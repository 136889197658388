const googleMapsKey = 'AIzaSyDrzzovfYyMzTgPUza6KWEvgOFqesFJMtI';

const config = {
  env: 'STAGING',
  geolocationAPI: `https://www.googleapis.com/geolocation/v1/geolocate?key=${googleMapsKey}`,
  appMode: process.env.appMode,
  apiUrlV2: 'https://internal-api-staging.fleetops.ai:5055/v1', // Staging V2
  firebaseConfig: {
    apiKey: 'AIzaSyDVLwOfPWL9iUEtExjTDT8k1LgydQzIzeA',
    authDomain: 'fleetops.ai',
    databaseURL: 'https://fleetops-p.firebaseio.com',
    projectId: 'fleetops-p',
    storageBucket: 'fleetops-p.appspot.com',
    messagingSenderId: '326879692618',
  },
  googleTagManager: {
    gtmId: 'GTM-54PL85J', // FleetOps-Staging-Application account
  },
  mixpanelConfig: {
    token: '21ea7debd2895a6d6f125a49aed73bcc',
  },
  googleMapsKey,
  staticGoogleMapsURL: 'https://maps.googleapis.com/maps/api/staticmap?',
  growthBookSdkClientKey: 'sdk-4hwOMnOq1ZH4o2ZC',
  featheryFormSdkClientKey: '8e095209-877e-4928-94d9-acb575afc0df',
};

export default config;
