import { AppMode } from "constants/AppMode";
import config from 'config';
import { ADMIN, DISPATCHER, DRIVER, TRAILER } from "constants/PartnerSpecificStrings";

export const getDispatcherNameVariation = (plural = false) => {
  const name = config.appMode === AppMode.FLEETPULSE ? ADMIN : DISPATCHER;
  return plural ? name + 's' : name;
};

export const getDriverNameVariation = (plural = false) => {
  const name = config.appMode === AppMode.FLEETPULSE ? TRAILER : DRIVER;
  return plural ? name + 's' : name;
};