const config = {
  ssoUrl: 'https://api.bigroad.com/partner-sign-in/freight', // BigRoad Production
  clarityId: '6xhswni1vu',
  hotjarId: '2827749',
  datadog: {
    applicationId: '53c84161-fd25-477d-8667-7b8a30e00067',
    clientToken: 'pub6693e024043f12ed00f5f764507137c7',
    service: 'bigroad-production',
  },
};

export default config;
