import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ApiLayer from 'services/APIServices/ApiLayer';
import { IntegrationNames } from 'components/IntegrationSourcesToggles/constants';
import SplashScreen from 'components/SplashScreen';
import { REDIRECT_URI } from 'components/IntegrationSourcesToggles/components/OneTwoThreeLoadBoardConfigModal/constants';
import LandingPageContainer from 'components/LandingPageContainer';
import useStyles from 'components/InitialSplashScreen/styles';
import GridContainer from 'components/GridContainer';
import { Grid, Box } from '@material-ui/core';
import classnames from 'classnames';
import CustomTypography from 'components/CustomTypography';

enum Sources {
  FleetPulse = 'FleetPulse',
  Unknown = 'Unknown',
}

function resolveSource(): Sources {
  return window.location.href.includes('fleetpulse') ? Sources.FleetPulse : Sources.Unknown;
}

function getNewLocationForIframeSource(source: Sources): string {
  switch (source) {
    case Sources.FleetPulse:
      return process.env.NODE_ENV === 'staging'
        ? process.env.FLEETPULSE_STAGING_URL
        : process.env.FLEETPULSE_PRODUCTION_URL;
    default:
      return null;
  }
}

const ONE_SECOND = 1000;
const ERROR_MESSAGE_DISPLAY_TIMING = ONE_SECOND * 10;

export const ConnectWith123LoadboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<{ message?: string | undefined } | null>(null);
  const [countdown, setCountdown] = useState(ERROR_MESSAGE_DISPLAY_TIMING / ONE_SECOND);
  const unsetLoading = () => setLoading(false);
  const unsetError = () => setError(null);
  const classes = useStyles();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  const displayError = (err: { message?: string }) => {
    setError(err);

    const timeout = setTimeout(() => {
      unsetError();
      unsetLoading();
      clearTimeout(timeout);
    }, ERROR_MESSAGE_DISPLAY_TIMING);
  };

  useLayoutEffect(() => {
    if (!code) {
      displayError({ message: 'Missing code from url query string' });
    }

    if (code) {
      ApiLayer.saveIntegrationConfig(
        {
          name: IntegrationNames.ONE_TWO_THREE_LOAD_BOARD,
          config: { onetime_code: code },
        },
        REDIRECT_URI,
      )
        .then(() => {
          switch (resolveSource()) {
            case Sources.FleetPulse:
              window.location = getNewLocationForIframeSource(Sources.FleetPulse);
              break;
            default:
              break;
          }

          unsetLoading();
        })
        .catch(({ response }) => {
          displayError(response?.data);
        });
    }
  }, []);

  useEffect(() => {
    let timeout;

    if (error && countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown((c) => c - 1);

        clearTimeout(timeout);
      }, ONE_SECOND);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, countdown]);

  if (error) {
    return (
      <LandingPageContainer isLoadingScreen>
        <Box className={classes.container}>
          <GridContainer
            direction='column'
            spacing={1}
            fullHeight
            wrap='nowrap'
            alignItems='center'
            justify='center'
          >
            <Grid item className={classnames(classes.fullWidth, classes.content)}>
              <Grid container direction='row' justify='center' alignItems='center'>
                <CustomTypography fontSemibold variant='h1' gutterBottom>
                  {error?.message || 'Something went wrong.'}
                </CustomTypography>
              </Grid>

              <Grid container direction='row' justify='center' alignItems='center'>
                <CustomTypography fontSemibold variant='h3' gutterBottom>
                  Redirecting in {countdown} seconds …
                </CustomTypography>
              </Grid>
            </Grid>
          </GridContainer>
        </Box>
      </LandingPageContainer>
    );
  }

  return loading ? <SplashScreen /> : <Redirect to='/driver/search' />;
};
