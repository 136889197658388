import firebase from 'firebase/app';
import 'firebase/auth';
import Trailer from 'models/dataStructures/Trailer';
import config from 'config';
import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import { getTokenHeader } from '../GetTokenHeader';

// eslint-disable-next-line import/prefer-default-export
export const putTrailerUpdateListedStatus = async (
  operation: 'listed' | 'unlisted',
  vins: string[],
): Promise<Trailer[]> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/trailers/status`;
  return axios
    .put(
      uri,
      {
        operation,
        vins,
      },
      { headers: tokenHeader },
    )
    .then(
      (response: {
        data: {
          data: Trailer[];
        };
      }) => response.data.data,
    )
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
