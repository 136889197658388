import config from 'config';

import Favicon from 'assets/images/favicon/favicon_brf.ico';
import FaviconFleet from 'assets/images/favicon/favicon_fleettrack.png';
import FaviconGeotab from 'assets/images/favicon/FTFavicon.png';
import FaviconEld from 'assets/images/favicon/favicon_eldloads.png';
import FaviconFreightMate from 'assets/images/favicon/favicon_freightmate.svg';
import FaviconSwitchBoard from 'assets/images/favicon/SwitchboardFavIcon.svg';
import FaviconEzloadz from 'assets/images/favicon/EzloadzFavIcon.svg';
import FaviconFleetPulse from 'assets/images/favicon/favicon_fleetpulse.ico';

const icons = {
  fleettrack: FaviconFleet,
  bigroad: Favicon,
  geotab: FaviconGeotab,
  eldloads: FaviconEld,
  freightmate: FaviconFreightMate,
  switchboard: FaviconSwitchBoard,
  ezloadz: FaviconEzloadz,
  fleetpulse: FaviconFleetPulse,
};

export default icons[config.appMode];
