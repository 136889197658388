const primary = {
  light: 'rgba(16, 137, 255, 0.1)',
  main: '#00539B',
  dark: '#1A1A1A',
  disabled: '#C8C7C7',
  contrastText: '#FFFFFF',
};
const secondary = {
  light: 'rgba(0, 83, 155, 0.2)',
  main: '#00539B',
  dark: '#023C6F',
  disabled: '#7FA8CC',
  contrastText: '#FFFFFF',
  medium: '#FFFFFF',
};
const orange = {
  main: '#F37321',
  dark: '#CC621F',
  disabled: '#C8C7C7',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: secondary,
  partnerCustomSecondary: orange,
  background: {
    walkthroughPurple: '#023C6F', // Walkthrough bubble bg, etc
    purpleLight: '#CCDDEB', // Light background for selected grid checkbox/radio block input etc
    lightBlue: {
      light: '#CCDDEB', // Table hover states
    },
  },
  navy: '#023C6F', // Search LP form background, etc
  loadStatus: {
    booked: '#00539B',
    shared: '#F37321',
    completed: '#2BBF8A',
    cancelled: '#B93131',
  },
};
export default palette;
