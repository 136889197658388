import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';
import { fontWeight } from 'theme/typography';

const useStyles = makeStyles((theme: ITheme) => ({
  fontRegular: {
    fontWeight: fontWeight.regular,
  },
  fontMedium: {
    fontWeight: fontWeight.medium,
  },
  fontSemibold: {
    fontWeight: fontWeight.semibold,
  },
  fontBold: {
    fontWeight: fontWeight.bold,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  textAlign: (props) => ({
    textAlign: props.textAlign,
  }),
}));
export default useStyles;
