export enum SortValues {
  RATE = 'rate',
  NEWEST = 'newest',
  PICKUP_DATE = 'pickupDate',
  DROPOFF_DATE = 'dropoffDate',
  DISTANCE = 'distance',
  WEIGHT = 'weight',
  SOURCE = 'source',
  COMPANY_NAME = 'companyName',
  PRICE = 'price',
  DEADHEAD = 'deadhead',
}
