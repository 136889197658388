import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import map from 'lodash/map';
import keys from 'lodash/keys';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import parseAddress from 'parse-address-string';
import moment, { Moment } from 'moment-timezone';
import { IObservableArray, toJS } from 'mobx';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { IInteraction } from '../models/interfaces/shared/INegotiateInteraction';
import { IRadius } from '../models/interfaces/shared/IRadius';
import { ICoordinate } from '../models/interfaces/shared/ICoordinate';
import { IOperatingLane } from '../models/interfaces/shared/IOperatingLanes';
import { IRegion } from 'models/interfaces/shared/IRegion';
import EQUIPMENT_TYPES from 'constants/EquipmentTypes';
import { PREV_ROUTE_NAME, PAGE_SOURCE } from 'constants/Mixpanel';
import { PermissionTypes } from 'constants/Permissions';
import ApiLayer from 'services/APIServices/ApiLayer';
import parsePhoneNumber from 'libphonenumber-js';
import { InteractionEventTypes } from 'constants/InteractionEventTypes';
import { UserTypeFormatted } from 'constants/UserTypes';
import { IDateRange } from 'models/interfaces/shared/IDateRange';
import { MappedBrokerNames } from 'constants/BrokerNames';
import config from 'config';
import { AppMode } from 'constants/AppMode';

export const getPerMileRateForMiles = (distanceInMiles, cost) => {
  const amount = parseFloat(cost);
  return {
    price: parseFloat((amount / distanceInMiles).toFixed(2)),
    miles: distanceInMiles,
  };
};

export const getFlatRateFromMiles = (distanceInMiles: number, perMileRateValue: number) => ({
  price: parseFloat((perMileRateValue * distanceInMiles).toFixed(0)),
  miles: distanceInMiles,
});

export const sleep = (miliseconds = 0) =>
  new Promise((resolve) => setTimeout(resolve, miliseconds));

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(String(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024))), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const omit = (originalObject = {}, keysToOmit: string[] = []) => {
  const clonedObject = { ...originalObject };
  for (let i = 0; i < keysToOmit.length; i += 1) {
    const path = keysToOmit[i];
    delete clonedObject[path];
  }
  return clonedObject;
};

export const getValueFromFormattedAmount = (formattedAmount: string) =>
  parseFloat(formattedAmount.split(' ')[0].replace(',', ''));

export const getPerMileRateForMeters = (distanceInMeters, cost) =>
  getPerMileRateForMiles(distanceInMeters * 0.00062137, cost);

export const getCityStateCountry: (address) => Promise<Object> = (address) =>
  new Promise((resolve, reject) => {
    parseAddress(address, (err, addressObj) => {
      if (err) {
        reject(err);
      } else if (addressObj.city || addressObj.state) {
        resolve(addressObj);
      } else {
        resolve(address);
      }
    });
  });
export const getFullAddress: (address) => Promise<Object> = (address) =>
  new Promise((resolve, reject) => {
    parseAddress(address, (err, addressObj) => {
      if (err) {
        reject(err);
      }
      let fullAddress = '';
      if (addressObj.city) {
        fullAddress +=
          fullAddress.length == 0 ? `${sentenceCase(addressObj.city)}` : `, ${addressObj.city}`;
      }
      if (addressObj.state) {
        fullAddress += `, ${addressObj.state}`;
      }
      if (addressObj.street_address1 && !/^\d+$/.test(addressObj.street_address1)) {
        fullAddress += addressObj.street_address1;
      }
      if (addressObj.postal_code) {
        fullAddress += `, ${addressObj.postal_code}`;
      }
      resolve(fullAddress);
    });
  });

export const getStreetAndPostalCode: (address) => Promise<Object> = (address) =>
  new Promise((resolve, reject) => {
    parseAddress(address, (err, addressObj) => {
      if (err) {
        reject(err);
      }
      let parsedAddress = '';
      if (addressObj.street_address1 && !/^\d+$/.test(addressObj.street_address1)) {
        parsedAddress += addressObj.street_address1;
      } else {
        // Don't return postal code if street address doesn't exist
        resolve(parsedAddress);
      }
      if (addressObj.postal_code) {
        parsedAddress += `, ${addressObj.postal_code}`;
      }
      resolve(parsedAddress);
    });
  });

export const refactorWeightValue = (weight) => {
  let newWeight = {};
  const weightArray = weight.split(',');
  if (weightArray[0] === '0') {
    newWeight = {
      high: parseFloat(weightArray[1]),
    };
  } else if (weightArray[1] === '0') {
    newWeight = {
      low: parseFloat(weightArray[0]),
    };
  } else {
    newWeight = {
      low: parseFloat(weightArray[0]),
      high: parseFloat(weightArray[1]),
    };
  }
  return newWeight;
};

export const refactorLengthValue = (length) => {
  let newLength = {};
  const lengthArray = length.split(',');
  if (lengthArray[0] === '0') {
    newLength = {
      high: parseFloat(lengthArray[1]),
    };
  } else if (lengthArray[1] === '0') {
    newLength = {
      low: parseFloat(lengthArray[0]),
    };
  } else {
    newLength = {
      low: parseFloat(lengthArray[0]),
      high: parseFloat(lengthArray[1]),
    };
  }
  return newLength;
};

export const refactorMileRate = (value) => ({
  price: parseFloat(value),
  currency: 'USD',
});

export const getAddressComponent = (value, type) =>
  find(value.address_components, (addressComponent) => includes(addressComponent.types, type));

export const parseAddressAndRefactorLocation = async (value, setAll?: boolean) => {
  if ((value.description || value.address) && !value.city && !value.state && !value.country) {
    const addressParsed = await getCityStateCountry(value.description || value.address);
    value.city = addressParsed.city;
    value.state = addressParsed.state;
    value.country = addressParsed.country;
  }
  return refactorLocation(value, setAll);
};

export const refactorLocation = (value, setAll?: boolean) => {
  const components = {
    city: '',
    state: '',
    country: '',
  };
  if (value) {
    const vcity = value.city;
    const locality = getAddressComponent(value, 'locality');
    const addCompCity = locality ? locality.long_name : '';
    const vstate = value.state;
    const administrative_area_level_1 = getAddressComponent(value, 'administrative_area_level_1');
    const addCompstate = administrative_area_level_1 ? administrative_area_level_1.short_name : '';
    const vcountry = value.country;
    const country = getAddressComponent(value, 'country');
    const addCompcountry = country ? country.short_name : '';
    if (setAll) {
      if (vcity || addCompCity) {
        components.city = vcity || addCompCity;
      }
      if (vstate || addCompstate) {
        components.state = vstate || addCompstate;
      }
      if (vcountry || addCompcountry) {
        components.country = vcountry || addCompcountry;
      }
    } else {
      // Only set the city, state or country. Not all three.
      if (vcity || addCompCity) {
        components.city = vcity || addCompCity;
      } else if (vstate || addCompstate) {
        components.state = vstate || addCompstate;
      } else if (vcountry || addCompcountry) {
        components.country = vcountry || addCompcountry;
      }
    }
  }
  return {
    address: value?.description || '',
    coordinates: value?.coordinates || null,
    ...components,
  };
};

export const refactorRadius = (value): IRadius => ({
  amount: parseFloat(value),
  unit: 'mile',
});

export const trackPageView = (pathname, search = '') => {
  // we want to track load detail and match detail page as load/details in GA
  if (pathname) {
    if (pathname.includes('detail')) {
    } else if (pathname.includes('redirect')) {
      console.log('Redirect');
    }
  }
};

export const filterBids = (interactions: IInteraction[]) => {
  const bidEventTypes = ['NEGOTIATING', 'NEGOTIATE_REJECT', 'NEGOTIATE_ACCEPT'];
  return interactions.filter((interaction) => bidEventTypes.includes(interaction.eventType));
};

export const getDateTime = (inputDate, inputTime) => {
  const momentDate = moment(inputDate);
  const momentTime = inputTime ? moment(inputTime, 'h:mm a') : momentDate;
  return momentDate.set({
    hour: momentTime.get('hour'),
    minute: momentTime.get('minute'),
    second: momentTime.get('second'),
  });
};

export const getDummyInteraction = () => {
  const dummyInteraction: IInteraction = {
    email: '',
    phone: '',
    displayName: '',
    firebaseUID: '',
    mcNumber: '',
    timestamp: 0,
    flatRate: true,
    unit: 'USD',
    price: 0,
    eventType: InteractionEventTypes.REJECT,
    userType: 'driver',
  };
  return dummyInteraction;
};

export const insertionSort = (array, value, comparator) => {
  let head = -1;
  let tail = -1;
  const { length } = array;

  if (length === 0) {
    array.push(value);
    return 0;
  }

  for (let index = 0; index < length; index += 1) {
    if (comparator(array[index], value)) {
      head = index;
    } else {
      tail = index;
      break;
    }
  }

  if (head === -1) {
    array.unshift(value);
  } else if (tail === -1) {
    tail = array.push(value) - 1;
  } else {
    array.splice(tail, 0, value);
  }

  // return index of insertion point
  return tail;
};

export const getDocumentNameFromKey = (documents, documentKey) => {
  let documentName = '';
  if (documents && documents[documentKey]) {
    if (documentKey === 'other' && documents[documentKey][documentKey]) {
      const documentNameSplit = documents[documentKey][documentKey].split('/');
      documentName = documents[documentKey][documentKey].split('/')[documentNameSplit.length - 1];
    } else {
      const documentNameSplit = documents[documentKey].split('/');
      documentName = documents[documentKey].split('/')[documentNameSplit.length - 1];
    }
  }
  return documentName;
};

export const calculateAverageCoordinates = (coordinates: ICoordinate[]): ICoordinate => {
  if (coordinates.length === 1) {
    return coordinates[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  coordinates.forEach((coordinate) => {
    const latitude = (coordinate.lat * Math.PI) / 180;
    const longitude = (coordinate.lng * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  });

  const total = coordinates.length;

  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI,
  };
};

export const growFromTopStyle: CSSProperties = {
  transformOrigin: 'top',
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  // Cast to a File() type
  return <File>theBlob;
};

export const convertMetersToMiles = (distanceInMeters: number) =>
  Number((distanceInMeters / 1609.344).toFixed(2));

export const buildURLBasedFilters = (URLSearchParams) => {
  const searchParams = {};

  if (URLSearchParams.weightLow) {
    searchParams.weight = `${parseFloat(URLSearchParams.weightLow)},${parseFloat(
      URLSearchParams.weightHigh,
    )}`;
  } else if (URLSearchParams.weightHigh) {
    searchParams.weight = `0,${parseFloat(URLSearchParams.weightHigh)}`;
  }

  const { pickupAddress, pickupState, pickupCity, pickupCountry, pickupLat, pickupLng } =
    URLSearchParams;
  if (pickupAddress) {
    searchParams.pickupLocation = {
      address: pickupAddress,
      city: pickupCity,
      state: pickupState,
      country: pickupCountry,
      description: pickupAddress,
      coordinates: {
        lat: parseFloat(pickupLat),
        lng: parseFloat(pickupLng),
      },
    };
  }

  const { dropoffAddress, dropoffState, dropoffCity, dropoffCountry, dropoffLat, dropoffLng } =
    URLSearchParams;
  if (dropoffAddress) {
    searchParams.dropoffLocation = {
      address: dropoffAddress,
      city: dropoffCity,
      state: dropoffState,
      country: dropoffCountry,
      coordinates: {
        lat: parseFloat(dropoffLat),
        lng: parseFloat(dropoffLng),
      },
    };
  }

  const { radiusAmount, radiusUnit } = URLSearchParams;
  if (radiusAmount) {
    searchParams.radius = {
      amount: radiusAmount,
      unit: radiusUnit,
    };
  }

  const { equipmentTypeList } = URLSearchParams;
  if (equipmentTypeList) {
    searchParams.equipmentTypeList =
      typeof equipmentTypeList === 'string' ? equipmentTypeList.split(',') : equipmentTypeList;
  }

  const { availableDate } = URLSearchParams;
  if (availableDate) {
    searchParams.availableDate = moment(availableDate * 1000);
  }

  const { expiresOn } = URLSearchParams;
  if (expiresOn) {
    searchParams.expiresOn = moment(expiresOn * 1000);
  }

  const { perMileRatePrice, perMileRateCurrency } = URLSearchParams;
  if (perMileRatePrice) {
    searchParams.perMileRate = {
      price: perMileRatePrice,
      currency: perMileRateCurrency,
    };
  }

  const { pickupDate } = URLSearchParams;
  if (pickupDate) {
    searchParams.pickupDate = moment(pickupDate * 1000);
  }

  const { laneSize } = URLSearchParams;
  if (laneSize) {
    searchParams.laneSize = laneSize;
  }

  if (URLSearchParams.weightLow) {
    searchParams.weight = `${parseFloat(URLSearchParams.weightLow)},${parseFloat(
      URLSearchParams.weightHigh,
    )}`;
  } else if (URLSearchParams.weightHigh) {
    searchParams.weight = `0,${parseFloat(URLSearchParams.weightHigh)}`;
  }

  return searchParams;
};

export const getLocationText = (city, state, country) => {
  let text = '';
  if (city) {
    text += `${city}`;
  }
  if (state) {
    text += `${city ? ', ' : ''}${state}`;
  }
  if (country) {
    text += `, ${country}`;
  }
  return text;
};

export const concatCityState = (city, state) => {
  let text = '';
  if (city) {
    text += `${city}`;
  }
  if (state) {
    text += `${city ? ', ' : ''}${state}`;
  }
  return text;
};

export const getCityFromAddress = (address) => {
  let stringArr = address.split(' ');
  return stringArr[0].replace(',', '');
};

export const formatLocationAddressCasing = (address) => {
  if (address) {
    let stringArr = address.split(' ');
    if (stringArr.length > 1) {
      stringArr[0] = sentenceCase(stringArr[0]);
      return stringArr.join(' ');
    }
    return sentenceCase(stringArr[0]).replace(',', '');
  }
  return '';
};

export const parseQueryParamsToSingleOutput = (param?: string | string[]) => {
  if (param) {
    if (Array.isArray(param) && param.length > 0) {
      return param[0];
    }
    if (typeof param === 'string') {
      return param;
    }
  }
  return null;
};

export const getGreetings = () => {
  const hours = new Date().getHours();
  if (hours < 12) {
    return 'Good Morning';
  }
  if (hours < 17) {
    return 'Good Afternoon';
  }
  return 'Good Evening';
};

export const fetchRandomValueFromArr = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const formatNumberToString = (numericNum) => {
  if (typeof numericNum === 'number') {
    return numericNum.toLocaleString('en');
  }
  return numericNum;
};

export const getAppContainer = () => {
  return document.getElementById('root');
};

export const isObjectId = (id: string) => Boolean(id.match(/^[0-9a-fA-F]{24}$/));

export const isSelectedDateToday = (selectedDate): boolean | null =>
  selectedDate ? moment(selectedDate).isSame(moment(), 'day') : null;

export const getNameForPreferredLanes = (
  name: string,
  operatingLanes: IObservableArray<IOperatingLane>,
) => {
  let existingNames = operatingLanes
    .filter((lane) => lane.name.toLowerCase().indexOf(name.toLowerCase()) !== -1)
    .map((o) => o.name);
  if (existingNames.length > 0) {
    var count = 1;
    existingNames.forEach((name, index) => {
      let words = name.split(' ');
      let lastWord = words[words.length - 1];
      if (parseInt(lastWord)) {
        count = parseInt(lastWord) + 1;
      }
    });
    name += ` ${count}`;
  }
  return name;
};

export const sortArrayByValue = (list: Array<any>, key: string, order? = 'asc'): Array<any> => {
  if (order === 'desc') {
    return list.sort(
      (a, b) =>
        (b[key] !== undefined ? b[key] : -Infinity) - (a[key] !== undefined ? a[key] : -Infinity),
    );
  }
  return list.sort(
    (a, b) =>
      (a[key] !== undefined ? a[key] : Infinity) - (b[key] !== undefined ? b[key] : Infinity),
  );
};

export const addCoordinatesToLane: (currentLocation) => Promise<ICoordinate> = async (
  currentLocation,
) =>
  new Promise<ICoordinate>((resolve) => {
    const address = `${currentLocation.city}, ${currentLocation.state}, ${currentLocation.country}`;
    const geocoder = new google.maps.Geocoder();
    let coordinates = { lat: 0, lng: 0 };
    geocoder.geocode({ address }, (geocodeResults, status) => {
      if (status === 'OK' && geocodeResults && geocodeResults.length > 0) {
        coordinates.lat = geocodeResults[0].geometry.location.lat();
        coordinates.lng = geocodeResults[0].geometry.location.lng();
      }
      resolve(coordinates);
    });
  });

export const validateAndAddCoordinatesToPreferredLanes = async (
  operatingLanes: IOperatingLane[],
) => {
  for (let lane in operatingLanes) {
    if (operatingLanes[lane].pickup?.coordinates === undefined) {
      const newCord = await addCoordinatesToLane(operatingLanes[lane].pickup);
      operatingLanes[lane].pickup.coordinates = newCord;
    }
    if (operatingLanes[lane].dropoff?.coordinates === undefined) {
      const newCord = await addCoordinatesToLane(operatingLanes[lane].dropoff);
      operatingLanes[lane].dropoff.coordinates = newCord;
    }
  }
  return operatingLanes;
};

export const getLocationFieldName = (locationField) => {
  if (locationField.name === 'pickupLocation') {
    return 'Pickup';
  }
  if (locationField.name === 'dropoffLocation') {
    return 'Drop-off';
  }
  return '';
};

export const getMergedLanes = (userPreferredLanes, recommendedLanes) => {
  const mergedLanes = Object.assign([], toJS(recommendedLanes));
  userPreferredLanes.forEach((o) => {
    const matches = recommendedLanes.filter((l) => l.lane === o.name);
    if (matches.length === 0) {
      mergedLanes.push(o);
    }
  });
  return mergedLanes;
};

/**
 * @param formValueArray - array of string
 Pass array of string, and return object with string as key and value true
 Used to convert parsed switch input that are converted to array of strings back to object
 */

export const reverseParseStringArray = (formValueArray: Array<string>) => {
  if (formValueArray) {
    const rawValue = {};
    return formValueArray.reduce((obj, item) => ({ ...obj, [item]: true }), rawValue);
  }
};

/**
 * DART UTILS
 */

export const recursiveObjectFlatten = (object) =>
  // taken from https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
  Object.assign(
    {},
    ...(function _flatten(o) {
      if (o) {
        return [].concat(
          ...Object.keys(o).map((k) => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })),
        );
      }
      return [];
    })(object),
  );

export const getAllTrueKeysList = (object) => {
  const allTrueKeys = pickBy(recursiveObjectFlatten(object), (value) => value === true);
  return map(keys(allTrueKeys), startCase);
};

export const getAllTrueKeysListUnformatted = (object) => {
  const allTrueKeys = pickBy(recursiveObjectFlatten(object), (value) => value === true);
  return map(keys(allTrueKeys));
};

export const getAllTrueKeysFormatted = (object) => getAllTrueKeysList(object).join(', ');

export const getAllKeysWithValidValues = (object) => {
  return Object.keys(object).filter(function (key) {
    return Boolean(object[key]);
  });
};

export const getCityAndState: (address, { cityOnly, stateOnly }) => Promise<string> = (
  address,
  { cityOnly, stateOnly },
) =>
  new Promise((resolve, reject) => {
    parseAddress(address, (err, addressObj) => {
      if (err) {
        reject(err);
      } else if (addressObj.city || addressObj.state) {
        /**
         * For a few addresses, city and state values are interchanged by parser
         * eg (New York, NY -> NY, New York)
         * Check length of city and state and set them back to
         * the correct values
         */
        if (addressObj.city?.length === 2 && addressObj.state?.length !== 2) {
          const city = addressObj.state;
          const state = addressObj.city;
          addressObj.city = city;
          addressObj.state = state;
        }

        if (addressObj.city && addressObj.state) {
          if (cityOnly) {
            resolve(`${addressObj.city}`);
          }
          if (stateOnly) {
            resolve(`${addressObj.state}`);
          }
          resolve(`${addressObj.city}, ${addressObj.state}`);
        } else {
          resolve(`${addressObj.city ? addressObj.city : addressObj.state}`);
        }
      } else {
        resolve(address);
      }
    });
  });

export const findByEmailOrPhone = (arrayList: Array<any>, selectedUser) => {
  return arrayList.find(
    (o) =>
      (o.email && o.email === selectedUser.email) ||
      (!o.email && o.phone && o.phone === selectedUser.phone),
  );
};

export const findIndexByEmailOrPhone = (arrayList: Array<any>, selectedUser) => {
  return arrayList.findIndex(
    (o) =>
      (o.email && o.email === selectedUser.email) ||
      (!o.email && o.phone && o.phone === selectedUser.phone),
  );
};

export const formatMonthDay = (dateTime: Date | Moment, utc = false) => {
  if (utc) {
    /**
     * All loads date times are converted to moment object in the store computed property
     * Whereas, RAL date are not always moment objects - we add a check to convert it if it isn't
     * a moment object
     */
    if (!moment.isMoment(dateTime)) {
      dateTime = moment(dateTime);
    }
    return moment.utc(dateTime, 'YYYY-MM-DD').local().format('MMM D');
  }
  return moment(dateTime).format('MMM D');
};

export const formatMonthDayTime = (dateTime, utc = false) => {
  if (utc) {
    return moment.utc(dateTime).local().format('MMM D, hh:mm A');
  }
  return moment(dateTime).format('MMM D, hh:mm A');
};

export const formatWeekdayMonthDayTime = (dateTime, utc = false) => {
  if (utc) {
    return moment.utc(dateTime).local().format('ddd, MMM D, h:mm A');
  }
  return moment(dateTime).format('ddd, MMM D, h:mm A');
};

export const formatMonthDayYear = (dateTime) => {
  return moment(dateTime).format('MMM D, YYYY');
};

export const formatFullMonthDayYear = (dateTime) => {
  return moment(dateTime).format('MMM DD, YYYY');
};

export const formatFullMonthDayYearWithTime = (dateTime, utc = false) => {
  if (utc) {
    return moment.utc(dateTime).local().format('MMM DD, YYYY hh:mm A');
  }
  return moment(dateTime).format('MMM DD, YYYY hh:mm A');
};

export const formatYearMonthDay = (dateTime, utc = false) => {
  if (!moment.isMoment(dateTime)) {
    dateTime = moment(dateTime);
  }
  if (utc) {
    return moment.utc(dateTime).format('YYYY-MM-DD');
  }
  return moment(dateTime).format('YYYY-MM-DD');
};

export const formatTime = (dateTime, utc = false) => {
  if (utc) {
    return moment.utc(dateTime).local().format('hh:mm A');
  }
  return moment(dateTime).format('hh:mm A');
};

export const joinDateAndTime = (date, time) => {
  return moment.utc(date + ' ' + time, 'YYYY-MM-DD HH:mm').format();
};

/**
 * If there is start date and end date, we should display: "{startDate} - {endDate}"
 * If end date doesn't exist or start and date are equal then we just display: "{startDate}"
 */
export const formatDateRange = (locationDetails) => {
  const start = locationDetails?.startDateTime;
  const end = locationDetails?.endDateTime;
  // Return empty string if both start and end dates are not populated
  if (!start.isValid() && !end.isValid()) return '';
  if (!end.isValid()) return formatMonthDay(start, true);
  if (moment(start).isSame(end, 'day')) return formatMonthDay(start, true);
  return `${formatMonthDay(start, true)} - ${formatMonthDay(end, true)}`;
};

export const roundValues = (values) => {
  return Math.round(values);
};

export const userTypeToPermission = (user: any) => {
  if (user.userType === 'dispatchableDriverSearch') {
    user.userType = 'dispatchableDriver';
    user.permissions = { search: 5, withDriver: 0 };
  } else if (user.dispatchableDriverSearch) {
    user.permissions = { search: 5, withDriver: 0 };
  } else if (user.dispatcherDriver) {
    user.permissions = { withDriver: 5, search: 0 };
  } else {
    user.permissions = { withDriver: 0, search: 0 };
  }
  return user;
};

export const permissionToUserType = (user: any) => {
  if (user.permissions) {
    if (user.userType === 'dispatchableDriver' && user.permissions?.search === 5) {
      user.userType = 'dispatchableDriverSearch';
      user.dispatchableDriverSearch = true;
      user = omit(user, ['permissions']);
    }
    if (user.userType === 'dispatcher' && user.permissions?.withDriver === 5) {
      user.dispatcherDriver = true;
      user = omit(user, ['permissions']);
    }
  }
  return user;
};

export const userTypesWithoutPermission = (userType) => {
  if (userType === 'dispatchableDriver') {
    return UserTypeFormatted.DISPATCHABLE_DRIVER;
  }
  if (userType === 'dispatcher') {
    return UserTypeFormatted.DISPATCHER;
  }
  if (userType === 'driver') {
    return UserTypeFormatted.OWNER_OPERATOR;
  }
};

export const permissionToUserTypeString = (data: any) => {
  if (data.permissions) {
    if (data.userType === 'dispatchableDriver' && data.permissions?.search === 5) {
      return UserTypeFormatted.DISPATCHABLE_DRIVER_WITH_SEARCH;
    }
    if (data.userType === 'dispatcher' && data.permissions?.withDriver === 5) {
      return UserTypeFormatted.DISPATCHER_DRIVER;
    }
    return userTypesWithoutPermission(data.userType);
  }
  return userTypesWithoutPermission(data.userType);
};

export const permissionToDriverType = (driver: any) => {
  if (driver.permissions) {
    if (driver.userType === 'dispatchableDriver') {
      if (driver?.permissions?.search === 5) {
        driver.userType = 'dispatchableDriverSearch';
      }
    }
  }
  return driver;
};

export const driverTypeToPermission = (driver: any) => {
  if (driver.userType === 'dispatchableDriverSearch') {
    driver.userType = 'dispatchableDriver';
    driver.permissions = { search: 5, withDriver: 0 };
  } else if (driver.userType === 'dispatchableDriver') {
    driver.permissions = { search: 0, withDriver: 0 };
  } else {
    driver.permissions = { search: 0, withDriver: 0 };
  }
  return driver;
};

export const driverTypeToDisplay = (driver: any) => {
  driver = permissionToUserType(driver);
  if (driver.userType === 'dispatchableDriverSearch') {
    return PermissionTypes.DRIVER_SEARCH;
  } else if (driver.userType === 'dispatchableDriver') {
    return PermissionTypes.DRIVER;
  } else if (driver.dispatcherDriver) {
    return ' ';
  } else {
    return PermissionTypes.OWNER_OPERATOR;
  }
};

export const sentenceCase = (string) => {
  try {
    return string
      .trim()
      .replace(/\s+/g, ' ')
      .split(' ')
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ');
  } catch (e) {
    console.log(e);
  }
};

export const camelToSentenceCase = (string) => {
  try {
    const breakString = string.trim().replace(/([A-Z])/g, ' $1');
    return breakString.charAt(0).toUpperCase() + breakString.slice(1);
  } catch (e) {
    console.log(e);
  }
};

export const sentenceToCamelCase = (string) => {
  try {
    return string
      .trim()
      .replace(/\s+/g, ' ')
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  } catch (e) {
    console.log(e);
  }
};

export const stableSort = (results, property, order, isNumeric = false) => {
  if (isNumeric) {
    return orderBy(
      results,
      (obj) => {
        let num = get(obj, property);
        // If value is string eg '30 mi' we want to extract only the numerical part
        if (typeof num === 'string') {
          num = num.replace(/\D/g, '');
        }
        return parseFloat(num);
      },
      [order],
    );
  }
  return orderBy(results, [property], [order]);
};

/**
 * Use match as parameter since we need to check URL
 * as well as route params in some cases (eg drivers)
 */
export const getRouteRedirectState = (match) => {
  if (match.url.includes('search')) {
    return PREV_ROUTE_NAME.SEARCH;
  }
  if (match.url.includes('active')) {
    return PREV_ROUTE_NAME.ACTIVE_LOADS;
  }

  if (match.url.includes('shared')) {
    return PREV_ROUTE_NAME.SHARED_LOADS;
  }

  if (match.url.includes('past')) {
    return PREV_ROUTE_NAME.PAST_LOADS;
  }

  if (match.url.includes('cancelled')) {
    return PREV_ROUTE_NAME.CANCELLED_LOADS;
  }

  if (match.url.includes('drivers') && match.params.personId) {
    return PREV_ROUTE_NAME.DRIVER_DETAIL;
  }

  if (match.url.includes('drivers')) {
    return PREV_ROUTE_NAME.DRIVER_LIST;
  }

  if (match.url.includes('ral') && match.params.ralId) {
    return PREV_ROUTE_NAME.RAL_DETAIL;
  }

  if (match.url.includes('ral')) {
    return PREV_ROUTE_NAME.RAL_LIST;
  }

  if (match.url.includes('notifications')) {
    return PREV_ROUTE_NAME.NOTIFICATIONS;
  }

  if (match.url.includes('onboarding')) {
    return PREV_ROUTE_NAME.ONBOARDING;
  }

  return 'Direct';
};

export const getRouteNameFromLocation = (location) => {
  /**
   location.pathname.includes - gives the page source currently on when function is called,
   used for click events to get page source of page
   */
  if (location.pathname.includes('search') && !location.pathname.includes('redirect')) {
    return PAGE_SOURCE.SEARCH;
  }
  if (location.pathname.includes('active')) {
    return PAGE_SOURCE.ACTIVE_LOADS;
  }

  if (location.pathname.includes('shared')) {
    return PAGE_SOURCE.SHARED_LOADS;
  }

  if (location.pathname.includes('past')) {
    return PAGE_SOURCE.PAST_LOADS;
  }

  if (location.pathname.includes('cancelled')) {
    return PAGE_SOURCE.CANCELLED_LOADS;
  }

  if (location.pathname.includes('drivers/')) {
    return PAGE_SOURCE.DRIVER_DETAIL;
  }

  if (location.pathname.includes('drivers')) {
    return PAGE_SOURCE.DRIVER_LIST;
  }

  if (location.pathname.includes('ral') && location.pathname.includes('details')) {
    return PAGE_SOURCE.RAL_DETAIL;
  }

  if (location.pathname.includes('ral')) {
    return PAGE_SOURCE.RAL_LIST;
  }

  if (location.pathname.includes('notifications')) {
    return PAGE_SOURCE.NOTIFICATIONS;
  }

  if (location.pathname.includes('settings')) {
    return PAGE_SOURCE.SETTING;
  }
  if (location.pathname.includes('onboarding')) {
    return PAGE_SOURCE.ONBOARDING;
  }
  if (
    location.pathname.includes('detail') &&
    (location.pathname.includes('load') || location.pathname.includes('match'))
  ) {
    return PAGE_SOURCE.LOAD_DETAIL;
  }
  if (location.pathname.includes('listmytrailer')) {
    return PAGE_SOURCE.FP_LIST_MY_TRAILER;
  }
  if (location.pathname.includes('rent')) {
    return PAGE_SOURCE.FP_RESERVE_A_TRAILER;
  }
  if (location.pathname.includes('trailer')) {
    return PAGE_SOURCE.FP_MY_TRAILER_LIST;
  }
  return PAGE_SOURCE.DIRECT;
};

export const getPreviousRouteNameFromLocation = (location) => {
  /**
   * This only happens in case of redirecting from search for web since
   * the search in web opens load details in a new tab and we need to
   * manually append redirectFrom to the url
   */
  const additionalRedirectParamArray = location.pathname.split('&redirectFrom=');

  let redirectFrom = '';
  if (
    additionalRedirectParamArray &&
    additionalRedirectParamArray.length > 1 &&
    additionalRedirectParamArray[additionalRedirectParamArray.length - 1]
  ) {
    redirectFrom = additionalRedirectParamArray[additionalRedirectParamArray.length - 1];
  }
  /**
   * If previous page is called after redirection - this value would help determine what the previous 
   * route was:
  location?.state?.redirectFrom - gives the page source redirected from,
  used in cases when re-directing from one page to another
   */

  /**
   * If previous page is called before redirection - this value would help determind what the previous
   * route is before redirection
   location.pathname.includes - gives the page source currently on when function is called
   */
  if (
    redirectFrom === 'search' ||
    location?.state?.redirectFrom === PREV_ROUTE_NAME.SEARCH ||
    location.pathname.includes('search')
  ) {
    return PAGE_SOURCE.SEARCH;
  }
  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.ACTIVE_LOADS ||
    location.pathname.includes('active')
  ) {
    return PAGE_SOURCE.ACTIVE_LOADS;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.SHARED_LOADS ||
    location.pathname.includes('shared')
  ) {
    return PAGE_SOURCE.SHARED_LOADS;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.PAST_LOADS ||
    location.pathname.includes('past')
  ) {
    return PAGE_SOURCE.PAST_LOADS;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.CANCELLED_LOADS ||
    location.pathname.includes('cancelled')
  ) {
    return PAGE_SOURCE.CANCELLED_LOADS;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.DRIVER_DETAIL ||
    location.pathname.includes('drivers/')
  ) {
    return PAGE_SOURCE.DRIVER_DETAIL;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.DRIVER_LIST ||
    location.pathname.includes('drivers')
  ) {
    return PAGE_SOURCE.DRIVER_LIST;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.RAL_DETAIL ||
    (location.pathname.includes('ral') && location.pathname.includes('details'))
  ) {
    return PAGE_SOURCE.RAL_DETAIL;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.RAL_LIST ||
    location.pathname.includes('ral')
  ) {
    return PAGE_SOURCE.RAL_LIST;
  }

  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.NOTIFICATIONS ||
    location.pathname.includes('notifications')
  ) {
    return PAGE_SOURCE.NOTIFICATIONS;
  }

  if (location.pathname.includes('settings')) {
    return PAGE_SOURCE.SETTING;
  }
  if (
    location?.state?.redirectFrom === PREV_ROUTE_NAME.ONBOARDING ||
    location.pathname.includes('onboarding')
  ) {
    return PAGE_SOURCE.ONBOARDING;
  }
  // Implies we got to this page using an external link
  return PAGE_SOURCE.DIRECT;
};

export const dateGrouping = (selectedDate) => {
  var TODAY = moment().startOf('day');
  var YESTERDAY = moment().subtract(1, 'days').startOf('day');
  var A_WEEK_OLD = moment().subtract(7, 'days').startOf('day');
  if (moment(selectedDate).isSame(TODAY, 'd')) {
    return 'Today';
  }
  if (moment(selectedDate).isSame(YESTERDAY, 'd')) {
    return 'Yesterday';
  }
  if (moment(selectedDate).isAfter(A_WEEK_OLD, 'd')) {
    return 'Last week';
  }
  return formatMonthDay(selectedDate);
};

/**
 * Neutralizes native browser back event
 * Helps to close a modal on browser back click
 * Accepts a callback as a param, which closes a modal
 * Use this function when a modal is opened
 */
export const neutralizeBrowserBackEvent = (callback) => {
  window.history.pushState(null, '', window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, '', window.location.href);
    callback();
  };
};

/**
 * Returns browser native back event for a proper user experience
 * This function should be called as a part of a callback
 * for the `neutralizeBrowserBackEvent` function
 */
export const revivalBrowserBackEvent = () => {
  window.onpopstate = undefined;
  window.history.back();
};

export const startOfDay = (selectedDate, timezone = '') => {
  if (timezone) {
    return moment(selectedDate).tz(timezone).startOf('day').format();
  }
  return moment(selectedDate).startOf('day').format();
};

export const addAddressToLocation = (location) => {
  const { city, state, country } = location;
  return {
    ...location,
    address: getLocationText(city, state, country),
  };
};

export const addCityStateToLocation = (location) => {
  const { city, state, country } = location;
  return {
    ...location,
    address: concatCityState(city, state),
  };
};

export const parseAddressPayload = (address) => {
  /** Remove lon key to prevent BE issues */
  if (address.coordinates.lon) {
    delete address.coordinates.lon;
  }
  return {
    city: address.city,
    coordinates: address.coordinates,
    country: address.country,
    state: address.state,
  };
};

export const parseOperatingLanePayload = (operatingLane) => {
  return {
    pickup: parseAddressPayload(operatingLane.pickup),
    dropoff: parseAddressPayload(operatingLane.dropoff),
    name: operatingLane.name || '',
    rank: operatingLane.rank,
  } as IOperatingLane;
};

export const getMaxRankOperatingLanes = (operatingLanes) => {
  let maxRank = 1;
  if (operatingLanes && operatingLanes.length > 0) {
    maxRank =
      operatingLanes.reduce((max, o) => (o.rank > max ? o.rank : max), operatingLanes[0].rank) +
      maxRank;
  }
  return maxRank;
};

export const chunkArray = (data: any[], size) => {
  const results: any[] = [];
  while (data.length) {
    results.push(data.splice(0, size));
  }
  return results;
};

export const getEquipmentTypeLabel = (equipmentType) => {
  const foundItem = EQUIPMENT_TYPES.find((item) => item.value === equipmentType);
  if (!foundItem) {
    return '';
  }
  return foundItem.label;
};

export const areEqual = (object1, object2) => {
  //Use the equality comparer from lodash to compare objects
  return isEqual(object1, object2);
};

export const formatURLParams = (param) => {
  if (param) {
    return param.replace(/'/g, '');
  }
  return '';
};

export const formatAmountString = (amount) => {
  return amount.replace(/[,$]/g, '');
};

export const deadheadBatchCalculation = async (results, location, locationType = 'PU') => {
  let deadheads = [];
  if (locationType === 'PU') {
    // Check if loads have computed pickupCoordinates property
    if (results[0]?.payload?.tripDetails?.pickupCoordinates) {
      deadheads = results.map((load) => load.payload.tripDetails.pickupCoordinates);
    } else {
      deadheads = results.map((load) => ({
        lng: load?.pickups[0]?.position?.coordinates[0],
        lat: load?.pickups[0]?.position?.coordinates[1],
      }));
    }
  } else {
    // Check if loads have computed dropoffCoordinates property
    if (results[0]?.payload?.tripDetails?.dropoffs[0]?.dropoffCoordinates) {
      deadheads = results.map((load) => load.payload.tripDetails.dropoffs[0].dropoffCoordinates);
    } else {
      deadheads = results.map((load) => ({
        lng: load?.dropoffs[0]?.position?.coordinates[0],
        lat: load?.dropoffs[0]?.position?.coordinates[1],
      }));
    }
  }
  const deadheadResults: Array<string> = [];
  for (let chunk of chunkArray(deadheads, 25)) {
    const result: any = await ApiLayer.getDistances([location.coordinates], chunk);
    const distances = result.rows[0].elements.map((element) => element?.distance?.text);
    deadheadResults.push(...distances);
  }
  return deadheadResults;
};

export const formatDeadhead = (deadhead) => {
  if (deadhead) {
    const [distance, unit] = deadhead.split(' ');
    if (unit === 'ft') {
      return '0 mi';
    }
    return `${Math.round(+distance)} ${unit}`;
  }
  return null;
};

export const formatPhone = (value: string) => {
  const phoneNumber = parsePhoneNumber(value, 'US');

  if (phoneNumber) {
    return phoneNumber.number.toString();
  }

  return value;
};

export const setDetailsForStates = (selectedState: IRegion, field) => {
  field.$('city').set('value', '');
  field.$('state').set('value', selectedState.state);
  field.$('country').set('value', selectedState.countryShortCode);
  field.$('county').set('value', '');
  field.$('streetNumber').set('value', '');
  field.$('streetName').set('value', '');
  field.$('postalCode').set('value', '');
  field.$('coordinates').set('value', {
    lat: selectedState.lat,
    lng: selectedState.lng,
  });
  field.$('coordinates').resetValidation();
  field.$('isCurrentLocation').set('value', false);
};

export const setDetailFields = (geocodeResults: google.maps.GeocoderResult, field) => {
  field.$('city').set('value', '');
  field.$('state').set('value', '');
  field.$('country').set('value', '');
  field.$('county').set('value', '');
  field.$('streetNumber').set('value', '');
  field.$('streetName').set('value', '');
  field.$('postalCode').set('value', '');
  geocodeResults.address_components.forEach((addressComponent) => {
    if (
      addressComponent.types.includes('locality') ||
      addressComponent.types.includes('sublocality')
    ) {
      field.$('city').set('value', addressComponent.long_name);
    }
    if (addressComponent.types.includes('administrative_area_level_1')) {
      field.$('state').set('value', addressComponent.short_name);
    }
    if (addressComponent.types.includes('country')) {
      field.$('country').set('value', addressComponent.short_name);
    }
    if (addressComponent.types.includes('administrative_area_level_2')) {
      field.$('county').set('value', addressComponent.long_name);
    }
    if (addressComponent.types.includes('street_number')) {
      field.$('streetNumber').set('value', addressComponent.long_name);
    }
    if (addressComponent.types.includes('route')) {
      field.$('streetName').set('value', addressComponent.long_name);
    }
    if (addressComponent.types.includes('postal_code')) {
      field.$('postalCode').set('value', addressComponent.long_name);
    }
  });

  field.$('coordinates').set('value', {
    lat: geocodeResults.geometry.location.lat(),
    lng: geocodeResults.geometry.location.lng(),
  });
  field.$('coordinates').resetValidation();
  field.$('isCurrentLocation').set('value', false);
};

export const removePhoneCountryCode = (value: string) => {
  const phone = formatPhone(value);

  if (phone) return phone.substring(2);

  return value;
};

export const getPhoneURI = (value: string) => {
  const phone = parsePhoneNumber(value, 'US');

  if (phone) return phone.getURI();

  return `tel:${value}`;
};

export const formatDateRangeArray = (daterangeArray, utc = true) => {
  let dateRangeObject: IDateRange = {};
  if (daterangeArray && daterangeArray?.[0] !== null) {
    dateRangeObject.range_start = startOfDay(daterangeArray[0]);
    if (utc) {
      dateRangeObject.range_start = startOfDay(daterangeArray[0], 'UTC');
    }
    if (
      daterangeArray.length === 2 &&
      daterangeArray[1] &&
      !moment(daterangeArray[0]).isSame(daterangeArray[1])
    ) {
      dateRangeObject.range_end = startOfDay(daterangeArray[1]);
      if (utc) {
        dateRangeObject.range_end = startOfDay(daterangeArray[1], 'UTC');
      }
    }
    return dateRangeObject;
  }
  return null;
};

export const formatDateRangeDisplay = (range) => {
  if (!range) return '';
  let displayDate = range?.range_start ? formatMonthDay(range?.range_start, true) : '';
  if (range?.range_end) {
    if (range?.range_start === range?.range_end) return displayDate;
    displayDate += ' - ' + formatMonthDay(range?.range_end, true);
  }
  return displayDate;
};

export const sortBrokerList = (list: Array<any>) => {
  list.sort((broker1, broker2) => {
    return broker1.label?.toLowerCase().localeCompare(broker2.label?.toLowerCase());
  });
  return list;
};

/**
 * The top and self are both window objects, along with parent,
 * so check if the current window is the top/main window.
 */
export const renderingInIFrame = () => {
  if (window.self !== window.top) {
    return true;
  }
  return false;
};

/**
 * This function will get a formatted broker name if present in the mapping.
 * @param loadSource The load source
 * @returns string or false - Formatted load source string
 */
export const getFormattedBrokerName = (loadSource: string) => {
  if (Object.keys(MappedBrokerNames).includes(loadSource)) {
    return MappedBrokerNames[loadSource];
  }
  return false;
};

export const handleOpenCallDialog = (phone: string) => {
  if (config.appMode === AppMode.EZLOADZ && renderingInIFrame()) {
    window.open(getPhoneURI(phone), '_blank');
  } else {
    window.location = getPhoneURI(phone);
  }
};

/**
 * This function removes query params from URL
 * @param param The param that should be removed from URL
 * Example: "driver/search?deadhead=10". "deadhead" param will be removed -> "driver/search"
 */
export const removeParamFromURL = (param: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  history.replaceState(null, null, url);
};

export const splitShippingNotes = (notes: string) => {
  if (!notes) return null;
  if (notes.includes('|')) {
    return notes.split('|');
  }
  return [notes];
};
