import palette from './palette';
import bottomNavOverrides from './bottomNav';
import inputOverrides from './input';
import typographyOverrides from './typography';
import tableOverrides from './table';
import alertOverrides from './alert';
import { createMuiTheme } from '@material-ui/core';
const defaultTheme = createMuiTheme();
const customShadows = defaultTheme.shadows;
customShadows[1] = '0px 1px 3px rgba(0, 0, 0, 0.2)';
customShadows[2] = '0 1px 10px rgba(0, 0, 0, 0.2)';

const themeCommon = {
  typography: {
    fontFamily: 'Inter',
  },
  palette,
  shadows: customShadows,
  overrides: {
    ...bottomNavOverrides,
    ...inputOverrides,
    ...typographyOverrides,
    ...tableOverrides,
    ...alertOverrides,
  },
  custom: {
    borderRadius: {
      radius4: 4,
      radius5: 5,
      radius6: 6,
      radius10: 10,
      radius12: 12,
      radius20: 20,
      radius25: 25,
      radius40: 40,
      radiusCircle: '50%',
    },
  },
};

export default themeCommon;
