import { TrailerLoadedStatus } from 'constants/TrailerConstants';
import { action, computed, observable } from 'mobx';
import {
  ITrailerData,
  ITrailerDetails,
  ITrailerEventDetails,
  ITrailerLocation,
} from 'models/interfaces/shared/ITrailerData';
import driverAppStore, { DriverAppStore } from 'store/DriverAppStore';
import Load from '../Load';

export default class Trailer {
  rootStore: DriverAppStore;
  @observable vin: string;
  @observable listed: boolean;
  @observable reserved: boolean;
  @observable details: ITrailerDetails;
  @observable as_of: string;
  @observable location: ITrailerLocation;
  @observable loads?: any;
  @observable eventDetails?: ITrailerEventDetails;

  constructor(trailerData: ITrailerData) {
    const { vin, as_of, location, details, loads, eventDetails, listed, reserved } = trailerData;
    this.rootStore = driverAppStore;
    this.vin = vin;
    this.as_of = as_of;
    this.location = location;
    this.details = details;
    this.loads = loads;
    this.eventDetails = eventDetails;
    this.listed = listed;
    this.reserved = reserved;
  }

  @computed get trailerLoadedStatusFormatted() {
    /**
     * if eventDetails doesn't exist OR
     * if eventDetails {} OR
     * if eventDetails {...except isLoaded} OR
     * isLoaded = null
     */
    if (
      !this.eventDetails ||
      Object.keys(this.eventDetails).length === 0 ||
      (Object.keys(this.eventDetails).length &&
        !Object.keys(this.eventDetails).includes('isLoaded')) ||
      this.eventDetails?.isLoaded === null
    ) {
      return '-';
    }
    // isLoaded = true
    if (this.eventDetails?.isLoaded) {
      return TrailerLoadedStatus.LOADED;
    }
    // isLoaded = false
    return TrailerLoadedStatus.UNLOADED;
  }

  @action.bound
  setLoads(loads: Array<Load>) {
    this.loads = loads;
  }
}
