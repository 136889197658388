import { getDriverNameVariation } from 'utils/partnerSpecificStrings';
const baseUrl = '/driver';

let navigation = [
  {
    value: 'loads',
    label: 'Loads',
    url: `${baseUrl}/search`,
    icon: 'icon-search',
    active: ['search', 'ral', 'match', 'load'],
  },
  {
    value: 'dispatch',
    label: 'Dispatch',
    url: `${baseUrl}/dispatch`,
    icon: 'icon-dispatch',
    active: ['dispatch'],
  },
  {
    value: 'drivers',
    label: 'Drivers',
    url: `${baseUrl}/drivers`,
    icon: 'icon-drivers',
    active: ['drivers'],
  },
  {
    value: 'trailers',
    label: 'Trailers',
    url: `${baseUrl}/trailers`,
    icon: 'icon-drivers',
    active: ['trailers'],
  },
  {
    value: 'settings',
    label: 'Settings',
    url: `${baseUrl}/settings`,
    icon: 'icon-settings',
    active: ['settings'],
  },
];

export default navigation;
