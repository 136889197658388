const primary = {
  light: '#6680a0',
  main: '#002C61',
  dark: '#001f44',
  disabled: '#b3c0d0',
  contrastText: '#FFFFFF',
  medium: '#335681',
};
const secondary = {
  light: '#F8E5E9',
  main: '#BC0029',
  dark: '#84001d',
  disabled: '#e499a9',
  medium: '#c93354',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: primary,
  partnerCustomSecondary: secondary,
  background: {
    walkthroughPurple: '#BC0029', // Walkthrough bubble bg, etc
  },
  navy: '#002C61', // Search LP form background, etc
};
export default palette;
