import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { getTokenHeader } from '../GetTokenHeader';
import config from '../../../../config';
import { ApiFail } from '../../../utils/ErrorService';
import { IPostLoadAcceptRejectHelper } from '../../../models/apiResponse/postLoadReject';

export const LOAD_CANNOT_BOOK_IT_NOW_ERROR = 'LOAD_CANNOT_BOOK_IT_NOW';

export class LoadCannotBookItNowError extends Error {}

export const PostLoadAccept = async (loadId: string, isMatch: boolean, payload?: any) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/${isMatch ? 'matches' : 'loads'}/${loadId}/accept`;
  return axios
    .post(uri, { accepted: true, ...payload }, { headers: tokenHeader })
    .then((response: IPostLoadAcceptRejectHelper) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      // If msg in the error has text - LOAD_CANNOT_BOOK_IT_NOW_ERROR then throw the LoadCannotBookItNowError
      // as that needs to be handled specifcally.
      if (
        'msg' in error.response.data &&
        error.response.data.msg.includes(LOAD_CANNOT_BOOK_IT_NOW_ERROR)
      ) {
        throw new LoadCannotBookItNowError(error);
      }
      throw error;
    });
};
