import { observable } from 'mobx';
import { IOfferEvent } from 'models/interfaces/shared/IOfferEvent';
import { IOfferData, IBidData } from 'models/interfaces/shared/IOfferData';
import { OfferStatusTypes } from 'constants/Offer';

class Offer implements IOfferData {
  @observable id: string;
  @observable carrierUserId: string;
  @observable brokerUserId: string;
  @observable loadId: string;
  @observable amount: number;
  @observable datetime: string;
  @observable status: OfferStatusTypes;
  @observable updated_datetime: string;
  @observable events: IOfferEvent[];
  @observable bid_countered_by_carrier: IBidData;
  @observable bid_submitted: IBidData;
  @observable bid_countered_by_broker: IBidData;
  @observable isIntegrated: boolean;

  constructor(offer: IOfferData) {
    if (!offer) {
      throw new Error('Offer is undefined.');
    }
    const {
      id,
      _id,
      carrierUserId,
      brokerUserId,
      loadId,
      amount,
      datetime,
      status,
      updated_datetime,
      events,
      bid_submitted,
      bid_countered_by_carrier,
      bid_countered_by_broker,
      isIntegrated,
    } = offer;
    this.id = id || _id;
    this.carrierUserId = carrierUserId;
    this.brokerUserId = brokerUserId;
    this.loadId = loadId;
    this.amount = amount;
    this.datetime = datetime;
    this.status = status;
    this.updated_datetime = updated_datetime;
    this.events = events;
    this.bid_submitted = bid_submitted;
    this.bid_countered_by_carrier = bid_countered_by_carrier;
    this.bid_countered_by_broker = bid_countered_by_broker;
    this.isIntegrated = isIntegrated;
  }
}

export default Offer;
