import { action, observable } from 'mobx';
import { GetTrailers } from 'services/APIServices/GetTrailersList';
import { GetRentTrailerList } from 'services/APIServices/GetRentTrailerList';
import Trailer from 'models/dataStructures/Trailer';
import { getTrailersByListedStatus } from 'services/APIServices/GetTrailersByListedStatus';
import { putTrailerUpdateListedStatus } from 'services/APIServices/PutTrailerUpdateListedStatus';
import { GetTrailerLoadRecommendations } from 'services/APIServices/GetTrailerLoadRecommendations';
import Load from 'models/dataStructures/Load';
import { createRef } from 'react';
import { CollectionsStore } from './CollectionsStore';
import { DriverAppStore } from './DriverAppStore';

export class TrailersStore {
  rootStore: DriverAppStore;
  @observable trailerResults = new CollectionsStore(this.rootStore, false, GetTrailers, '', this);
  @observable rentTrailerList = new CollectionsStore(
    this.rootStore,
    true,
    GetRentTrailerList,
    '',
    this,
  );
  @observable selectedTrailer: Trailer | null = null;
  @observable unlistedTrailers: Trailer[] = [];
  @observable listedTrailers: Trailer[] = [];
  @observable isFetchingTrailersByStatus = false;
  @observable isUpdatingTrailerStatus = false;
  @observable loadingRecommendations = false;
  @observable isRecommendationsFetched = false;
  @observable vinFilter = '';
  @observable unitIdFilter = '';
  @observable trailerTypeFilter = '';
  @observable activeLoadMarker: string | null = null; // set load marker that is clicked on as active

  constructor(rootStore: DriverAppStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  setSelectedTrailer(trailer: Trailer | null) {
    this.selectedTrailer = trailer;
  }

  @action.bound
  setActiveLoadMarker(loadId: string | null) {
    this.activeLoadMarker = loadId;
  }

  @action.bound
  setUnlistedTrailers(trailers: Trailer[]) {
    this.unlistedTrailers = trailers;
  }

  @action.bound
  setListedTrailers(trailers: Trailer[]) {
    this.listedTrailers = trailers;
  }

  @action.bound
  setIsFetchingTrailersByStatus(status: boolean) {
    this.isFetchingTrailersByStatus = status;
  }

  @action.bound
  setIsUpdatingTrailerStatus(status: boolean) {
    this.isUpdatingTrailerStatus = status;
  }

  @action.bound
  setIsRecommendationsFetched = (flag: boolean) => {
    this.isRecommendationsFetched = flag;
  };

  @action.bound
  setLoadingRecommendations = (flag: boolean) => {
    this.loadingRecommendations = flag;
  };

  @action.bound
  setListFilters = (vin: string, customerUnitId: string, trailerType: string) => {
    if (vin) {
      this.vinFilter = vin;
    } else if (vin === '') {
      this.vinFilter = ''; // Clear old filter
    }
    if (customerUnitId) {
      this.unitIdFilter = customerUnitId;
    } else if (customerUnitId === '') {
      this.unitIdFilter = ''; // Clear old filter
    }

    if (trailerType || trailerType === '') {
      this.trailerTypeFilter = trailerType;
    }
  };

  @action.bound
  downloadResults = async () => {
    this.trailerResults.setError(null);
    this.trailerResults.clearCache();
    const params = {};
    if (this.vinFilter) {
      params.vin = this.vinFilter;
    }
    if (this.unitIdFilter) {
      params.customer_unit_id = this.unitIdFilter;
    }
    if (this.trailerTypeFilter) {
      params.trailer_type = this.trailerTypeFilter;
    }

    await this.trailerResults.downloadResults(params);
  };

  @action.bound
  downloadTrailer = async (vin) => {
    this.setSelectedTrailer(null);
    const fetchFromResults = this.trailerResults.getItemFromResults(vin);
    if (fetchFromResults) {
      this.setSelectedTrailer(fetchFromResults);
    } else {
      // Make api to fetch results if necessary
    }
  };

  @action.bound
  downloadTrailerResultsByListedStatus = async (
    status: 'listed' | 'unlisted',
    onlyUnreserved?: boolean,
  ) => {
    const {
      snackbarStore: { enqueueSnackbarStore },
    } = this.rootStore;

    this.setIsFetchingTrailersByStatus(true);
    try {
      const trailers = await getTrailersByListedStatus(status, onlyUnreserved);
      if (status === 'unlisted') {
        this.setUnlistedTrailers(trailers);
      } else {
        this.setListedTrailers(trailers);
      }
      this.setIsFetchingTrailersByStatus(false);
    } catch (error) {
      enqueueSnackbarStore('Sorry, there was an error fetching your trailers', {
        variant: 'error',
      });
      this.setIsFetchingTrailersByStatus(false);
      throw error;
    }
  };

  @action.bound
  updateTrailerListedStatus = async (status: 'listed' | 'unlisted', vins) => {
    const {
      snackbarStore: { enqueueSnackbarStore },
    } = this.rootStore;

    this.setIsUpdatingTrailerStatus(true);
    try {
      await putTrailerUpdateListedStatus(status, vins);
      this.setIsUpdatingTrailerStatus(false);
    } catch (error) {
      this.setIsUpdatingTrailerStatus(false);
      enqueueSnackbarStore("Sorry, there was an error updating the trailers' statuses", {
        variant: 'error',
      });

      throw error;
    }
  };

  @action.bound
  downloadTrailerRecommendations = async (
    vins: Array<string>,
    trailerStatus?: 'unlisted' | 'listed' | 'available',
  ) => {
    let trailerResults: Trailer[] = [];
    switch (trailerStatus) {
      case 'unlisted':
        trailerResults = this.unlistedTrailers;
        break;
      case 'listed':
        trailerResults = this.listedTrailers;
        break;
      case 'available':
        trailerResults = this.rentTrailerList.results;
        break;
      default:
        trailerResults = this.trailerResults.results;
    }
    const {
      snackbarStore: { enqueueSnackbarStore },
    } = this.rootStore;
    try {
      const payload = { vins };
      this.setLoadingRecommendations(true);
      // Trailer DS recommendations
      const trailersRecommendations = await GetTrailerLoadRecommendations(payload);
      // Set fetched flag to true
      this.setIsRecommendationsFetched(true);
      // Copy trailer list to update with loads
      const trailerList = [...trailerResults];
      // Maps VINS from trailer list with recommendations list
      trailerList.forEach((trailer: Trailer) => {
        const vinLoadRecommendation = trailersRecommendations.find(
          (trailerRecommendation) => trailer.vin === trailerRecommendation.vin,
        );
        // Update loads object in trailer list
        if (vinLoadRecommendation) {
          const loadsList = vinLoadRecommendation.loads
            .map((load) => new Load(load))
            .map((load) => {
              // Add DOM ref to load item to identify it later
              // so that we can scroll to any one in the list
              const loadWithRef = load;
              loadWithRef.ref = createRef();
              return loadWithRef;
            });
          trailer.setLoads(loadsList);
        } else {
          trailer.setLoads([]);
        }
      });
      this.setLoadingRecommendations(false);
    } catch (error) {
      enqueueSnackbarStore('Sorry, there was an error fetching recommended loads', {
        variant: 'error',
      });
      this.setLoadingRecommendations(false);
      this.setIsFetchingTrailersByStatus(false);
      throw error;
    }
  };
}

export default TrailersStore;
