import { action, computed, isObservableArray, observable } from 'mobx';
import { putMatchSentDocuments } from 'services/APIServices/PutMatchSentDocuments';
import { ILoadData } from 'models/interfaces/shared/ILoadData';
import { IPostedBy } from 'models/interfaces/shared/IPostedBy';
import { IPayload } from 'models/interfaces/shared/IPayload';
import { IOfferData } from 'models/interfaces/shared/IOfferData';
import { ICoordinate } from 'models/interfaces/shared/ICoordinate';
import ApiLayer from 'services/APIServices/ApiLayer';
import {
  getAllTrueKeysList,
  getDateTime,
  getPerMileRateForMiles,
  getValueFromFormattedAmount,
  formatNumberToString,
  addCityStateToLocation,
  formatLocationAddressCasing,
  formatPhone,
  formatAmountString,
  getFormattedBrokerName,
} from 'utils/utility';
import { PostLoadDispatch } from 'services/APIServices/PostLoadDispatch';
import { PostLoadAccept } from 'services/APIServices/PostLoadAccept';
import { SubmitLoadOffer } from 'services/APIServices/SubmitLoadOffer';
import { UpdateLoadOffer } from 'services/APIServices/UpdateLoadOffer';
import { CancelLoadOffer } from 'services/APIServices/CancelLoadOffer';
import { PostLoadDecline } from 'services/APIServices/PostLoadDecline';
import { PostLoadUnassign } from 'services/APIServices/PostLoadUnassign';
import driverAppStore, { DriverAppStore } from 'store/DriverAppStore';
import { ILoadAddress } from 'models/interfaces/shared/ILoadAddress';
import StoreBase from 'store/StoreBase';
import { IMatchData } from 'models/interfaces/shared/IMatchData';
import { postMatchCancel } from 'services/APIServices/PostMatchCancel';
import { IInteraction } from 'models/interfaces/shared/INegotiateInteraction';
import Offer from 'models/dataStructures/Offer';
import LoadSources from 'constants/LoadSources';
import { EMPTY_STRING } from 'constants/General';
import { MatchStatus } from 'constants/MatchStatus';
import { LoadStatusCode } from 'constants/LoadStatus';
import Match from '../Match';
import { FOUser } from '../FOUser';

export default class Load {
  rootStore: DriverAppStore;
  @observable loadId: string;
  @observable status: LoadStatusCode;
  @observable trackingNumber: string;
  @observable brokerId: string;
  @observable token: string;
  @observable externalLink?: string;
  @observable source: string;
  @observable customKey: string;
  @observable key: string;
  @observable isIntegrated = false;
  @observable payload: IPayload;
  @observable postedBy: IPostedBy;
  @observable account?: any;
  @observable matchId: string;
  @observable rawLoad: ILoadData;
  @observable matches: Match[];
  @observable offer: IOfferData | null;
  @observable ral_id?: string;
  @observable matchStatus?: MatchStatus;
  @observable certifiedLoad?: boolean = false;
  @observable distanceInfo: google.maps.DistanceMatrixResponse = {
    destinationAddresses: [],
    originAddresses: [],
    rows: [],
  };
  @observable directionInfo: google.maps.DirectionsResult = {
    routes: [],
    geocoded_waypoints: [],
  };
  @observable loadingDirectionInfo = false;
  @observable deadhead = '';
  @observable dropoffDeadhead = '';
  @observable distanceStore = new StoreBase();
  @observable requestCallbackStore = new StoreBase();
  @observable loadBookingStore = new StoreBase();
  @observable feUniqueKey: number;
  @observable availableLoadCosts: string[];

  constructor(loadData: ILoadData, match?: Match) {
    const {
      _id: id,
      loadId,
      status,
      trackingNumber,
      brokerId,
      token,
      externalLink,
      source,
      customKey,
      key,
      payload,
      postedBy,
      matches,
      account,
      offer,
      deadhead,
      dropoffDeadhead,
      feUniqueKey,
      availableLoadCosts,
      certifiedLoad,
    } = loadData;
    this.rawLoad = loadData;
    this.rootStore = driverAppStore;
    this.loadId = id || loadId;
    this.status = status;
    this.trackingNumber = trackingNumber;
    this.brokerId = brokerId;
    this.token = token;
    this.externalLink = externalLink;
    this.source = source;
    this.offer = offer ? new Offer(offer) : null;
    this.customKey = customKey;
    this.key = key;
    this.payload = payload;
    this.postedBy = postedBy;
    this.matches = matches ? matches.map((match) => new Match(match)) : match ? [match] : [];
    this.matchId = (match && match.id) || this.matches[0]?.id || '';
    this.account = account;
    this.deadhead = deadhead ?? '';
    this.dropoffDeadhead = dropoffDeadhead || '';
    this.feUniqueKey = feUniqueKey;
    this.availableLoadCosts = availableLoadCosts || [];
    this.certifiedLoad = certifiedLoad;
  }

  @computed get id() {
    return this.loadId;
  }

  @computed get isMatch() {
    return Boolean(this.matchId && this.matchId !== this.loadId);
  }

  @computed get showOffer() {
    return this.rootStore.configStore.offerSources.includes(this.source) &&
      !this.rootStore.configStore.isTrailerSolution;
  }

  @computed get hasOffer() {
    return this.offer && this.offer.amount;
  }

  @computed get contentDetails() {
    return this.payload.loads[0].loadContentDetails;
  }

  @computed get contact() {
    if (!this?.postedBy && !this?.account) {
      return null;
    }
    return {
      displayName: this?.postedBy?.displayName || this?.account?.contactName || '',
      email:
        (this?.postedBy?.email && this?.postedBy?.email.toLowerCase().includes('donotsend')) ||
          (!this?.postedBy?.email && !this?.account?.contactEmail)
          ? ''
          : this?.postedBy?.email || this?.account?.contactEmail,
      phone: formatPhone(this?.postedBy?.phone || this?.account?.contact_phone || ''),
      companyName: this?.postedBy?.companyName || this?.account?.companyName || '',
    };
  }

  @computed get sourcePascalCase() {
    return this.source.charAt(0).toUpperCase() + this.source.slice(1);
  }

  // Using sourcePascalCase doesn't work for cases like `chr`
  // Therefore we check if `getFormattedBrokerName` can produce a result else fallback to `sourcePascalCase`
  @computed get sourceDisplayName() {
    return getFormattedBrokerName(this.source) || this.sourcePascalCase;
  }

  @computed get scrapedLoad() {
    return Boolean(this.loadTruckerpath || this.loadCoyote);
  }

  @computed get loadSmart() {
    return this.source === LoadSources.LOADSMART;
  }

  @computed get loadPostEverywhere() {
    return this.source === LoadSources.POSTEVERYWHERE;
  }

  @computed get loadSchneider() {
    return this.source === LoadSources.SCHNEIDER;
  }

  @computed get loadConvoy() {
    return this.source === LoadSources.CONVOY;
  }

  @computed get loadParade() {
    return this.source === LoadSources.PARADE;
  }

  @computed get loadUber() {
    return this.source === LoadSources.UBER;
  }

  @computed get loadEdgeLogistics() {
    return this.source === LoadSources.EDGELOGISTICS;
  }

  @computed get loadVerihaLogistics() {
    return this.source === LoadSources.VERIHALOGISTICS;
  }

  @computed get loadEnglandLogistics() {
    return this.source === LoadSources.ENGLANDLOGISTICS;
  }

  @computed get loadMcleod() {
    return this.source === LoadSources.MCLEOD;
  }

  @computed get loadSourceIntegrationExists() {
    if (this.loadEmerge) {
      return this.rootStore.userStore.isEmergeApproved;
    }
    return this.rootStore.userStore.FOUser?.truckIntegrationsList.includes(this.source);
  }

  @computed get canBookItNow() {
    const canBookItNow = this.payload?.loadPay?.bookItNow?.canBookItNow;
    return (canBookItNow === 'true' || canBookItNow === true) && this.loadSourceIntegrationExists;
  }

  /**
   * Load Action buttons visibility
   */
  @computed get showBookButton() {
    // We don't want to show a book button for CHR loads that don't have `availableLoadCosts` object or rate
    const showBookButtonForCHR = this?.flatRate?.perMileRate && this.availableLoadCosts?.length;
    // Logic to figure out if book button can be shown is based on certified loads computed property
    return (
      // TODO: Re-add this check when the BE apis support sending this information.
      // Disabled the certified load check as we need to update the BE search & matches apis to compute & send the value for certifiedLoad.
      // The canBookItNow will primarily control whether a load can be booked on the not.
      // this.certifiedLoad &&
      this.canBookItNow &&
      !this.bookedOrCompletedMatch &&
      !this.anyMatchBookedPending &&
      (this.loadCHRobinson ? showBookButtonForCHR : true)
    );
  }

  @computed get loadCHRobinson() {
    return this.source === LoadSources.CH_ROBINSON;
  }

  @computed get loadShipwell() {
    return this.source === LoadSources.SHIPWELL;
  }

  @computed get loadTruckerpath() {
    return this.source === LoadSources.TRUCKERPATH;
  }

  @computed get loadCoyote() {
    return this.source === LoadSources.COYOTE;
  }

  @computed get loadFleetops() {
    return this.source && this.source.toLowerCase() === LoadSources.FLEETOPS.toLowerCase();
  }

  @computed get loadZengistics() {
    return this.source && this.source.toLowerCase() === LoadSources.ZENGISTICS.toLowerCase();
  }

  @computed get loadEmerge() {
    return this.source && this.source.toLowerCase() === LoadSources.EMERGE.toLowerCase();
  }

  @computed get loadRpm() {
    return this.source && this.source.toLowerCase() === LoadSources.RPM.toLowerCase();
  }

  @computed get loadGenpro() {
    return this.source && this.source.toLowerCase() === LoadSources.GENPRO.toLowerCase();
  }

  @computed get loadAfc() {
    return this.source && this.source.toLowerCase() === LoadSources.AFC.toLowerCase();
  }

  @computed get loadTumaloCreek() {
    return this.source && this.source.toLowerCase() === LoadSources.TUMALO_CREEK.toLowerCase();
  }

  @computed get loadTct() {
    return this.source && this.source.toLowerCase() === LoadSources.TCT.toLowerCase();
  }

  @computed get loadGpTransco() {
    return this.source && this.source.toLowerCase() === LoadSources.GP_TRANSCO.toLowerCase();
  }

  @computed get loadCargoExpress() {
    return this.source && this.source.toLowerCase() === LoadSources.CARGO_EXPRESS.toLowerCase();
  }

  @computed get isSchneiderLocked() {
    return this.loadSchneider && !this.rootStore.userStore.hasSchneiderIntegration;
  }

  @computed get isCHRApproved() {
    return this.loadCHRobinson && this.rootStore.userStore.isCHRApproved;
  }

  @computed get isCHRPendingApproval() {
    return this.loadCHRobinson && !this.rootStore.userStore.isCHRApproved;
  }

  @computed get pickups(): ILoadAddress[] {
    return [
      {
        location: this.payload.tripDetails.pickupLocation,
        address: addCityStateToLocation(this.payload.tripDetails.pickupAddress).address,
        startDateTime: getDateTime(
          this.payload.tripDetails.pickupStartDate,
          this.payload.tripDetails.pickupStartTime,
        ),
        endDateTime: getDateTime(
          this.payload.tripDetails.pickupEndDate,
          this.payload.tripDetails.pickupEndTime,
        ),
        lat: this.payload.tripDetails.pickupCoordinates.lat,
        lng: this.payload.tripDetails.pickupCoordinates.lng,
        startTime: this.payload.tripDetails.pickupStartTime,
        endTime: this.payload.tripDetails.pickupEndTime,
        city: this.payload.tripDetails.pickupAddress.city,
        state: this.payload.tripDetails.pickupAddress.state,
        country: this.payload.tripDetails.pickupAddress.country,
        streetNumber: this.payload.tripDetails.pickupAddress.streetNumber,
        streetName: this.payload.tripDetails.pickupAddress.streetName,
        postalCode: this.payload.tripDetails.pickupAddress.postalCode,
        county: this.payload.tripDetails.pickupAddress.county,
      },
    ];
  }

  @computed get dropoffs(): ILoadAddress[] {
    return this.payload.tripDetails.dropoffs.map((dropoff) => ({
      location: dropoff.dropoffLocation,
      address: addCityStateToLocation(dropoff.dropoffAddress).address,
      startDateTime: getDateTime(dropoff.dropoffStartDate, dropoff.dropoffStartTime),
      endDateTime: getDateTime(dropoff.dropoffEndDate, dropoff.dropoffEndTime),
      lat: dropoff.dropoffCoordinates.lat,
      lng: dropoff.dropoffCoordinates.lng,
      startTime: dropoff.dropoffStartTime,
      endTime: dropoff.dropoffEndTime,
      city: dropoff.dropoffAddress.city,
      state: dropoff.dropoffAddress.state,
      country: dropoff.dropoffAddress.country,
      streetNumber: dropoff.dropoffAddress.streetNumber,
      streetName: dropoff.dropoffAddress.streetName,
      postalCode: dropoff.dropoffAddress.postalCode,
      county: dropoff.dropoffAddress.county,
    }));
  }

  @computed get firstPickupOriginCoordinates(): ICoordinate | undefined {
    if (this.pickups[0].lat && this.pickups[0].lng) {
      return { lat: this.pickups[0].lat, lng: this.pickups[0].lng };
    }
  }

  @computed get firstDropoffOriginCoordinates(): ICoordinate | undefined {
    if (this.dropoffs[0].lat && this.dropoffs[0].lng) {
      return { lat: this.dropoffs[0].lat, lng: this.dropoffs[0].lng };
    }
  }

  @computed get equipmentTypes() {
    if (this.contentDetails) {
      if (isObservableArray(this.contentDetails.equipmentTypes)) {
        return this.contentDetails.equipmentTypes;
      }
      return getAllTrueKeysList(this.contentDetails.equipmentTypes);
    }
  }

  @computed get equipmentSpecifications() {
    if (this.contentDetails) {
      if (isObservableArray(this.contentDetails.equipmentSpecifications)) {
        return this.contentDetails.equipmentSpecifications;
      }
      return getAllTrueKeysList(this.contentDetails.equipmentSpecifications);
    }
  }

  @computed get equipmentTypeFormatted() {
    if (this.equipmentTypes) {
      return this.equipmentTypes.join(', ');
    }
  }

  @computed get distanceInMiles() {
    if (this.account.distance) {
      return `${Math.round(this.account.distance)} mi`;
    }
    return this.distanceExists()
      ? Math.round(this.distanceInfo.rows[0].elements[0].distance.text)
      : '';
  }

  @computed get offerRatePerMile() {
    if (this.offer && this.offer.amount && this.distanceInMiles) {
      const ratePermile = this.offer.amount / getValueFromFormattedAmount(this.distanceInMiles);
      return Math.round(ratePermile * 100) / 100;
    }
    return 0;
  }

  /**
   * Format all basic request data that is displayed
   * Mainly creating a flat object for table column sorting
   * but can be used to get all required display info from this object
   */
  @computed get loadDisplayInfo() {
    return {
      pickupLocation: this.pickups && formatLocationAddressCasing(this.pickups[0]?.address),
      pickupDate: this.pickups && this.pickups[0]?.startDateTime,
      dropoffLocation: this.dropoffs && formatLocationAddressCasing(this.dropoffs[0]?.address),
      dropoffDate: this.dropoffs && this.dropoffs[0]?.startDateTime,
      distanceInMilesFmt: this.distanceInMiles,
      equipmentTypeFormatted: this.equipmentTypeFormatted,
      weightWithUnits: this.weightWithUnits,
      deadhead: this.deadheadInMiles,
      perMileRate: this.payload?.loadPay?.perMileRate || this.perMileRate?.price,
      rateCurrency: this.payload?.loadPay?.currency,
      flatRate: this.payload?.loadPay?.amount,
      source: this.source,
    };
  }

  @computed get deadheadInMiles() {
    if (this.deadhead || this.deadhead === 0) {
      return this.deadhead;
    }
  }

  @computed get flatRate() {
    return this.payload.loadPay.priceIsEstimated || this.payload.loadPay.amount <= 1
      ? null
      : this.payload.loadPay;
  }

  @computed get perMileRate() {
    if (
      this.distanceExists() &&
      this.payload.loadPay.amount &&
      !this.payload.loadPay.priceIsEstimated
    ) {
      return {
        ...getPerMileRateForMiles(
          getValueFromFormattedAmount(this.distanceInMiles),
          this.payload.loadPay.amount,
        ),
        currency: this.payload.loadPay.currency,
      };
    }
    return null;
  }

  @computed get freightType() {
    return this.contentDetails.ftl === undefined
      ? undefined
      : this.contentDetails.ftl
        ? 'FTL'
        : 'LTL';
  }

  @computed get isMultipleDropOffPickup() {
    return this.pickups.length > 1 || this.dropoffs.length > 1;
  }

  @computed get weightWithUnits() {
    if (this.contentDetails.weight && this.contentDetails.weight.amount) {
      return `${formatNumberToString(this.contentDetails.weight.amount)} ${
        this.contentDetails.weight.unit
        }`;
    }
    return EMPTY_STRING;
  }

  @computed get weight() {
    if (this.contentDetails.weight && this.contentDetails.weight.amount) {
      return `${formatNumberToString(this.contentDetails.weight.amount)}`;
    }
    return EMPTY_STRING;
  }

  @computed get dimensions() {
    if (!this.contentDetails) {
      return EMPTY_STRING;
    }
    const {
      height: { amount: hAmount, unit: hUnit },
      length: { amount: lAmount, unit: lUnit },
      width: { amount: wAmount, unit: wUnit },
    } = this.contentDetails.dimensions;
    const dimensions = {
      height: '',
      length: '',
      width: '',
    };
    if (hAmount) {
      dimensions.height = `${hAmount} ${hUnit}`;
    }
    if (lAmount) {
      dimensions.length = `${lAmount} ${lUnit}`;
    }
    if (wAmount) {
      dimensions.width = `${wAmount} ${wUnit}`;
    }
    return dimensions;
  }
  @computed get lengthFormatted() {
    return this.dimensions.length;
  }

  @computed get commodity() {
    if (!this.contentDetails || !this.contentDetails.commodityDescription) {
      return '';
    }
    const {
      commodityDescription: { description },
    } = this.contentDetails;
    let commodity = '';
    if (description) {
      commodity = `${description}`;
    }
    return commodity;
  }

  @computed get careInstructions() {
    if (!this.contentDetails || !this.contentDetails.commodityDescription) {
      return '';
    }
    const {
      commodityDescription: { specialCareInstructions },
    } = this.contentDetails;
    let careInstructions = '';
    if (specialCareInstructions) {
      careInstructions = `${specialCareInstructions}`;
    }
    return careInstructions;
  }

  @computed get hazardous() {
    if (!this.contentDetails?.commodityDescription) {
      return '';
    }
    let hazardous = '';
    const {
      commodityDescription: { hazardousMaterialsCheck },
    } = this.contentDetails;
    if (hazardousMaterialsCheck) {
      hazardous = 'Hazardous Material: &#x2713';
    }
    return hazardous;
  }

  @computed get shippingNotes() {
    let notes = '';

    if (this.careInstructions) {
      notes += `${this.careInstructions} `;
    }
    if (this.hazardous) {
      notes += `${this.hazardous}`;
    }

    // Filter out all 'None' values from the shipping notes
    const filteredNotes = notes.split('|').filter((note) => !note.includes('None'));
    return filteredNotes.join('|').trim();
  }

  @computed get anyMatchAutomatedTrackingAssigned() {
    return this.matches.some((match) =>
      Boolean(match.isAutomatedTracking && match.currentlyBeingTracked?.linkedMatchId),
    );
  }

  @computed get anyMatchActiveRequestedCallback() {
    const matchFound = this.matches.find((match) => Boolean(match.matchActiveRequestedCallback));
    if (matchFound) {
      return matchFound.matchActiveRequestedCallback;
    }
    return null;
  }

  @computed get assignedTruckId() {
    const matchFound = this.matches.find((match) => Boolean(match.dispatchAssignedEvent));
    if (matchFound) {
      return matchFound.dispatchAssignedEvent?.truckId;
    }
    return null;
  }

  @computed get getDispatchAssignedEvent() {
    const matchFound = this.matches.find((match) => Boolean(match.dispatchAssignedEvent));
    if (matchFound) {
      return matchFound.dispatchAssignedEvent;
    }
    return null;
  }

  @computed get getReceivedDispatchEvent() {
    const matchFound = this.matches.find((match) => Boolean(match.receivedDispatchEvent));
    if (matchFound) {
      return matchFound.receivedDispatchEvent;
    }
    return null;
  }

  @computed get anyMatchBookedPending() {
    return this.matches.find((match) => Boolean(match.matchBookedPending));
  }

  @computed get anyMatchShared() {
    return this.matches.find((match) => Boolean(match.matchShared));
  }

  @computed get loadBooked() {
    return this.status === LoadStatusCode.BOOKED;
  }

  @computed get loadAssigned() {
    return Boolean(this.matches.find((match) => match.matchAssigned));
  }

  @computed get matchBySelfMatchId() {
    // This continues the logic of isMatch
    // If the load is made from a match reference, then this.matches array holds only the match ref
    // That is why we can safely return the first match from the matches array
    if (this.isMatch) {
      return this.matches[0];
    }
    return null;
  }

  @computed get bookedMatch() {
    return this.matches.find((match) => match.matchBooked);
  }

  @computed get transitMatch() {
    return this.matches.find((match) => match.matchInTransit);
  }

  @computed get completedMatch() {
    return this.matches.find((match) => match.matchCompleted);
  }

  @computed get bookedOrCompletedMatch() {
    return this.completedMatch || this.transitMatch || this.bookedMatch;
  }

  @computed get isCancelled() {
    return this.matches.find((match) => match.matchCancelled);
  }

  @computed get getLoadDetailsPath() {
    return this.isMatch
      ? `/driver/match/${this.matchId}/detail`
      : `/driver/load/${this.loadId}/detail/${this?.source}`;
  }

  @computed get companyLogo() {
    return this?.postedBy?.companyLogo;
  }

  @computed get driverTruckName() {
    if (this.isMatch) {
      const matchFound = this.matchBySelfMatchId;
      if (matchFound) {
        const driverTruck = this.rootStore.userStore.dispatcherTrucks.find(
          (truck) => truck.personId === matchFound.personId,
        );
        if (driverTruck) {
          return driverTruck.driverFullName;
        }
      }
    }
    return null;
  }

  @computed get isTagSortedLoad() {
    return this.payload?.bestPrice || this.payload?.bestPerMileRate || this.payload?.bestDeadhead;
  }

  @action.bound
  updateDeadhead(deadhead) {
    this.deadhead = deadhead;
  }

  @action.bound
  updateDropoffDeadhead(dropoffDeadhead) {
    this.dropoffDeadhead = dropoffDeadhead;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  async downloadLoadWithDistanceInMiles() {
    if (this.distanceInfo.rows.length <= 0) {
      try {
        this.distanceStore.setLoading(true);
        const pickup = this.pickups[0];
        const dropoff = this.dropoffs[0];
        const distanceInfo = await ApiLayer.getDistance(
          pickup.lat,
          pickup.lng,
          dropoff.lat,
          dropoff.lng,
        );
        this.setDistanceInfo(distanceInfo);
      } catch (error) {
        console.log(`Error downloading Google Distance API: ${error}`);
      } finally {
        this.distanceStore.setLoading(false);
      }
    }
  }

  @action.bound
  setDistanceInfo(distanceInfo: google.maps.DistanceMatrixResponse) {
    this.distanceInfo = distanceInfo;
  }

  distanceExists() {
    return (
      this.distanceInfo.rows &&
      this.distanceInfo.rows.length > 0 &&
      this.distanceInfo.rows[0] &&
      this.distanceInfo.rows[0].elements[0].distance &&
      this.distanceInfo.rows[0].elements[0].distance.text &&
      this.distanceInfo.rows[0].elements[0].distance.value
    );
  }

  @action.bound
  setStatus(newLoadStatus: LoadStatusCode) {
    this.status = newLoadStatus;
  }

  @action.bound
  setMatches(matches: IMatchData[]) {
    this.matches = matches.map((match) => new Match(match));
    this.matchId = this.matches[0]?.id || '';
  }

  @action.bound
  setOffer(offer: IOfferData) {
    if (offer) {
      this.offer = new Offer(offer);
    } else {
      this.offer = null;
    }
  }

  @action.bound
  updateMatches(matchOrLoad: ILoadData | IMatchData) {
    if (this.isMatch) {
      const matchFoundIndex = this.matches.findIndex((match) => match.matchId === this.matchId);

      if (matchFoundIndex !== -1) {
        const newMatchFound = new Match(matchOrLoad);

        const updatedMatches = [...this.matches];
        updatedMatches[matchFoundIndex] = newMatchFound;

        this.setMatches(updatedMatches);
      }
    } else {
      const { matches } = matchOrLoad as ILoadData;
      this.setMatches(matches || []);
    }
  }

  @action.bound
  calculateDeadheadInMiles = async (currentCoordinates: ICoordinate) => {
    let lat = 0;
    let lng = 0;
    if (!currentCoordinates || (!currentCoordinates.lat && !currentCoordinates.lng)) {
      const currentCoordinatesAPIResponse = await ApiLayer.getCurrentCoordinates();
      const { location } = currentCoordinatesAPIResponse;
      lat = location.lat;
      lng = location.lng;
    }
    if (!this.deadhead) {
      try {
        const pickup = this.pickups[0];
        const distanceFromAPI = await ApiLayer.getDistance(
          pickup.lat,
          pickup.lng,
          lat || currentCoordinates.lat,
          lng || currentCoordinates.lng,
        );
        let distanceText = distanceFromAPI.rows[0].elements[0].distance.text;
        if (distanceText) {
          const [distance, unit] = distanceText.split(' ');
          if (unit === 'ft') {
            distanceText = '0 mi';
          } else {
            distanceText = `${Math.round(+distance)} ${unit}`;
          }
        }
        this.updateDeadhead(distanceText);
        return distanceText;
      } catch (error) {
        return this.deadhead;
      }
    }
    return this.deadhead;
  };

  @action.bound
  dispatchLoadToDriver = async (driver: FOUser) => {
    try {
      const matchOrLoad = await PostLoadDispatch(
        this.isMatch ? this.matchId : this.loadId,
        driver?.truckId,
        this.isMatch,
      );
      this.updateMatches(matchOrLoad);
    } catch (error) {
      throw error;
    }
  };

  @action.bound
  unassignDriverFromLoad = async () => {
    try {
      const matchOrLoad = await PostLoadUnassign(this.loadId, this.matchId);
      this.updateMatches(matchOrLoad);
    } catch (error) {
      throw error;
    }
  };

  @action.bound // used for dispatchable driver accept & book shipment
  acceptLoad = async (payload?: any) => {
    try {
      const matchOrLoad = await PostLoadAccept(
        this.isMatch ? this.matchId : this.loadId,
        this.isMatch,
        { loadSource: this.source, ...payload },
      );
      this.updateMatches(matchOrLoad);
    } catch (error) {
      throw error;
    }
  };

  @action.bound // used for dispatchable driver accept & book shipment
  submitLoadOffer = async (amount) => {
    try {
      let offer;
      if (this.offer?.id) {
        offer = await UpdateLoadOffer(this.offer?.id, parseFloat(formatAmountString(amount)));
      } else {
        offer = await SubmitLoadOffer(
          this.loadId,
          parseFloat(formatAmountString(amount)),
          this.source,
        );
      }
      this.setOffer(offer);
    } catch (error) {
      throw error;
    }
  };

  // Cancel a previous offer
  @action.bound
  cancelLoadOffer = async () => {
    try {
      if (this.offer?.id) {
        const offer = await CancelLoadOffer(this.offer?.id);
        if (offer.offerId) {
          this.setOffer(null);
          this.rootStore.snackbarStore.enqueueSnackbarStore('Offer Cancelled', {
            variant: 'success',
          });
        } else {
          this.rootStore.snackbarStore.enqueueSnackbarStore('Failed to cancel bid', {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };

  @action.bound
  declineLoad = async (userType: 'driver' | 'dispatcher', reason?: string) => {
    try {
      const matchOrLoad = await PostLoadDecline(
        this.isMatch ? this.matchId : this.loadId,
        this.isMatch,
        userType,
        reason,
      );
      this.updateMatches(matchOrLoad);
    } catch (error) {
      throw error;
    }
  };

  @action.bound
  bookShipment = async () => {
    try {
      this.loadBookingStore.setLoading(true);
      // Dispatcher books matches/loads for drivers
      // Handle load case as usual
      // When a match is being booked, we need to make sure the match had been dispatched
      // RDSP event is added for their drivers' matches
      // If RDSP event is found, use the matchId from the event to book
      // If RDSP event is not found, dispatch the load
      // Then use the matchId from RDSP event to book the match
      if (
        this.rootStore.userStore.dispatcher &&
        this.isMatch &&
        this.matchBySelfMatchId?.personId !== this.rootStore.userStore.FOUser.truck?.personId
      ) {
        if (this.matchBySelfMatchId.receivedDispatchEvent) {
          // RDSP event found, directly book the load
          await PostLoadAccept(this.matchBySelfMatchId.receivedDispatchEvent.matchId, true);
        } else {
          // RDSP event not found, first dispatch and then book the load
          const matchDriverTruck = this.rootStore.userStore.FOUser.drivers?.find(
            (driver) => driver.personId === this.matchBySelfMatchId?.personId,
          );
          if (matchDriverTruck) {
            await this.dispatchLoadToDriver(matchDriverTruck);
            if (this.matchBySelfMatchId.receivedDispatchEvent) {
              const { matchId } = this.matchBySelfMatchId.receivedDispatchEvent as IInteraction;
              await PostLoadAccept(matchId, true);
            }
          }
        }
      } else {
        await this.acceptLoad();
      }
      this.loadBookingStore.setLoading(false);
      if (
        !this.rootStore.matchStore.activeLoads.results.find((load) => load.matchId === this.matchId)
      ) {
        this.rootStore.matchStore.activeLoads.setResults([
          this,
          ...this.rootStore.matchStore.activeLoads.results,
        ]);
      }
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore('Technical error booking your shipment', {
        variant: 'error',
      });
      this.loadBookingStore.setLoading(false);
      throw error;
    }
  };

  @action.bound
  cancelShipment = async () => {
    if (this.isMatch) {
      try {
        await postMatchCancel(this.matchId);
        this.matches[0].setStatus(LoadStatusCode.CANCELLED);
        this.rootStore.snackbarStore.enqueueSnackbarStore('Shipment cancelled', {
          variant: 'warning',
        });
      } catch (error) {
        console.log(error);
        this.rootStore.snackbarStore.enqueueSnackbarStore(
          'Technical error trying to cancel shipment',
          { variant: 'error' },
        );
        throw error;
      }
    }
  };

  @action.bound
  markLoadComplete = async () => {
    if (this.isMatch) {
      try {
        await ApiLayer.markLoadComplete(this.matchId);
        this.matches[0].setStatus(MatchStatus.COMPLETED);
        this.rootStore.snackbarStore.enqueueSnackbarStore('Shipment status changed', {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
        this.rootStore.snackbarStore.enqueueSnackbarStore(
          'Technical error trying to cancel shipment',
          { variant: 'error' },
        );
        throw error;
      }
    }
  };

  @action.bound
  sendDocuments = async (documents) => {
    try {
      const match = await putMatchSentDocuments(
        this.isMatch ? this.matchId : this.loadId,
        documents,
      );
      this.updateMatches(match);
      this.rootStore.snackbarStore.enqueueSnackbarStore('Documents sent successfully', {
        variant: 'success',
      });
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Technical error trying to send documents',
        { variant: 'error' },
      );
      throw error;
    }
  };
}
