import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { IPalette, IThemeOptions } from 'theme/ITheme';
import themeCommon from 'theme/themeCommon';
import customPalette from './palette';
import buttonOverrides from './buttons';

const palette: IPalette = {
  ...themeCommon.palette,
  ...customPalette,
  background: {
    ...themeCommon.palette.background,
    ...customPalette.background,
  },
};
const overrides = { ...themeCommon.overrides, ...buttonOverrides };

const ezloadzTheme = createMuiTheme({
  ...themeCommon,
  palette,
  overrides,
} as IThemeOptions);

export default ezloadzTheme;
