import { GrowthBook } from '@growthbook/growthbook-react';
import axios from 'axios';
import { mixpanelTrack } from 'services/FOMixpanel';
import { MIXPANEL_KEYS } from 'constants/Mixpanel';
import config from '../../../config';

const SDK_CLIENT_KEY = config.growthBookSdkClientKey;
const API_KEY = 'key_6593eafd1b972a7d';
const EXPERIMENT_ENDPOINT = `https://cdn.growthbook.io/config/${API_KEY}`;

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',

  clientKey: SDK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    mixpanelTrack(MIXPANEL_KEYS.EXPERIMENT_VIEWED, {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

const getExperimentSettings = async (): Promise<{
  overrides: Record<string, any>;
  experiments: Record<string, any>;
}> => {
  try {
    const response = await axios.get(EXPERIMENT_ENDPOINT);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getAttributes = () => growthbook.getAttributes();
const getAllFeatures = () => growthbook.getFeatures();
const isFeatureFlagActive = (featureFlag: string) => {
  getAttributes();
  const allFeatures = getAllFeatures();
  if (allFeatures?.[featureFlag]?.defaultValue) {
    return allFeatures?.[featureFlag]?.defaultValue;
  }
  return false;
};

const updateAttributes = (updatedProperty) => {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    updatedProperty,
  });
};

export { growthbook, getExperimentSettings, isFeatureFlagActive, getAttributes, updateAttributes };
