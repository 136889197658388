import React from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { IDriverAppStore } from 'models/dataStructures/IDriverAppStore';
import { DriverAppStore } from 'store/DriverAppStore';
import CustomTypography from 'components/CustomTypography';
import INTIAL_LOADING_TEXT from 'constants/InitialLoadingText';

interface LoadingTextOwnProps { }

type LoadingTextProps = IDriverAppStore & LoadingTextOwnProps;

const LoadingText = inject('driverAppStore')(
  observer(({ driverAppStore }: LoadingTextProps) => {

    return (
      <Box>
        <Box mb={2}>
          <CustomTypography variant='h2' textAlign='center' gutterBottom>
            {INTIAL_LOADING_TEXT.title}
          </CustomTypography>
        </Box>
        <CustomTypography color='textSecondary' textAlign='center'>
          {INTIAL_LOADING_TEXT.content}
        </CustomTypography>
      </Box>
    );
  }),
);

export default LoadingText;
