import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import config from 'config';
import { ApiFail } from 'utils/ErrorService';
import { IGetRelationsHelper } from 'models/apiResponse/getRelations';
import { DriverTruck } from 'models/dataStructures/DriverTruck';
import { getTokenHeader } from '../GetTokenHeader';

interface IRelationshipParams {
  is_driver?: boolean;
  relationship_type?: 'dispatcher' | 'dispatchableDriver';
}
export const GetRelations = async (params: IRelationshipParams) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/users/me/relationships`;
  return axios
    .get(uri, {
      headers: tokenHeader,
      params,
    })
    .then((response: IGetRelationsHelper) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
