import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';
const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  img: {
    width: '100%',
    padding: '20px',
    maxWidth: 300,
  },
}));
export default useStyles;
