import React from 'react';
import { render } from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { Router } from 'react-router-dom';
import driverAppStore from 'store/DriverAppStore';
import App from './App';
import config from './config';
import { datadogRum } from '@datadog/browser-rum';
import { axiosRetryInterceptor } from 'services/APIServices';
import 'snippets/freshworks';
import 'snippets/clairity';
import 'snippets/hotjar';
import 'react-virtualized/styles.css';
import './assets/stylesheets/main.scss';

// Init datadog if config exists
if (config?.datadog) {
  datadogRum.init({
    applicationId: config?.datadog?.applicationId,
    clientToken: config?.datadog?.clientToken,
    site: 'datadoghq.com',
    service: config?.datadog?.service,
    env: config.env,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

axiosRetryInterceptor();

function renderWithHotReload(AppComponent: React.ComponentType) {
  // pull history mechanism for router from the config store
  const {
    configStore: { history },
  } = driverAppStore;

  render(
    <Router history={history}>
      <MobxProvider driverAppStore={driverAppStore}>
        <AppComponent />
      </MobxProvider>
    </Router>,
    document.getElementById('root'),
  );
}

renderWithHotReload(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    const nextApp = require('./App').default;
    renderWithHotReload(nextApp);
  });
}
