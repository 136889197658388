import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import config from 'config';
import { ApiFail } from 'utils/ErrorService';
import { IGetFeatureModalsHelper } from 'models/apiResponse/getFeatureModals';
import { getTokenHeader } from '../GetTokenHeader';

export const GetFeatureModal = async () => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/users/fetch-feature-modal`;
  return axios
    .get(uri, {
      headers: tokenHeader,
      params: { modal_name: '' },
    })
    .then((response: IGetFeatureModalsHelper) => {
      return response.data.data;
    })
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
