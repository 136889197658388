import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    height: '100%',
    padding: theme.spacing(9),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(9),
    },
  },
  content: {
    marginTop: theme.spacing(-4), // since the body container has extra padding at the top
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  },
  root: {
    marginBottom: theme.spacing(6),
    height: 10,
    borderRadius: 5,
    background: theme.palette.grey[300],
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  fullWidth: {
    width: '100%',
  },
}));
export default useStyles;
