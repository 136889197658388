import { getTokenHeader } from '../GetTokenHeader';
import axios from 'axios';
import config from 'config';
import Truck from 'models/dataStructures/Trucks';
import { Pagination } from 'models/interfaces/shared/IPagination';
import { ApiFail } from 'utils/ErrorService';
import Load from 'models/dataStructures/Load';

export const GetSubmittedLoads = async () => {
  const tokenHeader = await getTokenHeader();
  let url = `${config.apiUrlV2}/offers/submitted`;
  return axios({
    method: 'GET',
    url,
    headers: tokenHeader,
    maxRedirects: 0,
  })
    .then((response) => {
      if (response.data && response.data.data) {
        return {
          data: response.data.data.reduce((acc, load) => {
            try {
              acc.push(new Load(load));
            } catch (error) {
              console.log(`Search Error parsing truckData list: ${error}`);
            }
            return acc;
          }, []),
          pagination: {
            ...new Pagination(),
            totalMatches: response.data.data.length,
          },
        };
      }
      return {
        data: [],
        pagination: new Pagination(),
      };
    })
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
