const config = {
  ssoUrl: 'https://staging.gorillasafety.com/auth/login', // Gorilla Safety Staging
  clarityId: '86rztxpizr',
  datadog: {
    applicationId: '7bb2c568-6de6-4eb0-9976-170f6cee1920',
    clientToken: 'pub5a455dd933592a6db10684803ab00a95',
    service: 'eld-loads-staging',
  },
};

export default config;
