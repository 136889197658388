export default {
  DRIVER: 'driver', // Owner/Operator
  DISPATCHABLE_DRIVER: 'dispatchableDriver', // Dispatchable driver without search
  DISPATCHABLE_DRIVER_W_SEARCH: 'dispatchableDriverWSearch', // Dispatchable driver with search
  DISPATCHER: 'dispatcher',
  DISPATCHER_W_DRIVER: 'dispatcherWDriver', // Dispatcher + driver
};

export const PermissionTypes = {
  DRIVER: `Driver (View)`,
  DRIVER_SEARCH: `Driver (Search)`,
  OWNER_OPERATOR: `Owner/Operator`,
};
