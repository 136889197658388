export enum MatchStatus {
  automatedMatch = 'AU',
  LOAD_LOST = 'LST',
  BOOKED = 'BO',
  ENROUTE_TO_PICKUP = 'ENPU',
  AT_PICKUP = 'ATPU',
  ENROUTE_TO_DROPOFF = 'ENDO',
  AT_DROPOFF = 'ATDO',
  COMPLETED = 'CO',
  CANCELLED = 'CAN',
  requestedALoaddMatch = 'RAL',
  activeMatch = 'active',
  activeMatchDispatcher = 'active_dispatcher',
  activeMatchDispatchableDriver = 'active_dispatchable_driver',
  activeMatchOwnerOperator = 'active_owner_operator',
  NEARBY = 'NEARBY',
  SHARED = 'SHARED',
  PAST = 'past',
  CANCELLED_MATCH = 'cancelled',
}
