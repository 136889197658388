export enum TrailerLoadedStatus {
  LOADED = 'Loaded',
  UNLOADED = 'Empty',
}

export const TrailerTypeSearchOptions = {
  Dry: 'Dry',
  Reefer: 'Reefer',
  Flatbed: 'Flat',
};

export const trailerStatusToRouteMap = {
  listed: '/driver/trailers/listed',
  unlisted: '/driver/trailers/listmytrailer',
  available: '/driver/trailers/rent/list',
};
