import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: (props) => ({
    borderStyle: props.borderStyle,
  }),
  buttonDefault: {
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.default?.main}`,
      color: theme.palette.default?.main,
      '&:hover': {
        backgroundColor: theme.palette.default?.dark,
        color: theme.palette.default?.contrastText,
      },
      '&:active': {
        backgroundColor: theme.palette.default?.dark,
        color: theme.palette.default?.contrastText,
      },
      '&$disabled': {
        backgroundColor: theme.palette.default?.disabled,
        color: theme.palette.default?.contrastText,
      },
    },
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
    },
    '&:active': {
      backgroundColor: theme.palette.error?.dark,
      color: theme.palette.common.white,
    },
    '&$disabled': {
      backgroundColor: theme.palette.error?.light,
      color: theme.palette.default?.contrastText,
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
    },
  },
  partnerCustomPrimary: {
    backgroundColor: theme.palette.partnerCustomPrimary.main,
    color: theme.palette.partnerCustomPrimary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.partnerCustomPrimary.dark,
      color: theme.palette.partnerCustomPrimary.contrastText,
    },
    '&:active': {
      backgroundColor: theme.palette.partnerCustomPrimary?.dark,
      color: theme.palette.partnerCustomPrimary.contrastText,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.partnerCustomPrimary?.disabled,
      color: theme.palette.partnerCustomPrimary?.contrastText,
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.partnerCustomPrimary?.dark,
        color: theme.palette.partnerCustomPrimary?.contrastText,
      },
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.partnerCustomPrimary.main}`,
      backgroundColor: theme.palette.partnerCustomPrimary.contrastText,
      color: theme.palette.partnerCustomPrimary.main,
    },
  },
  partnerCustomSecondary: {
    backgroundColor: theme.palette.partnerCustomSecondary.main,
    color: theme.palette.partnerCustomSecondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.partnerCustomSecondary.dark,
      color: theme.palette.partnerCustomSecondary.contrastText,
    },
    '&:active': {
      backgroundColor: theme.palette.partnerCustomSecondary?.dark,
      color: theme.palette.partnerCustomSecondary.contrastText,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.partnerCustomSecondary?.disabled,
      color: theme.palette.partnerCustomSecondary?.contrastText,
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.partnerCustomSecondary?.dark,
        color: theme.palette.partnerCustomSecondary?.contrastText,
      },
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette.partnerCustomSecondary.main}`,
      backgroundColor: theme.palette.partnerCustomSecondary.contrastText,
      color: theme.palette.partnerCustomSecondary.main,
    },
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
  chr: {
    backgroundColor: theme.palette.chr.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.chr.dark,
    },
  },
  textBlack: {
    color: theme.palette.common.black,
  },
  inheritTextTransform: {
    textTransform: 'inherit',
  },
  customBackground: (props) => ({
    background: props.background,
  }),
}));
export default useStyles;
