export const ON = 'ON' as const;
export const OFF = 'OFF' as const;

export enum IntegrationNames {
  TRUCKSTOP = 'truckstop',
  ONE_TWO_THREE_LOAD_BOARD = '123loadboard',
}

export const MIN_INTEGRATION_ID_LENGTH = 6;

export const LocalStorage123LoadboardStateKey = 'StateKey-123Loadboard';
