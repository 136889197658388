export const getInitialsFromName = (name: string) => {
  const initials = name.match(/\b\w/g) || [];
  const newInitials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return newInitials;
};

/*
This function generate other version of file name from the original default version file name or url.
Ex.
name = https://fleetops-static-assets.s3-us-west-2.amazonaws.com/integrations/shipwell.png
getOtherFileName(name, 'square') =>
https://fleetops-static-assets.s3-us-west-2.amazonaws.com/integrations/shipwell_square.png
we append version name like 'square' or 'long' to get the square or long version of icon.
'_' is appended between original file name and version.
*/
export const getOtherFileName = (name: string, version: string) => {
  const index = name.lastIndexOf('.');
  return `${name.substring(0, index)}_${version}${name.substring(index)}`;
};

export const getNameArrFromFullName = (fullName?: string) => {
  if (!fullName) {
    return ['', ''];
  }
  let trimmedFullName = fullName.replace(/\s+/g, ' ').trim();
  const separator = trimmedFullName.indexOf(',') > 0 ? ',' : ' ';
  const arr = trimmedFullName.split(separator);

  return [arr[0], arr[1] || ''];
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const hashCode = (str) => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const strToRGB = (str) => {
  const i = hashCode(str);
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return `#${'00000'.substring(0, 6 - c.length) + c}`;
};
