import config from 'config';
import { capitalize } from 'utils/StringUtils';
import { getDriverNameVariation } from 'utils/partnerSpecificStrings';

export const ERROR_BOUNDRY_PAGE_TEXT = `Sorry, something went wrong and we're working on fixing it. Please try again in a few minutes.`;

export const RAL_DELETE_ERROR = 'Sorry, there was an error deleting your request';
export const DRIVER_NOT_FOUND_ERROR = `The ${capitalize(getDriverNameVariation())} you are searching for is no longer available`;

export const NO_UPLOADED_DOCUMENTS =
  'An error had occurred. Please upload your documents and try again';

export const DEFAULT_EMAIL_MESSAGE = `Hi, I'm interested in finding out more details about this load posted on ${config.partner.name}. Please contact me to discuss it.`;

export const EMAIL_NOT_FOUND_ERROR = {
  error: 'EMAIL_NOT_FOUND',
  message: `Email doesn't exist, enter a different email`,
};

export const INVALID_PASSWORD = {
  error: 'INVALID_PASSWORD',
  message: `Sorry, the password isn't right`,
};
