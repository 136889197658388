import config from 'config';
import MovingTruck from 'assets/images/MovingTruck.gif';
import MovingTruckFleet from 'assets/images/MovingTruckFleet.gif';
import MovingTruckEld from 'assets/images/MovingTruckEld.gif';
import MovingTruckFreightMate from 'assets/images/MovingTruckFreightMate.gif';
import MovingTruckSwitchBoard from 'assets/images/MovingTruckSwitchboard.gif';
import MovingTruckEzloadz from 'assets/images/MovingTruckEzloadz.gif';
import MovingTruckFleetPulseFreight from 'assets/images/MovingTruckFleetPulseFreight.gif';

const icons = {
  fleettrack: MovingTruckFleet,
  bigroad: MovingTruck,
  eldloads: MovingTruckEld,
  freightmate: MovingTruckFreightMate,
  switchboard: MovingTruckSwitchBoard,
  ezloadz: MovingTruckEzloadz,
  fleetpulse: MovingTruckFleetPulseFreight,
};

export default icons[config.appMode];
