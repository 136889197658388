import React, { ReactNode, lazy, Suspense, useEffect, useState } from 'react';
import classnames from 'classnames';
import { configure } from 'mobx';
import { inject, observer } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { trackPageView } from 'utils/utility';
import { IDriverAppStore } from 'models/dataStructures/IDriverAppStore';
import { DriverAppStore } from 'store/DriverAppStore';
import { mixpanelInit } from 'services/FOMixpanel';
import NAVIGATION_INFO from 'constants/Navigation';
import Favicon from 'constants/Favicon';
import Permissions from 'constants/Permissions';

import ErrorBoundary from 'components/ErrorBoundary';
import SplashScreen from 'components/SplashScreen';
import InitialSplashScreen from 'components/InitialSplashScreen';
import FOSnackbarProvider from 'components/FOSnackbarProvider';
import FOSnackbar from 'components/FOSnackbar';
import { hasRequiredPermission } from 'components/PermissionCheck/helper';
import { REMOVE_NAV_BAR } from 'constants/General';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'services/GrowthBookServices';
import { AppMode } from 'constants/AppMode';
import { init } from '@feathery/react';
import { ConnectWith123LoadboardPage } from 'pages/ConnectWith123LoadboardPage';
import useStyles from './styles';
import config from '../config';

let showInitialSplash;

/**
 * Pages
 */
const DriverAppPage = lazy(() => import('pages/DriverAppPage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const SignUpPage = lazy(() => import('pages/SignUpPage'));
const PasswordResetPage = lazy(() => import('pages/PasswordResetPage'));
const EmailResetPage = lazy(() => import('pages/EmailResetPage'));
const ExistingEmailPage = lazy(() => import('pages/ExistingEmailPage'));
const OnboardingPage = lazy(() => import('pages/OnboardingPage'));
const LandingPage = lazy(() => import('pages/LandingPage'));
const UploadPage = lazy(() => import('pages/UploadPage'));
const MatchDetailPage = lazy(() => import('pages/MatchDetailPage'));
const SearchLandingPage = lazy(() => import('pages/SearchLandingPage'));
const SearchLoadsPage = lazy(() => import('pages/SearchLoadsPage'));
const RALListPage = lazy(() => import('pages/RALListPage'));
const RALDetailPage = lazy(() => import('pages/RALDetailPage'));
const DriversPage = lazy(() => import('pages/DriversPage'));
const DriverDetailsPage = lazy(() => import('pages/DriverDetailsPage'));
const SettingsPage = lazy(() => import('pages/SettingsPage'));
const DispatchLandingPage = lazy(() => import('pages/DispatchLandingPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage'));
const PrivacyPage = lazy(() => import('pages/PrivacyPage'));
const MaintenancePage = lazy(() => import('pages/MaintenancePage'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const TrailersPage = lazy(() => import('pages/TrailersPage'));
const RevenuePage = lazy(() => import('pages/RevenuePage'));
const TrailerRecommendationsPage = lazy(() => import('pages/TrailerRecommendationsPage'));
const ListMyTrailerPage = lazy(() => import('pages/ListMyTrailerPage'));

/**
 * Geotab onboarding
 const FTAccountAccess = lazy(() => import('pages/FTAccountAccess'));
 const FTOnboarding = lazy(() => import('pages/FTOnboarding'));
 */

configure({
  enforceActions: 'observed',
}); // Using MobX strictly. This will allow state changes only through actions.

interface IAppState {}

type IAppProps = RouteComponentProps & IDriverAppStore;

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

// Initialize Google Tag Manager
TagManager.initialize(config.googleTagManager);

// Initialize mixpanel
mixpanelInit();

// Initialize feathery forms
init(config.featheryFormSdkClientKey);

const App = inject('driverAppStore')(
  observer(({ history, location, driverAppStore }: IAppProps) => {
    const {
      userStore: { loggedIn, FOUser },
      partnerStore: {
        theme,
        checkSSOLogin,
        checkGeotabLogin,
        checkFleetTrackLogin,
        checkFleetPulseLogin,
      },
      configStore: {
        isGeotab,
        isFleetTrack,
        pageTitle,
        indexRoute,
        isFleetPulse,
        isTrailerSolution,
      },
    } = driverAppStore as DriverAppStore;
    const classes = useStyles();
    const [navInfo, setNavInfo] = useState(NAVIGATION_INFO);

    let locationUnlisten = () => {};

    useEffect(() => {
      /**
       * LoadingSplashInitial is only shown on 1st load/refresh &
       * REMOVE_NAV_BAR = /standalone (eg. load details on web), set true for routes that are not standalone
       * - Set to true when app mounts
       */
      if (!location.pathname.includes(REMOVE_NAV_BAR)) {
        showInitialSplash = true;
      }
      if (location.search && location.search.includes('utm')) {
        trackPageView(location.pathname, location.search);
      } else {
        trackPageView(location.pathname);
      }
      locationUnlisten = history.listen(({ location: { pathname } }) => {
        trackPageView(pathname);
      });
      // Load features when app loads
      growthbook.loadFeatures();
      appendQueryParamsFromStorageToUrl(); // Some params are being saved in localStorage during log in flow
      return () => {
        locationUnlisten();
      };
    }, []);

    useEffect(() => {
      generateNavItems();
    }, [FOUser]);

    const appendQueryParamsFromStorageToUrl = () => {
      try {
        const url = new URL(window.location.href);
        const newUrl = getNewUrlWithSearchParams(
          getNewUrlWithSearchParams(url, 'action'),
          'requestId',
        );
        window.history.replaceState(null, null, newUrl);
      } catch (error) {
        console.warn('Error fetching search params from localStorage:', error);
      }
    };

    const getNewUrlWithSearchParams = (url: URL, param: string) => {
      // Read search param saved during log in flow
      const item = localStorage?.getItem(param);
      if (item) {
        const itemParsed = JSON.parse(item);
        // Append param back to url
        const paramFromURL = url.searchParams.get(param);
        itemParsed && !paramFromURL && url.searchParams.append(param, itemParsed);
        // Remove item from localStorage
        localStorage?.removeItem(param);
      }
      return url;
    };

    const generateNavItems = () => {
      const navItems = [];
      NAVIGATION_INFO.forEach((item) => {
        if (
          item.value === 'loads' &&
          hasRequiredPermission(
            [Permissions.DRIVER, Permissions.DISPATCHABLE_DRIVER_W_SEARCH],
            driverAppStore,
          )
        ) {
          item.label = 'Search';
          navItems.push(item);
        }
        if (
          item.value === 'loads' &&
          hasRequiredPermission(
            [Permissions.DISPATCHER, Permissions.DISPATCHER_W_DRIVER],
            driverAppStore,
          )
        ) {
          navItems.push(item);
        }
        if (
          item.value === 'dispatch' &&
          !isTrailerSolution &&
          hasRequiredPermission(
            [
              Permissions.DRIVER,
              Permissions.DISPATCHABLE_DRIVER,
              Permissions.DISPATCHABLE_DRIVER_W_SEARCH,
            ],
            driverAppStore,
          )
        ) {
          item.label = 'My Loads';
          navItems.push(item);
        }
        if (
          item.value === 'dispatch' &&
          !isTrailerSolution &&
          hasRequiredPermission(
            [Permissions.DISPATCHER, Permissions.DISPATCHER_W_DRIVER],
            driverAppStore,
          )
        ) {
          navItems.push(item);
        }

        if (
          item.value === 'drivers' &&
          !isTrailerSolution &&
          hasRequiredPermission(
            [Permissions.DISPATCHER, Permissions.DISPATCHER_W_DRIVER],
            driverAppStore,
          )
        ) {
          navItems.push(item);
        }
        if (item.value === 'settings') {
          navItems.push(item);
        }

        if (item.value === 'trailers' && isTrailerSolution) {
          navItems.push(item);
        }
      });
      setNavInfo(navItems);
    };

    const getPublicRoute = (renderComponent: ReactNode) => (props: RouteComponentProps) => {
      const LoadingSplash = <SplashScreen />;
      return <Suspense fallback={LoadingSplash}>{renderComponent}</Suspense>;
    };

    const updateInitialSplashCallback = () => {
      if (showInitialSplash) {
        showInitialSplash = false;
      }
    };

    const getProtectedRoute =
      (toRender: string | ReactNode, wrapInPageContainer = true, allowForNotLoggedUser = false) =>
      (props: RouteComponentProps) => {
        const LoadingSplashInitial = <InitialSplashScreen />;
        /**
         * We pass updateInitialSplashCallback as callback to set showInitialSplash = false
         * when LoadingSplashInitialLazy is unmounted after the first page lazy loading is complete
         */
        const LoadingSplashInitialLazy = (
          <InitialSplashScreen updateInitialSplashCallback={updateInitialSplashCallback} />
        );
        const LoadingSplash = <SplashScreen />;

        if (loggedIn || allowForNotLoggedUser) {
          const hideNavbar = props.location.pathname.includes(REMOVE_NAV_BAR);
          /**
           * LoadingSplashInitialLazy is only shown on 1st load/refresh
           * - when 1st route component is lazy loaded
           */
          return (
            <Suspense fallback={showInitialSplash ? LoadingSplashInitialLazy : LoadingSplash}>
              {wrapInPageContainer ? (
                <DriverAppPage navInfo={navInfo} hideNavbar={hideNavbar}>
                  {toRender}
                </DriverAppPage>
              ) : (
                toRender
              )}
            </Suspense>
          );
        }
        /**
         * LoadingSplashInitial is only shown on 1st load/refresh
         * - while auth and /me calls go out
         */
        if (isFleetTrack) {
          checkFleetTrackLogin();
          if (showInitialSplash) {
            return LoadingSplashInitial;
          }
          return LoadingSplash;
        }
        if (isGeotab) {
          checkGeotabLogin();
          return LoadingSplash;
        }
        if (isFleetPulse) {
          checkFleetPulseLogin();
          return LoadingSplash;
        }
        checkSSOLogin(history);
        if (showInitialSplash) {
          return LoadingSplashInitial;
        }
        return LoadingSplash;
      };

    const searchAllowed = hasRequiredPermission(
      [
        Permissions.DRIVER,
        Permissions.DISPATCHABLE_DRIVER_W_SEARCH,
        Permissions.DISPATCHER,
        Permissions.DISPATCHER_W_DRIVER,
      ],
      driverAppStore,
    );

    const allowedDriversPage = hasRequiredPermission(
      [Permissions.DISPATCHER, Permissions.DISPATCHER_W_DRIVER],
      driverAppStore,
    );

    const allowIfNotLoggedIn = isFleetTrack;

    return (
      <ErrorBoundary key={location.pathname}>
        <Helmet title={pageTitle} link={[{ rel: 'icon', type: 'image/png', href: Favicon }]}>
          <script
            type='text/javascript'
            src={`https://widget.freshworks.com/widgets/${config.freshworksId}.js`}
            async
            defer
          />
        </Helmet>
        <GrowthBookProvider growthbook={growthbook}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />

            <Box
              className={classnames(classes.appContainer, {
                [classes.geotabAppContainer]: isGeotab,
              })}
            >
              <FOSnackbarProvider isGeotab={isGeotab}>
                <LocalizationProvider dateAdapter={MomentUtils} dateLibInstance={moment}>
                  <FOSnackbar />
                  <Switch>
                    <Route
                      exact
                      path='/'
                      render={() => <Redirect to={indexRoute || navInfo[0].url} />}
                    />
                    <Route
                      exact
                      path='/driver/login'
                      render={getProtectedRoute(<LoginPage />, false, allowIfNotLoggedIn)}
                    />
                    <Route
                      exact
                      path='/driver/sign-up'
                      render={getProtectedRoute(<SignUpPage />, false, allowIfNotLoggedIn)}
                    />
                    <Route
                      exact
                      path='/driver/forgot-password'
                      render={getProtectedRoute(<PasswordResetPage />, false, allowIfNotLoggedIn)}
                    />
                    <Route
                      exact
                      path='/driver/forgot-email'
                      render={getProtectedRoute(<EmailResetPage />, false, allowIfNotLoggedIn)}
                    />
                    <Route
                      exact
                      path='/driver/existing-email'
                      render={getProtectedRoute(<ExistingEmailPage />, false, allowIfNotLoggedIn)}
                    />
                    <Route
                      exact
                      path='/driver/onboarding'
                      render={getProtectedRoute(<OnboardingPage />, false)}
                    />
                    <Route
                      exact
                      path='/driver/landing'
                      render={getProtectedRoute(<LandingPage />, false, allowIfNotLoggedIn)}
                    />
                    {searchAllowed && (
                      <Route
                        path='/driver/search'
                        exact
                        render={getProtectedRoute(<SearchLandingPage />)}
                      />
                    )}
                    {searchAllowed && (
                      <Route
                        path='/driver/search/results'
                        exact
                        render={getProtectedRoute(<SearchLoadsPage />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/trailers'
                        exact
                        render={getProtectedRoute(<TrailersPage />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/trailers/listmytrailer'
                        exact
                        render={getProtectedRoute(<ListMyTrailerPage />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/trailers/listed'
                        exact
                        render={getProtectedRoute(<TrailersPage trailerStatus='listed' />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/revenue'
                        exact
                        render={getProtectedRoute(<RevenuePage />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/trailer/recommendations/:vin'
                        exact
                        render={getProtectedRoute(<TrailerRecommendationsPage />)}
                      />
                    )}
                    {isTrailerSolution && (
                      <Route
                        path='/driver/trailers/rent/list'
                        exact
                        render={getProtectedRoute(<TrailersPage trailerStatus='available' />)}
                      />
                    )}

                    {
                      // RAL has the same permissions as search
                      searchAllowed && (
                        <Route
                          path='/driver/ral'
                          exact
                          render={getProtectedRoute(<RALListPage />)}
                        />
                      )
                    }
                    {
                      // RAL has the same permissions as search
                      searchAllowed && (
                        <Route
                          path='/driver/ral/:ralId/details'
                          render={getProtectedRoute(<RALDetailPage />)}
                        />
                      )
                    }
                    <Redirect exact from='/driver/view' to='/driver/view/loads' />
                    <Route
                      path='/driver/view/:type/lane/:laneIndex'
                      render={getProtectedRoute(<div />)}
                    />
                    <Route path='/driver/view/:type' render={getProtectedRoute(<div />)} />
                    {allowedDriversPage && (
                      <Route
                        path='/driver/drivers'
                        exact
                        render={getProtectedRoute(<DriversPage />)}
                      />
                    )}
                    {allowedDriversPage && (
                      <Route
                        path='/driver/drivers/:personId'
                        exact
                        render={getProtectedRoute(<DriverDetailsPage />)}
                      />
                    )}
                    <Route
                      path='/driver/dispatch/:tabName?'
                      exact
                      render={getProtectedRoute(<DispatchLandingPage />)}
                    />
                    <Route
                      exact
                      path='/driver/settings/:sectionId?'
                      render={getProtectedRoute(<SettingsPage />)}
                    />

                    <Route
                      exact
                      path='/driver/settings/:sectionId?/upload'
                      render={getProtectedRoute(<UploadPage hasProfile />, false)}
                    />
                    <Route
                      path='/driver/notifications'
                      render={getProtectedRoute(<NotificationsPage />)}
                    />
                    <Route
                      exact
                      path='/driver/match/:matchId/detail/:deadhead?'
                      render={getProtectedRoute(<MatchDetailPage />)}
                    />
                    <Route
                      exact
                      path='/driver/match/:matchId/detail/:deadhead?/upload'
                      render={getProtectedRoute(<UploadPage />, false)}
                    />
                    <Route
                      path='/driver/load/:loadId/detail/:source?/:deadhead?'
                      render={getProtectedRoute(<MatchDetailPage />)}
                    />
                    {/* The following route is applied when redirecting from Search to the load details page. */}
                    <Route
                      exact
                      path={`/driver/load/:loadId/detail/:source?/:deadhead?/${REMOVE_NAV_BAR}`}
                      render={getProtectedRoute(<UploadPage />, false)}
                    />
                    <Route
                      exact
                      path='/driver/load/:loadId/detail/:deadhead?/upload'
                      render={getProtectedRoute(<UploadPage />, false)}
                    />
                    <Route
                      exact
                      path='/driver/connect/123loadboard'
                      render={getProtectedRoute(<ConnectWith123LoadboardPage />, false)}
                    />
                    <Route path='/privacy' render={getPublicRoute(<PrivacyPage />)} />
                    <Route path='/maintenance' render={getPublicRoute(<MaintenancePage />)} />
                    <Route path='/error' render={getPublicRoute(<ErrorPage />)} />
                    {/**
                   * Geotab onboarding
                  <Route
                    exact
                    path='/ftAccountAccess/:type'
                    render={getProtectedRoute(<FTAccountAccess />)}
                  />
                  <Route
                    exact
                    path='/ftOnboarding/:step'
                    render={getProtectedRoute(<FTOnboarding />)}
                    />
                  */}
                    {
                      // Default route
                    }
                    <Route render={getProtectedRoute(<Redirect to='/' />)} />

                    {/**
                   * 
                     <Route
                      path='/driver/chatbot'
                      render={getProtectedRoute(
                        <DriverAppPage>
                          <FOChatbot />
                          </DriverAppPage>)}
                     />
                     */}
                  </Switch>
                </LocalizationProvider>
              </FOSnackbarProvider>
            </Box>
          </MuiThemeProvider>
        </GrowthBookProvider>
      </ErrorBoundary>
    );
  }),
);

export default withRouter(App);
