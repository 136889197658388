const config = {
  ssoUrl: 'https://oneview2.pedigreetechnologies.com/lineage/api/redirectWithToken?url=',
  clarityId: '8orlgo6zi1',
  datadog: {
    applicationId: 'fb913270-70a6-4d1f-8fcb-3936471283ce',
    clientToken: 'pub0a9db41bdb906c4804c67533b9781a8a',
    service: 'freight-mate-production',
  },
};

export default config;
