import React, { useState } from 'react';
import classnames from 'classnames';
import Button, { ButtonProps } from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import FeatureModalTrigger from 'components/FeatureModalTrigger';

interface IButtonOwnProps {
  children: any;
  loading?: boolean;
  danger?: boolean;
  partnerCustomPrimary?: boolean;
  partnerCustomSecondary?: boolean;
  chr?: boolean;
  featureModalTrigger?: string;
  borderStyle?: string;
  textColor?: 'secondary' | 'black';
  justifyContent?: 'inherit' | 'space-between' | 'center';
  inheritTextTransform?: boolean;
  background?: string;
}

type IButtonProps = IButtonOwnProps & ButtonProps;
export default ({
  color = 'default',
  children,
  loading = false,
  danger = false,
  chr = false,
  borderStyle = 'solid',
  featureModalTrigger,
  className,
  partnerCustomPrimary = false, // this props is used to create a partner specific button color. Ex: primary green for bigroad and secondary light blue from fleettrack.
  partnerCustomSecondary = false, // this props is used to create a partner specific button color. Ex: secondary purple for bigroad and primary blue from fleettrack and orange for the eldloads and freightmate.
  staticContext, // added to handle console errors for staticContext
  textColor,
  onClick,
  inheritTextTransform = false,
  role = 'button',
  justifyContent,
  background,
  ...rest
}: IButtonProps) => {
  const classes = useStyles({ borderStyle, justifyContent, background });
  const [clicked, setClicked] = useState(false);
  const handleClick = (e) => {
    setClicked(true);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      color={color}
      className={classnames(classes.root, className, {
        [classes.danger]: danger,
        [classes.textSecondary]: textColor === 'secondary', // used with props color='primary' variant='text'
        [classes.textBlack]: textColor === 'black', // used with props color='primary' variant='text'
        [classes.partnerCustomPrimary]: partnerCustomPrimary,
        [classes.partnerCustomSecondary]: partnerCustomSecondary,
        [classes.inheritTextTransform]: inheritTextTransform,
        [classes.chr]: chr,
        [classes.customBackground]: background,
      })}
      onClick={handleClick}
      role={role}
      {...rest}
    >
      {children}
      {loading && <CircularProgress size={24} color='inherit' />}
      {featureModalTrigger && (
        <FeatureModalTrigger clicked={clicked} triggerOnClick trigger={featureModalTrigger} />
      )}
    </Button>
  );
};
