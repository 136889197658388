import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from 'components/Button';
import useStyles from './styles';

interface IErrorImageOwnProps {
  image: ReactNode;
  title: ReactNode;
  buttonText?: string;
  caption?: ReactNode;
  titleVariant?: string;
  contentMargin?: number;
  handleCustomCallback?(): void;
}

type IErrorImageProps = IErrorImageOwnProps;

const ErrorImage = observer(
  ({ image, title, caption, titleVariant, contentMargin, handleCustomCallback, buttonText = 'Try Again' }: IErrorImageProps) => {
    const classes = useStyles();

    const handleRefresh = () => {
      window.location.reload();
    };
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justify='center'
        className={classes.root}
      >
        <Grid item>{image}</Grid>
        <Box mt={contentMargin !== undefined ? contentMargin : 4}>
          <Grid item>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant={titleVariant || 'h4'} align='center'>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                  className={classes.caption}
                >
                  {caption}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  partnerCustomPrimary
                  variant='contained'
                  fullWidth
                  size='large'
                  onClick={handleCustomCallback || handleRefresh}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  },
);

export default ErrorImage;
