import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './styles';
import GridContainer from 'components/GridContainer';

interface ILandingPageContainerOwnProps {
  children: ReactNode;
  isLoadingScreen: boolean;
}

type ILandingPageContainerProps = ILandingPageContainerOwnProps;

const LandingPageContainer = ({
  children,
  isLoadingScreen = false,
}: ILandingPageContainerProps) => {
  const classes = useStyles();
  return (
    <>
      {isLoadingScreen ? (
        <Box height='100%' width='100%'>
          <GridContainer fullHeight>
            <Grid item xs={12}>
              {children}
            </Grid>
          </GridContainer>
        </Box>
      ) : (
        <Box height='100%'>
          <GridContainer fullHeight>
            <Hidden xsDown>
              <Grid item md={6}>
                <Box className={classes.background} width='100%' height='100%' />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              {children}
            </Grid>
          </GridContainer>
        </Box>
      )}
    </>
  );
};

export default LandingPageContainer;
