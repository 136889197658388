const primary = {
  main: '#31b958',
  dark: '#1D813A',
  disabled: '#98DCAB',
  light: '#D7F5DF',
  contrastText: '#FFFFFF',
};
const secondary = {
  // purple
  light: 'rgba(93, 104, 204, 0.1)',
  medium: '#EDEDFA',
  main: '#5D68CC',
  dark: '#3C448B',
  disabled: '#AEB3E5',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: primary,
  partnerCustomSecondary: secondary,
  background: {
    walkthroughPurple: '#4B56BA',
  },
};
export default palette;
