export const fontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const typographyOverrides = {
  MuiTypography: {
    h1: {
      fontSize: 24,
      fontWeight: fontWeight.medium,
      lineHeight: '29px',
      letterSpacing: 0,
    },
    h2: {
      fontSize: 20,
      fontWeight: fontWeight.medium,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    h3: {
      fontSize: 16,
      fontWeight: fontWeight.medium,
      lineHeight: '19px',
      letterSpacing: 0,
    },
    h4: {
      fontSize: 15,
      fontWeight: fontWeight.medium,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: fontWeight.medium,
      letterSpacing: 0,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: fontWeight.medium,
      lineHeight: '14.2px',
      letterSpacing: 0,
    },
    body1: {
      fontSize: 14,
      fontWeight: fontWeight.medium,
      lineHeight: '17px', // Check if needed
      letterSpacing: 0,
    },
    body2: {
      fontSize: 10,
      fontWeight: fontWeight.medium,
      lineHeight: '12px',
      letterSpacing: 0.5,
    },
    caption: {
      fontSize: 11,
      fontWeight: fontWeight.medium,
      lineHeight: '13.21px',
      letterSpacing: 0.5,
    },
    overline: {
      fontSize: 8,
      fontWeight: fontWeight.medium,
      lineHeight: '9.38px',
      letterSpacing: -0.1,
    },
  },
};
export default typographyOverrides;
