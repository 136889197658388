export default {
  CONVOY: 'Convoy',
  MEYERTRANSPORT: 'Meyer Transport LLC',
  FOXLOGISTICS: 'Fox Logistics',
  //Parade
  AZ_TRUCKING: 'A&Z Trucking',
  BRIDGE_LOGISTICS: 'Bridge Logistics Inc',
  LRGISTICS_LLC: 'LRGISTICS LLC',
  CORNERSTONE_SYSTEMS: 'Cornerstone Systems Inc',
  HOGAN_LOGISTICS: 'Hogan Logistics',
  RWI_LOGISTICS: 'RWI Logistics',
  BEEMAC_LOGISTICS: 'Beemac Logistics / Hybrid Global Logistics Services',
  TRIDENT_TRANSPORT: 'Trident Transport',
  TRANSPORTATION_ONE: 'Transportation One',
  LIVE_LOGISTICS: 'Live Logistics Corp',
  GILTNER_LOGISTICS: 'Giltner Logistics, Inc.',
  GREENBUSH_LOGISTICS: 'Greenbush Logistics',
  PB_LOGISTICS: 'PB Logistics Services LLC',
  BLUE_LINE_LOGISTICS: 'Blue Line Logistics, Inc.',
  LDP_LOGISTICS: 'LDP Logistics, Inc.',
  GENPRO_INC: 'Genpro Inc',
  RGL_SPECIALTY_SERVICES: 'RGL Specialty Services, LLC',
  SONWIL_LOGISTICS: 'Sonwil Logistics',
  GEODIS: 'Geodis',
  RED_CLASSIC: 'Red Classic Transportation Services, LLC',
  ELBERTA_LOGISTICS: 'Elberta Logistics International LLC',
  MARINER_LOGISTICS: 'Mariner Logistics',
  STORD_FREIGHT: 'STORD Freight LLC',
  STATON_LOGISTICS: 'Staton Logistics Partners, LLC',
  CUSTOM_PRO_LOGISTICS: 'Custom Pro Logistics',
  ODW_LOGISTICS: 'ODW Logistics',
};
