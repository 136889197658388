import palette from './palette';
import themePalette from '../palette';
import themeCommon from 'theme/themeCommon';

const renderPseudoClassStyles = (backgroundColor, color = 'inherit') => ({
  padding: '8px 16px',
  '&:hover': {
    backgroundColor,
    color,
  },
  '&:active': {
    backgroundColor,
    color,
  },
  '@media (hover: none)': {
    '&:hover': {
      backgroundColor: `${backgroundColor} !important`,
      color,
    },
  },
});

const mediumBtnFont = {
  fontSize: 14,
  lineHeight: '14.2px',
  letterSpacing: 0.5,
};

const largeBtnFont = {
  fontSize: 16,
  lineHeight: '19px',
  letterSpacing: 0,
};

const buttonOverrides = {
  MuiButton: {
    root: {
      textTransform: 'capitalize',
      borderRadius: themeCommon.custom.borderRadius.radius6,
      letterSpacing: 0,
      padding: '8px 16px',
      boxShadow: 'none',
    },
    contained: {
      ...mediumBtnFont,
      backgroundColor: themePalette.background.paper,
      height: 35,
      padding: '8px 16px',
      letterSpacing: 0,
      boxShadow: 'none',
    },
    outlined: {
      ...mediumBtnFont,
      height: 35,
      padding: '8px 16px',
      letterSpacing: 0,
      boxShadow: 'none',
    },
    outlinedSizeLarge: {
      ...largeBtnFont,
      height: 50,
      padding: '8px 16px',
    },
    containedSizeLarge: {
      ...largeBtnFont,
      height: 50,
      padding: '8px 16px',
    },
    text: {
      ...mediumBtnFont,
      height: 35,
      padding: '8px 16px',
      letterSpacing: 0,
      boxShadow: 'none',
    },
    textSizeLarge: {
      ...largeBtnFont,
      height: 50,
      padding: '8px 16px',
    },
    containedPrimary: {
      // green filled white text
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      '&$disabled': {
        backgroundColor: palette.primary.disabled,
        color: palette.primary.contrastText,
      },
      ...renderPseudoClassStyles(palette.primary.dark, palette.primary.contrastText),
    },
    containedSecondary: {
      // purple filled white text
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      '&$disabled': {
        backgroundColor: palette.secondary.disabled,
        color: palette.secondary.contrastText,
      },
      ...renderPseudoClassStyles(palette.secondary.dark, palette.secondary.contrastText),
    },
    textPrimary: {
      /**
       * UI is white filled with grey text - default colour
       * Used as color='primary' variant='text'
       *
       *  pass prop textColor: 'secondary' || 'black' to Button component
       *  to change font colour and overwrite the default grey
       */
      backgroundColor: palette.primary.contrastText,
      border: `1px solid ${themeCommon.palette.default.main}`,
      color: themePalette.text.secondary,
      ...renderPseudoClassStyles(themePalette.background.greyLight, themePalette.text.secondary),
    },
  },
};

export default buttonOverrides;
