export const ApiFail = (error) => {
  if (error && error.response && error.response.status && error.response.status === 500) {
    return;
  }
  // We comment it because we might need to migrate Raven later.
  // Raven.captureException(error, {
  //   extra: error.response || error.message || error.request || 'API Call Fail',
  // });
};

export const ErrorException = (error, errorInfo) => {
  // We comment it because we might need to migrate Raven later.
  // Raven.captureException(error, { extra: errorInfo });
};
