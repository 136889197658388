import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';

const useStyles = makeStyles((theme: ITheme) => ({
  appContainer: {
    height: '100%',
  },
  geotabAppContainer: {
    overflow: 'hidden',
    '& .driver-page-content': {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 140px)',
    },
  },
}));

export default useStyles;
