const bottomNavOverrides = {
  MuiBottomNavigationAction: {
    // root: {
    //   maxWidth: 'inherit',
    //   paddingLeft: 0,
    //   paddingRight: 0,
    //   paddingTop: 0,
    //   paddingBottom: 0,
    // },
  },
  MuiBottomNavigation: {
    // root: {
    //   boxShadow: 'inset 0px 3px 3px -2px rgba(0,0,0,0.2)',
    // },
  },
};
export default bottomNavOverrides;
