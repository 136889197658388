import { sleep } from '../../utils/utility';
import { ApiFail } from '../../utils/ErrorService';
import axios from 'axios';

export const fetchRetry = async (
  url: RequestInfo,
  options?: RequestInit,
  n: number = 5,
): Promise<Response> => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw response;
    }
    return response;
  } catch (error) {
    if (n === 1) {
      ApiFail(error);
      throw error;
    } else if (error.status && error.status <= 500) {
      throw error;
    }
    await sleep(1000);
    return await fetchRetry(url, options, n - 1);
  }
};

export const axiosRetryInterceptor = () => {
  axios.interceptors.response.use(undefined, (err) => {
    let config = err.config;
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) return Promise.reject(err);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    let backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(() => {
      return axios(config);
    });
  });
};
