import config from 'config';
window.fwSettings = { widget_id: config.freshworksId };
!(function () {
  if ('function' != typeof window.FreshworksWidget) {
    var n = function () {
      n.q.push(arguments);
    };
    (n.q = []), (window.FreshworksWidget = n);
  }
})();
