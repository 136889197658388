import firebase from 'firebase/app';
import 'firebase/auth';
import config from '../../../../config';
import { fetchRetry } from '../index';
import { getTokenHeader } from '../GetTokenHeader';
import { IGetUser } from '../../../models/apiResponse/getUser';
import { FOUser } from '../../../models/dataStructures/FOUser';

export const deleteUserDocuments = async (documentType: string): Promise<FOUser> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }

  const tokenHeader = await getTokenHeader();
  const url = `${config.apiUrlV2}/users/me/documents/${documentType}`;

  return fetchRetry(url, {
    method: 'DELETE',
    headers: { ...tokenHeader },
  }).then(async (response) => {
    const responseJson: IGetUser = await response.json();
    return responseJson.data;
  });
};
