import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import { getTokenHeader } from '../GetTokenHeader';
import config from 'config';

export const GetTrailerLoadRecommendations = async ({ vins }) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  // TODO: Uncomment and clean up code later
  // let url = `${config.apiUrlV2}/loads/fleetpulse`;
  let url = `https://internal-api.fleetops.ai:5055/v1/loads/fleetpulse`;

  return axios
    .post(
      url,
      { vins },
      {
        // headers: tokenHeader,
        headers: {
          'x-api-key': 'demo@fleet-pulse.com',
        },
      },
    )
    .then((response) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
