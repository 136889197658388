import Permissions from 'constants/Permissions';
import { DriverAppStore } from 'store/DriverAppStore';

export const hasRequiredPermission = (permissions, driverAppStore) => {
  const {
    userStore: { FOUser, defaultDriver, dispatchableDriver, dispatcher },
  } = driverAppStore as DriverAppStore;
  const userPermissions = FOUser?.userPermissions || {};

  if (permissions.indexOf(Permissions.DRIVER) !== -1 && defaultDriver) {
    return true;
  }

  if (
    permissions.indexOf(Permissions.DISPATCHABLE_DRIVER) !== -1 &&
    dispatchableDriver &&
    !userPermissions?.search
  ) {
    return true;
  }

  if (
    permissions.indexOf(Permissions.DISPATCHABLE_DRIVER_W_SEARCH) !== -1 &&
    dispatchableDriver &&
    userPermissions?.search === 5
  ) {
    return true;
  }

  if (
    permissions.indexOf(Permissions.DISPATCHER) !== -1 &&
    dispatcher &&
    !userPermissions?.withDriver
  ) {
    return true;
  }

  if (
    permissions.indexOf(Permissions.DISPATCHER_W_DRIVER) !== -1 &&
    dispatcher &&
    userPermissions?.withDriver === 5
  ) {
    return true;
  }

  return false;
};
