const primary = {
  light: 'rgba(16, 137, 255, 0.1)',
  main: '#5A9588',
  dark: '#1A1A1A',
  disabled: '#C8C7C7',
  contrastText: '#FFFFFF',
};
const secondary = {
  main: '#5A9588',
  dark: '#427166',
  medium: '#FFFFFF', //TODO check with design team because it's used in phone number button.
  disabled: '#ACC9C3',
  light: 'rgba(35, 55, 77, 0.1)',
  contrastText: '#FFFFFF',
};
const orange = {
  main: '#DE784D',
  dark: '#BA643F',
  disabled: '#C8C7C7',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: secondary,
  partnerCustomSecondary: orange,
  background: {
    walkthroughPurple: '#427166', // Walkthrough bubble bg, etc
    purpleLight: '#EFF4F3', // Light background for selected grid checkbox/radio block input etc
    lightBlue: {
      light: '#EFF4F3', // Table hover states
    },
  },
  navy: '#427166', // Search LP form background, etc
  loadStatus: {
    booked: '#5A9588',
    shared: '#DE784D',
    completed: '#2BBF8A',
    cancelled: '#B93131',
  },
};
export default palette;
