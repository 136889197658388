export default {
  TRUCKERPATH: 'truckerpath',
  COYOTE: 'coyote',
  LOADSMART: 'loadsmart',
  FLEETOPS: 'FleetOps',
  SCHNEIDER: 'schneider',
  POSTEVERYWHERE: 'posteverywhere',
  SHIPWELL: 'shipwell',
  CH_ROBINSON: 'chr',
  CONVOY: 'convoy',
  UBER: 'uber',
  EDGELOGISTICS: 'edgeLogistics',
  MCLEOD: 'mcleod',
  HENIFF: 'heniff',
  AMERITON: 'ameriton',
  ZENGISTICS: 'zengistics',
  EMERGE: 'emerge',
  RPM: 'rpm',
  VERIHALOGISTICS: 'verihaLogistics',
  ENGLANDLOGISTICS: 'englandLogistics',
  GENPRO: 'genpro',
  AFC: 'afc',
  TUMALO_CREEK: 'tumaloCreek',
  LBN: 'loadboardnetwork',
  TCT: 'tct',
  GP_TRANSCO: 'gptransco',
  CARGO_EXPRESS: 'cefc',
  PARADE: 'parade',
  TRUCKSTOP: 'truckstop',
  ONE_TWO_THREE_LOADBOARD: '123loadboard',
};
