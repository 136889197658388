import config from 'config';
import GeneralErrorBigroad from 'assets/images/icons/generalErrorBigroad.svg';
import GeneralErrorFleettrack from 'assets/images/icons/generalErrorFleettrack.svg';
import GeneralErrorEldloads from 'assets/images/icons/generalErrorEldloads.svg';
import GeneralErrorFreightMate from 'assets/images/icons/generalErrorFreightmate.svg';
import GeneralErrorSwitchBoard from 'assets/images/icons/generalErrorSwitchboard.svg';
import GeneralErrorEzloadz from 'assets/images/icons/generalErrorEzloadz.svg';

const icons = {
  fleettrack: GeneralErrorFleettrack,
  bigroad: GeneralErrorBigroad,
  eldloads: GeneralErrorEldloads,
  freightmate: GeneralErrorFreightMate,
  switchboard: GeneralErrorSwitchBoard,
  ezloadz: GeneralErrorEzloadz,
  fleetpulse: GeneralErrorBigroad, // UPDATE WHEN AVAILABLE
};

export default icons[config.appMode];
