const config = {
  datadog: {
    applicationId: '', // UPDATE WHEN AVAILABLE
    clientToken: '', // UPDATE WHEN AVAILABLE
    service: '', // UPDATE WHEN AVAILABLE
  },
  appUrl: 'https://my.fleet-pulse.com',
  growthBookSdkClientKey: 'sdk-MrFopUodvY5ivUw',
};

export default config;
