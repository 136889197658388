import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { IDriverAppStore } from 'models/dataStructures/IDriverAppStore';
import { DriverAppStore } from 'store/DriverAppStore';

interface IFeatureModalTriggerOwnProps {
  trigger: string;
  clicked?: boolean;
  triggerOnClick?: boolean;
  children?: any;
}

type IFeatureModalTriggerProps = IFeatureModalTriggerOwnProps & IDriverAppStore;

const FeatureModalTrigger = inject('driverAppStore')(
  observer(
    ({
      trigger,
      clicked = false,
      triggerOnClick = false,
      driverAppStore,
    }: IFeatureModalTriggerProps) => {
      const {
        userStore,
        userStore: { featureModals, visitedModals },
      } = driverAppStore as DriverAppStore;
      const getActiveModal = () => {
        const activeModal =
          featureModals.find(
            (item) => item.trigger === trigger && !visitedModals.includes(item.name),
          ) || undefined;
        return activeModal;
      };

      useEffect(() => {
        if (!triggerOnClick) {
          userStore.setActiveFeatureModal(getActiveModal());
        }
      }, [featureModals, visitedModals]);

      useEffect(() => {
        if (triggerOnClick && clicked) {
          userStore.setActiveFeatureModal(getActiveModal());
        }
      }, [clicked, visitedModals]);
      return null;
    },
  ),
);

export default FeatureModalTrigger;
