import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Grid, { GridProps } from '@material-ui/core/Grid';
import useStyles from './styles';

interface IGridContainerOwnProps {
  fullHeight?: boolean;
}

type IGridContainerProps = IGridContainerOwnProps & GridProps;

const GridContainer = observer(
  ({ fullHeight, className, children, ...other }: IGridContainerProps) => {
    const classes = useStyles();

    return (
      <Grid
        container
        className={classnames(className, { [classes.fullHeight]: fullHeight })}
        {...other}
      >
        {children}
      </Grid>
    );
  },
);

export default GridContainer;
