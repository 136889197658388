export enum InteractionEventTypes {
  LIKE = 'LK',
  NEGOTIATING = 'NG',
  NEGOTIATE_REJECT = 'NR',
  NEGOTIATE_ACCEPT = 'NA',
  ACCEPTED = 'AC',
  REJECT = 'RJ',
  DRIVER_ACCEPT = 'DAC',
  DISPATCHED = 'DSP',
  DISPATCH_ASSIGNED = 'DSPA',
  DISPATCH_UNASSIGNED = 'DSPU',
  RECEIVED_DISPATCH = 'RDSP',
  REQUESTED_CALLBACK = 'SREQ',
  RECEIVED_REQUESTED_CALLBACK = 'RSREQ',
  CALLBACK_CANCELLED = 'SCAN',
  ACCEPT_PENDING = 'ACP',
  LOAD_LOST = 'LST',
  DOC_SAVE = 'DOCSAVE',
  RECEIVED_SHARED = 'RSHARED',
}
