import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import config from 'config';
import { getTokenHeader } from '../GetTokenHeader';
import Trailer from 'models/dataStructures/Trailer';

const { CancelToken } = axios;
let source = CancelToken.source();

export const GetTrailers = async (params = undefined) => {
  source && source.cancel('Operation canceled due to new request.');
  source = axios.CancelToken.source();

  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();

  let url = `${config.apiUrlV2}/trailers/`;

  try {
    const response = await axios.get(url, {
      headers: tokenHeader,
      params: params
    });

    if (response.data && response.data.data) {
      return {
        data: response.data.data.reduce((acc, trailer) => {
          try {
            acc.push(new Trailer(trailer));
          } catch (error) {
            console.log(`Error parsing trailer list: ${error}`);
          }
          return acc;
        }, []),
      };
    }
    return {
      data: [],
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      return {
        data: [],
      };
    }
    ApiFail(error);
    throw error;
  }
};
