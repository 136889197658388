import { action, computed, IObservableArray, observable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { saveAs } from 'file-saver';
import { asyncComputed } from 'computed-async-mobx';
import { validateAndAddCoordinatesToPreferredLanes } from 'utils/utility';
import StoreBase from './StoreBase';
import LoadSources from 'constants/LoadSources';
import { GetDriverMetrics } from 'services/APIServices/GetDriverMetrics';
import { GetDispatcherTrucks } from 'services/APIServices/GetDispatchableDrivers';
import { GetRelations } from 'services/APIServices/GetRelations';
import { patchDispatcherTrucks } from 'services/APIServices/PatchDispatchableDriver';
import ApiLayer from 'services/APIServices/ApiLayer';
import { DriverAppStore } from './DriverAppStore';
import { DispatchableType } from 'models/dataStructures/DispatchableType';
import { DriverTruck } from 'models/dataStructures/DriverTruck';
import { PostCurrentCoordinates } from 'services/APIServices/PostCurrentCoordinates';
import { ICoordinate } from 'models/interfaces/shared/ICoordinate';
import { putUserDocuments } from 'services/APIServices/PutUserDocuments';
import { getUserDocuments } from 'services/APIServices/GetDocument';
import { postSendDocuments } from 'services/APIServices/PostSendDocuments';
import { IFleet } from 'models/interfaces/shared/IFleet';
import { getFleet } from 'services/APIServices/GetFleet';
import { ErrorException } from 'utils/ErrorService';
import { TruckRoles } from 'models/dataStructures/TruckRoles';
import { mixpanelUpdateUser } from 'services/FOMixpanel';
import { IOperatingLane } from 'models/interfaces/shared/IOperatingLanes';
import postUserOperatingLanes from 'services/APIServices/PostUserOperatingLanes';
import patchUserCommunicationsPreference from 'services/APIServices/PatchUserCommunicationsPreference';
import PostGeotabAcknowledgement from 'services/APIServices/Users/PostGeotabAcknowledgement';
import { FOUser } from 'models/dataStructures/FOUser';
import { IFOUser } from 'models/interfaces/shared/IFOUser';
import PostGeotabCreateDriverTrucks from 'services/APIServices/Geotab/PostGeotabCreateDriverTrucks';
import { MIXPANEL_KEYS } from 'constants/Mixpanel';
import { hasRequiredPermission } from 'components/PermissionCheck/helper';
import Permissions from 'constants/Permissions';
import { getNameArrFromFullName } from 'utils/StringUtils';
import { deleteUserDocuments } from 'services/APIServices/DeleteUserDocuments';
import { EMAIL_NOT_FOUND_ERROR } from 'constants/Messages';
import { ACCEPTED_UPLOAD_TYPES } from 'constants/General';
import { UserTypeFormatted } from 'constants/UserTypes';
import { GetFeatureModal } from 'services/APIServices/GetFeatureModal';
import { IFeatureModal } from 'models/interfaces/shared/IFeatureModal';
import { PostUserViewModal } from 'services/APIServices/PostUserViewModal';
import { getDriverNameVariation } from 'utils/partnerSpecificStrings';

// eslint-disable-next-line import/prefer-default-export
export class UserStore extends StoreBase {
  rootStore: DriverAppStore;
  @observable FOUser?: FOUser;
  @observable loggedIn = false;
  @observable isAndroidWebview = false;
  @observable isMobileWebview = false;
  @observable queryEmail = '';
  @observable queryAuthToken = '';
  @observable dispatcherTrucks: IObservableArray<DriverTruck> = observable([]);
  @observable currentCoordinates: ICoordinate = { lat: 0, lng: 0 };
  @observable documentsBlobs?: { [key: string]: Blob };
  @observable fleet?: IFleet;
  @observable hideTracking = false;
  @observable checkingAuth = false;
  @observable showedGeotabAcknowledgement = false;
  @observable driverMetrics = {};
  @observable featureModals: IFeatureModal[] = [];
  @observable visitedModals: string[] = [];
  @observable activeFeatureModal?: IFeatureModal = undefined;
  @observable downloadCurrentCoordinateFailure = null;
  @observable onboardedFleetUsers = [];
  @observable usersNotOnboarded = [];
  @observable relatedDispatchableDrivers: IObservableArray<FOUser> = observable([]);
  @observable relatedDispatchers: IObservableArray<FOUser> = observable([]);
  @observable activeWalkthroughStep: number | null = null;
  @observable loadingDispatcherDrivers: boolean = false;

  constructor(rootStore: DriverAppStore) {
    super();
    this.rootStore = rootStore;
    this.checkWebview();
  }

  @computed get dispatchableDriver() {
    return Boolean(this.FOUser?.userType === 'dispatchableDriver');
  }

  @computed get dispatchableDriverWSearch() {
    return hasRequiredPermission([Permissions.DISPATCHABLE_DRIVER_W_SEARCH], this.rootStore);
  }

  @computed get dispatcher() {
    return Boolean(this.FOUser?.userType === 'dispatcher');
  }

  @computed get dispatcherWithDriver() {
    return hasRequiredPermission([Permissions.DISPATCHER_W_DRIVER], this.rootStore);
  }

  @computed get defaultDriver() {
    return Boolean(this.FOUser?.userType === 'driver');
  }

  @computed get userTypeString() {
    if (this.defaultDriver) {
      return UserTypeFormatted.OWNER_OPERATOR;
    }
    if (this.dispatchableDriverWSearch) {
      return UserTypeFormatted.DISPATCHABLE_DRIVER_WITH_SEARCH;
    }
    if (this.dispatchableDriver) {
      return UserTypeFormatted.DISPATCHABLE_DRIVER;
    }
    if (this.dispatcherWithDriver) {
      return UserTypeFormatted.DISPATCHER_DRIVER;
    }
    if (this.dispatcher) {
      return UserTypeFormatted.DISPATCHER;
    }
    return null;
  }

  @computed get hasSchneiderIntegration() {
    return Boolean(this.FOUser.truck?.integrations?.hasOwnProperty(LoadSources.SCHNEIDER));
  }

  @computed get isEmergeApproved() {
    if (this.FOUser?.truck?.integrations?.hasOwnProperty(LoadSources.EMERGE)) {
      const status = this.FOUser?.truck?.integrations[LoadSources.EMERGE]['status'];
      return (
        status === 'APPROVED' ||
        Boolean(this.FOUser?.truck?.integrations[LoadSources.EMERGE].carrier_id)
      );
    }
    return false;
  }

  @computed get isCHRApproved() {
    return Boolean(
      this.FOUser?.truck?.integrations?.hasOwnProperty(LoadSources.CH_ROBINSON) &&
        this.FOUser?.truck?.integrations[LoadSources.CH_ROBINSON].carrier_id,
    );
  }

  @computed get CHRTCode() {
    let carrierId = '';
    if (this.isCHRApproved) {
      carrierId = this.FOUser?.truck?.integrations[LoadSources.CH_ROBINSON].carrier_id;
    }
    return carrierId;
  }

  @computed get isLoadsmartOODriver() {
    return Boolean(
      this.defaultDriver && this.FOUser.truck?.integrations?.hasOwnProperty(LoadSources.LOADSMART),
    );
  }

  @computed get completedStepsKeys() {
    return this.FOUser?.completedOnboardingSteps
      ? Object.keys(this.FOUser?.completedOnboardingSteps)
      : [];
  }

  /**
   * All users have a minimum of 4 steps that are required for onboarding to be marked as complete
   * In some cases steps that need to be skipped are marked as complete before onboarding begins
   * based on certain requirements (eg, user role step and fleet roles step)
   * In this use cases, checking onboarding complete only by step name would not ensure it is actually complete,
   * hence the additional check for step count = 4 is added
   */
  @computed get commonCompletedOnboardingCheck() {
    // we used to mark users complete if completed the invite step.
    // Below was added to maintain backwards compatibility
    if (this.completedStepsKeys && this.completedStepsKeys.includes('completedOnboarding')) {
      return true;
    }
    return this.completedStepsKeys && this.completedStepsKeys.includes('inviteStep');
  }

  // True, indicates existing use; false indicates new user
  @computed get isExistingOnboardingUser() {
    return (
      this.completedStepsKeys &&
      this.completedStepsKeys.includes('splashStep') &&
      !this.commonCompletedOnboardingCheck
    );
  }

  @computed get showLandingPage() {
    // Don't show landing page to logged in FleetTrack users
    if (this.rootStore.configStore.isFleetTrack && this.loggedIn) return false;
    return (
      this.completedStepsKeys &&
      !this.completedStepsKeys.includes('dotNumberStep') &&
      !this.commonCompletedOnboardingCheck
    );
  }

  @computed get showTutorial() {
    return (
      this.completedStepsKeys &&
      !this.completedStepsKeys.includes('tutorialStep') &&
      this.commonCompletedOnboardingCheck
    );
  }

  // Modal is shown to get missing account details
  @computed get showAccountDetailsForm() {
    return !this.FOUser?.dotNumber && !this.FOUser?.mcNumber;
  }

  @computed get completedTutorialStep() {
    if (this.completedStepsKeys && this.completedStepsKeys.includes('tutorialStep')) {
      return this.FOUser?.completedOnboardingSteps['tutorialStep'];
    }
    return null;
  }

  @computed get newDriversAvailable() {
    return Boolean(
      this.dispatcherTrucks.find((truck) => truck.dispatchable === DispatchableType.NEW),
    );
  }

  @computed get completedMcNumber() {
    return Boolean(this.FOUser?.mcNumber);
  }

  @computed get canAssignToMe() {
    // Only users of type 'dispatcher' who also have 'driver' role can assign to self.
    return this.dispatcher && this.FOUser?.truck?.roles.includes(TruckRoles.DRIVER);
  }

  @computed get dispatchableDrivers() {
    const dDrivers: (DriverTruck | undefined)[] = [];
    if (this.dispatcher && this.FOUser?.drivers) {
      dDrivers.push(...this.FOUser?.drivers);
      if (this.canAssignToMe) {
        dDrivers.push(this.FOUser?.truck);
      }
    }
    return dDrivers;
  }

  @computed get hasRequiredDocuments() {
    return Boolean(
      this.FOUser?.documents &&
        this.FOUser?.documents?.w9 &&
        this.FOUser?.documents?.coi &&
        this.FOUser?.documents?.cau,
    );
  }

  @computed get operatingLanes() {
    return this.FOUser?.operatingLanes || [];
  }

  @computed get userFirstName() {
    const nameArr = getNameArrFromFullName(this.FOUser?.displayName);
    return nameArr[0] || '';
  }

  @computed get userLastName() {
    const nameArr = getNameArrFromFullName(this.FOUser?.displayName);
    return nameArr[1] || '';
  }

  @computed get hasServiceAccount() {
    return Boolean(this.fleet?.geotabIntegration?.geotabIntegrationServiceAccount);
  }

  @action.bound setLogin(loginState: boolean) {
    this.loggedIn = loginState;
    if (loginState) {
      this.fetchUserAdditionalMetadata();
    }
  }

  @action.bound readAllNotifications() {
    this.FOUser.inAppNotifications = 0;
  }

  @action.bound setFleet(fleet: IFleet) {
    this.fleet = fleet;
    mixpanel.people.set({ [MIXPANEL_KEYS.CARRIER_NAME]: fleet ? fleet.carrierName : null });
  }

  @action.bound setVisitedModal(name: string) {
    this.visitedModals = [...this.visitedModals, name];
  }

  @action.bound setActiveFeatureModal(modal?: IFeatureModal) {
    this.activeFeatureModal = modal;
  }

  @action.bound setFeatureModals(featureModals: IFeatureModal[]) {
    this.featureModals = featureModals;
  }

  @action.bound async markTutorialComplete(stepValue: number) {
    await this.patchUser({ step: 'tutorialStep', stepValue });
  }

  @action.bound
  async fetchUserAdditionalMetadata() {
    if (!this.fleet && this.FOUser?.fleetId) {
      try {
        const fleet = await getFleet(this.FOUser?.fleetId);
        this.setFleet(fleet);
      } catch (error) {
        ErrorException('Error fetching fleet for user', error);
      }
    }
  }

  @action.bound
  async updateDispatchableDriver(driver: IFOUser, pageSource: String, data: any) {
    const payload = {
      driverEmail: driver.email,
      pageSource: pageSource,
      ...data,
    };

    const drivers = [...this.relatedDispatchableDrivers];

    try {
      const apiResponse = await ApiLayer.updateDriverSettings(payload);
      const newDriver = apiResponse.user;
      const newDriverUser = new FOUser(newDriver);
      const index = this.relatedDispatchableDrivers.findIndex(
        (item) => item.id === newDriverUser.id,
      );
      drivers[index] = newDriverUser;
      this.setRelatedDispatcherDrivers(drivers);
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        `Successfully updated ${getDriverNameVariation()} settings.`,
        {
          variant: 'success',
        },
      );
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        `Sorry, there was an error updating ${getDriverNameVariation()} settings.`,
        { variant: 'error' },
      );
    }
  }

  @action.bound
  async getDispatcherDrivers() {
    if (this.FOUser?.fleetId) {
      try {
        let dispatcherTrucks = await GetDispatcherTrucks(this.FOUser?.fleetId);
        if (
          dispatcherTrucks.length === 0 &&
          this.rootStore.configStore.isGeotab &&
          this.rootStore.partnerStore.geotabSession
        ) {
          const {
            geotabServer,
            geotabSession: { database, userName, sessionId },
          } = this.rootStore.partnerStore;
          dispatcherTrucks = await PostGeotabCreateDriverTrucks(
            geotabServer,
            database,
            userName,
            sessionId,
          );
        }
        this.setDispatcherTrucks(dispatcherTrucks);
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  @action.bound
  async getFeatureModals() {
    try {
      const modals = await GetFeatureModal();
      this.setFeatureModals(
        modals.sort((a, b) => {
          return a.priority - b.priority;
        }),
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  async viewFeatureModal(modalName) {
    try {
      const user = await PostUserViewModal(modalName);
      this.FOUser?.setModal(user.modal);
      await this.getFeatureModals();
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * Returns all drivers in the fleet, ie.
   * dispatchableDrivers & dispatchers with permission withDriver
   */
  @action.bound
  async getRelatedDispatcherDrivers() {
    this.setLoadingDispatcherDrivers(true);
    try {
      const params = {
        is_driver: true,
      };
      let relations = await GetRelations(params);
      const trucks: FOUser[] = [];
      relations.forEach((relation) => {
        if (relation?.target_user?.truck) {
          const truck = new FOUser(relation.target_user);
          trucks.push(truck);
        }
      });
      this.setRelatedDispatcherDrivers(trucks);
      this.setLoadingDispatcherDrivers(false);
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  async getRelatedDispatchers() {
    try {
      const params = {
        relationship_type: 'dispatcher',
      };
      let relations = await GetRelations(params);
      const trucks: FOUser[] = [];
      relations.forEach((relation) => {
        if (relation?.target_user?.truck) {
          const truck = new FOUser(relation.target_user);
          trucks.push(truck);
        }
      });
      this.setRelatedDispatchers(trucks);
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  async getDriverMetrics() {
    if (this.dispatcherTrucks) {
      try {
        let driverMetrics = await GetDriverMetrics();
        this.setDriverMetrics(driverMetrics);
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  @action.bound setDriverMetrics(data) {
    if (this.dispatcherTrucks) {
      this.dispatcherTrucks.map((driver) => {
        driver.setDriverMetric(data[driver.personId]);
        return driver;
      });
    }
  }

  @action.bound
  async patchUser(userData, form?) {
    try {
      const apiResponse = await ApiLayer.onboardUser(userData); // this API doesn't return fleetID
      this.setFOUser({ ...apiResponse.user, fleetId: this.FOUser?.fleetId });
    } catch (error) {
      let result = error as Error;
      if (Object.keys(result) && Object.keys(result).length > 0) {
        Object.entries(result).forEach(([key, value]) => {
          form.$(`${key}`).invalidate(value);
        });
      } else {
        throw new Error('Technical error updating drivers');
      }
    }
  }

  @action.bound
  async updateDriverSettings(settings, pageSource) {
    let payload = {
      ...settings,
      pageSource: pageSource,
    };
    try {
      const apiResponse = await ApiLayer.updateDriverSettings(payload);
      return apiResponse;
    } catch (error) {
      throw new Error('Technical error updating settings');
    }
  }

  @action.bound
  async deleteDriver(fleetId: string, driverId: string) {
    if (!driverId) {
      throw new Error("Driver's ID is missing");
    }
    try {
      const apiResponse = await ApiLayer.deleteDriver(fleetId, driverId);
      return apiResponse.data;
    } catch (error) {
      throw new Error('Technical error deleting the driver');
    }
  }

  @action.bound
  acceptTAC() {
    return this.patchUser({ tac: new Date().toISOString() });
  }

  @action.bound
  async updateDispatcherTrucks(driverTrucks) {
    const { fleetId } = this.FOUser;
    if (!fleetId) {
      throw new Error("Dispatcher's fleetID is missing");
    }
    try {
      const user = await patchDispatcherTrucks(
        fleetId,
        driverTrucks
          .filter((dt: any) => dt.dispatchable === DispatchableType.DISPATCHABLE)
          .map((dt) => (dt.rawDriverTruck ? dt.rawDriverTruck : dt)),
      );
      this.setFOUser(user);
    } catch (error) {
      throw new Error('Technical error updating drivers');
    }
  }

  @action.bound setDispatcherTrucks(driverTrucks: DriverTruck[]) {
    this.dispatcherTrucks.replace(driverTrucks);
  }

  @action.bound setRelatedDispatcherDrivers(driverTrucks: IObservableArray<FOUser>) {
    if (this.dispatcherWithDriver) {
      driverTrucks.push(this.FOUser);
    }
    this.relatedDispatchableDrivers = driverTrucks;
  }

  @action.bound setLoadingDispatcherDrivers(loading: boolean) {
    this.loadingDispatcherDrivers = loading;
  }

  @action.bound setRelatedDispatchers(dispatchers: IObservableArray<FOUser>) {
    this.relatedDispatchers = dispatchers;
  }

  @action.bound setFOUser(user: IFOUser) {
    this.FOUser = new FOUser(user);
    const { currentUser } = firebase.auth();
    if (currentUser) {
      currentUser.foUser = user;
    }
  }

  @action.bound
  setCurrentCoordinates(coordinates: ICoordinate) {
    this.currentCoordinates = coordinates;
  }

  @action.bound
  setShowedGeotabAcknowledgement(newState) {
    this.showedGeotabAcknowledgement = newState;
  }

  @action.bound
  setCheckingAuth(status) {
    this.checkingAuth = status;
  }

  @action.bound
  updateGeotabAcknowledgement = async (acknowldgementIncrementBy: number) => {
    try {
      const user = await PostGeotabAcknowledgement(acknowldgementIncrementBy);
      this.setFOUser(user);
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Sorry, there was an error acknowledging your request',
        { variant: 'error' },
      );
    }
  };

  @action.bound
  setDocumentBlob(label: string, documentBlob: Blob) {
    this.documentsBlobs = {
      ...this.documentsBlobs,
      [label]: documentBlob,
    };
  }

  @action.bound
  updateCoordinates() {
    this.setCurrentCoordinates({ lat: 0, lng: 0 });
    this.downloadCurrentCoordinatesAsync();
  }

  @action.bound
  setDownloadCurrentCoordinateFailure(error) {
    this.downloadCurrentCoordinateFailure = error;
  }

  @action.bound
  downloadCurrentCoordinatesAsync = async () => {
    if (this.currentCoordinates.lat || this.currentCoordinates.lng) {
      return this.currentCoordinates;
    }

    if (!this.downloadCurrentCoordinateFailure) {
      try {
        const currentCoordinatesAPIResponse = await ApiLayer.getCurrentCoordinates();
        const {
          location: { lat, lng },
          accuracy,
        } = currentCoordinatesAPIResponse;
        this.setCurrentCoordinates({ lat, lng });
        PostCurrentCoordinates(lat, lng, accuracy);
        mixpanelUpdateUser({ lat, lng });
        return { lat, lng } as ICoordinate;
      } catch (error) {
        if (this.FOUser) {
          this.setDownloadCurrentCoordinateFailure(error);
          mixpanelUpdateUser(this.currentCoordinates);
        }
        return this.currentCoordinates;
      }
    }
  };

  getCurrentCoordinates = asyncComputed(
    this.currentCoordinates,
    500,
    this.downloadCurrentCoordinatesAsync,
  );

  @action.bound
  updateDocument = async (label: string, file: File) => {
    const {
      snackbarStore: { enqueueSnackbarStore },
    } = this.rootStore;

    const acceptableFileType = ACCEPTED_UPLOAD_TYPES.find((acceptedType) =>
      file.type.match(`.${acceptedType}`),
    );

    if (!acceptableFileType) {
      enqueueSnackbarStore('File type not supported', { variant: 'error' });
      enqueueSnackbarStore('Please use PDF or image files only', { variant: 'info' });
      throw new Error('Unsupported file type');
    }

    try {
      const user = await putUserDocuments(label, file);
      this.setFOUser(user);
      await this.FOUser?.getProfilePicture();
    } catch (error) {
      enqueueSnackbarStore('Sorry, there was an error posting your file', { variant: 'error' });
      throw error;
    }
  };

  @action.bound
  getDocument = async (label: string, documentName?: string) => {
    let documentBlob = this.documentsBlobs && this.documentsBlobs[label];
    if (!documentBlob) {
      try {
        documentBlob = await getUserDocuments(label);
        this.setDocumentBlob(label, documentBlob);
      } catch (error) {
        this.rootStore.snackbarStore.enqueueSnackbarStore(
          'Sorry, there was an error downloading your file',
          { variant: 'error' },
        );
      }
    }
    if (documentBlob) {
      saveAs(documentBlob, documentName);
    }
  };

  @action.bound
  deleteDocument = async (documentType: string) => {
    const {
      snackbarStore: { enqueueSnackbarStore },
    } = this.rootStore;

    try {
      const user = await deleteUserDocuments(documentType);
      this.setFOUser(user);
    } catch (error) {
      enqueueSnackbarStore('Sorry, there was an error removing your file', { variant: 'error' });
      throw error;
    }
  };

  @action.bound setHideTracking(newState: boolean) {
    this.hideTracking = newState;
  }

  @action.bound
  setOperatingLanes(operatingLanes: IOperatingLane[]) {
    this.FOUser?.operatingLanes.replace(observable(operatingLanes));
  }

  @action.bound
  updateOperatingLanes = async (operatingLanes: IOperatingLane[]) => {
    const existingOperatingLanes = [...this.FOUser?.operatingLanes];
    try {
      operatingLanes = await validateAndAddCoordinatesToPreferredLanes(operatingLanes);
      this.setOperatingLanes(operatingLanes);
      const user = await postUserOperatingLanes(operatingLanes);
      this.setFOUser(user);
    } catch (error) {
      this.setOperatingLanes(existingOperatingLanes);
      this.rootStore.snackbarStore.enqueueSnackbarStore('Error updating operating lanes', {
        variant: 'error',
      });
      throw error;
    }
  };

  @action.bound
  updateCommunicationPreference = async (name: string, value: boolean | string) => {
    try {
      this.setLoading(true);
      const user = await patchUserCommunicationsPreference(name, value);
      this.setFOUser(user);
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Error updating communications preferences',
        { variant: 'error' },
      );
    } finally {
      this.setLoading(false);
    }
  };

  shareDocuments = async (email: string, documents: any = {}) => {
    try {
      await postSendDocuments(email, documents);
      this.rootStore.snackbarStore.enqueueSnackbarStore('Successfully shared your documents', {
        variant: 'success',
      });
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Sorry, there was an error sending your documents',
        { variant: 'error' },
      );
    }
  };

  @action.bound
  preserveAuthQueryParams(queryEmail: string, queryAuthToken: string) {
    this.queryEmail = queryEmail;
    this.queryAuthToken = queryAuthToken;
  }

  @action.bound
  checkWebview() {
    // For reference
    // https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
    // https://developer.chrome.com/multidevice/user-agent#webview_user_agent
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const isAndroidWebview = /version\/\d\.\d /.test(userAgent);
    const standalone = window.navigator.standalone;
    const safari = /safari/.test(userAgent);
    let isIOSWebview = false;
    if (ios) {
      if (!standalone && safari) {
        //browser
      } else if (standalone && !safari) {
        //standalone
      } else if (!standalone && !safari) {
        //uiwebview
        isIOSWebview = true;
      }
    }

    if (!ios && isAndroidWebview) {
      this.isAndroidWebview = true;
    }

    // Check for ios or android webview
    if (isIOSWebview || isAndroidWebview) {
      this.isMobileWebview = true;
    }
  }

  @action.bound
  updateSettings = async (settings): Promise<IFOUser | void> => {
    return await ApiLayer.updateSettings(settings)
      .then((data) => {
        if (data.user) {
          this.setFOUser(data.user);
          return data.user;
        }
      })
      .catch(() => {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error updating settings.', {
          variant: 'error',
        });
      });
  };

  @action.bound
  meSettingsUpdate = async (uid, settings): Promise<IFOUser | void> => {
    return await ApiLayer.meUpdate(uid, settings)
      .then((data) => {
        this.setFOUser({ ...data });
        return data;
      })
      .catch(() => {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error updating settings.', {
          variant: 'error',
        });
      });
  };

  @action.bound setOnboardedUsers(users) {
    this.onboardedFleetUsers = users;
  }

  @action.bound
  getOnboardedUsers = async () => {
    await ApiLayer.getOnboardedUsers()
      .then((data) => {
        this.setOnboardedUsers(data);
      })
      .catch(() => {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error getting DOT infomation.', {
          variant: 'error',
        });
      });
  };

  @action.bound setUsersNotOnboarded(users) {
    this.usersNotOnboarded = users;
  }

  @action.bound
  getUsersNotOnboarded = async (params) => {
    await ApiLayer.getUsersNotOnboarded(params)
      .then((data) => {
        this.setUsersNotOnboarded(data);
      })
      .catch(() => {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error getting DOT infomation.', {
          variant: 'error',
        });
      });
  };

  @action.bound
  setupUserOnCompleteOnboarding = async () => {
    if (this.dispatcher) {
      await this.getRelatedDispatcherDrivers();
    }
    /**
     Needed for driver-dispatcher permissions
     * &
     Used to get dispatchers info for dispatcher settings
     */
    await this.getRelatedDispatchers();
  };

  @action.bound
  async setActiveWalkthroughStep(activeWalkthroughStep) {
    this.activeWalkthroughStep = activeWalkthroughStep;
  }

  @action.bound
  resetPassword = async (email: string, emailFormField) => {
    if (!email) {
      throw new Error('Email is missing');
    }
    const response = await ApiLayer.resetPassword(email)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const { data } = error;
        if (data.msg.includes(EMAIL_NOT_FOUND_ERROR.error)) {
          emailFormField.invalidate(EMAIL_NOT_FOUND_ERROR.message);
        }
      });
    return response;
  };

  sendEmail = async (
    name: string,
    email: string,
    phone: string,
    message: string,
    loadId: string,
  ) => {
    try {
      await ApiLayer.sendEmail(
        { senderName: name, senderEmail: email, senderPhone: phone, senderMessage: message },
        loadId,
      );
      this.rootStore.snackbarStore.enqueueSnackbarStore('Successfully sent your email', {
        variant: 'success',
      });
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Sorry, there was an error sending your email',
        { variant: 'error' },
      );
    }
  };

  @action.bound
  checkBigRoadToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.checkBigRoadToken(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  checkEldLoadsToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.checkEldLoadsToken(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  checkEZLoadzToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.checkEZLoadz(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  checkFreightMateToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.checkFreightMateToken(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  checkSwitchboardToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.checkSwitchboardToken(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  checkFleetPulseToken(formData) {
    return new Promise((resolve, reject) => {
      this.setCheckingAuth(true);
      ApiLayer.fleetPulseLogin(formData)
        .then((data) => {
          this.setCheckingAuth(false);
          resolve(data);
        })
        .catch((err) => {
          this.setCheckingAuth(false);
          reject(err);
        });
    });
  }

  @action.bound
  async logout(history) {
    this.setLogin(false);
    if (!this.rootStore.configStore.isSSO) {
      history.push('/driver/login');
    } else {
      history.push('/');
    }
    await firebase.auth().signOut();
  }
}
