const primary = {
  main: '#44B0E9',
  contrastText: '#fff',
};
const palette = {
  primary,
  secondary: {
    main: '#214D78',
    light: '#3B648C',
    dark: '#1F3E5C',
  },
  partnerCustomPrimary: primary,
  background: {},
};
export default palette;
