import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { getTokenHeader } from '../GetTokenHeader';
import config from '../../../../config';
import { ApiFail } from '../../../utils/ErrorService';
import { IFOUser } from 'models/interfaces/shared/IFOUser';

export const PostUserViewModal = async (modalName: string) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  return axios
    .post(
      `${config.apiUrlV2}/users/user-viewed-modal`,
      {
        modal_name: modalName,
      },
      { headers: tokenHeader },
    )
    .then((response: IFOUser) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
