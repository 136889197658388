const primary = {
  main: '#23374D',
  dark: '#0B1117',
  disabled: '#919BA6',
  light: 'rgba(35, 55, 77, 0.1)',
  contrastText: '#FFFFFF',
};
const secondary = {
  light: 'rgba(16, 137, 255, 0.1)',
  medium: '#FFFFFF', //TODO check with design team because it's used in phone number button.
  main: '#1089FF',
  dark: '#004486',
  disabled: '#87C4FF',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: secondary,
  partnerCustomSecondary: primary,
  background: {
    walkthroughPurple: '#0068CE', // Walkthrough bubble bg, etc
    lightBlue: {
      light: '#F4F4FE', // Table hover states
    },
  },
  navy: '#23374D', // Search LP form background, etc
  loadStatus: {
    booked: '#1089FF',
    shared: '#2B78BF',
    completed: '#2BBF8A',
    cancelled: '#D35252',
  },
};
export default palette;
