export const DEFAULT_NOTIFICATION_COUNT = 50;
export const NAV_BAR_HEIGHT = 70;
export const NAV_BAR_HEIGHT_SM = 51;
export const REMOVE_NAV_BAR = '/standalone';
export const EMPTY_STRING = '--';
export const DEFAULT_RADIUS = 50;
export const RADIUS_MAX_LIMIT = 200;
export const DROPDOWN_ALL_OPTION = { label: 'All', value: 'ALL' };
export const ACCEPTED_UPLOAD_TYPES = ['txt', 'pdf', 'png', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'];
export const FO_USER_TYPES = { broker: 'broker', driver: 'driver' };
export const HIDDEN_EMAILS = ['donotsend', 'donotemail'];
export const CURRENT_LOCATION = 'current location';
export const DEFAULT_LANE_SIZE = { LocalHaul: true, ShortHaul: true, LongHaul: true };
export const CHR_EMAIL = 'carrier.services@chrobinson.com';
export const CHR_PHONE = '800-326-9977';
export const DEFAULT_CURRENCY = 'USD';
export const REVENUE_UTILITATION_PERCENTAGE = 30; // %
export const REVENUE_COST_PER_DAY = 60;
export const REVENUE_NUMBER_OF_MONTH_DAYS = 30;

// Feature flags
export const GB_TRAILER_REVENUE_LISTING_FEATURE_FLAG = 'trailer-revenue-listing';
export const GB_AUTHORITY_EXPRESS_FORM_FEATURE_FLAG = 'authority-express-form';
export const GB_LUCKY_TRUCK_REFERRAL_FEATURE_FLAG = 'lucky-truck-referral';
