import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import useStyles from './styles';
interface IFOSnackbarProviderOwnProps {
  isGeotab: boolean;
  children: ReactNode;
}

type IFOSnackbarProviderProps = IFOSnackbarProviderOwnProps;

const FOSnackbarProvider = ({ children, isGeotab }: IFOSnackbarProviderProps) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={2}
      anchorOrigin={{ vertical: 'bottom', horizontal: isGeotab ? 'right' : 'left' }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default FOSnackbarProvider;
