import firebase from 'firebase/app';
import 'firebase/auth';
import Trailer from 'models/dataStructures/Trailer';
import config from 'config';
import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import { getTokenHeader } from '../GetTokenHeader';

// eslint-disable-next-line import/prefer-default-export
export const getTrailersByListedStatus = async (
  status: 'listed' | 'unlisted',
  onlyUnreserved?: boolean,
): Promise<Trailer[]> => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/trailers/?status=${status}&only_unreserved=${onlyUnreserved}`;
  return axios
    .get(uri, { headers: tokenHeader })
    .then(
      (response: {
        data: {
          data: Trailer[];
        };
      }) =>
        response.data.data.reduce((acc, trailer) => {
          try {
            acc.push(new Trailer(trailer));
          } catch (error) {
            console.log(`Error parsing trailer list: ${error}`);
          }
          return acc;
        }, []),
    )
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
