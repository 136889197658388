export default {
  USER_CONFIRMED_MATCH: 'user_confirmed_match',
  USER_DECLINED_MATCH: 'user_declined_match',
  RAL_FOUND_MATCH: 'ral_found_match',
  LOAD_POSTED: 'load_posted',
  LOADS_PARSED: 'loads_parsed',
  TRACKING_ONBOARDING_REQUEST: 'tracking_onboarding_request',
  LOAD_BOOKED: 'load_booked',
  MATCH_RECOMMENDED: 'match_recommended',
  SYSTEM_LOAD_CANCELLED: 'system_load_cancelled',
  LOAD_SHARED: 'load_shared',
  FLEET_USER_JOINED: 'fleet_user_joined',
  FLEET_USER_REMOVED: 'fleet_user_removed',
  LOAD_UNASSIGNED: 'load_unassigned',
  CONVOY_LOAD_SHARED: 'convoy_load_shared',
};
