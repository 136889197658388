import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { getTokenHeader } from 'services/APIServices/GetTokenHeader';
import config from 'config';
import { ApiFail } from 'utils/ErrorService';
import { IPostLoadUnassignHelper } from 'models/apiResponse/postLoadUnassign';

export const PostLoadUnassign = async (loadId: string, matchId: string) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/loads/${loadId}/unassign`;
  let payload = {};
  if (matchId) {
    payload = { matchId };
  }

  return axios
    .post(uri, payload, { headers: tokenHeader })
    .then((response: IPostLoadUnassignHelper) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
