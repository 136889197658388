import React from 'react';
import Grid from '@material-ui/core/Grid';
import MovingTruck from 'constants/MovingTruckIcon';
import useStyles from './styles';

const SplashScreen = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>
        <img className={classes.img} src={MovingTruck} alt='Loading...' />
      </Grid>
    </Grid>
  );
};

export default SplashScreen;
