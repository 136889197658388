import production from './production';
import staging from './staging';
import development from './development';
import test from './test';

const configVariants = {
  production,
  staging,
  development,
  test,
};
const config = {
  freshworksId: 43000001978,
  partner: {
    url: 'https://app.freighttab.com',
    name: 'Freight Tab',
    partnerName: 'Geotab',
    logo: null, // TODO: Not available at the moment
  },
  ...configVariants[process.env.NODE_ENV || 'development'],
};

export default config;
