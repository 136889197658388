import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';
import LandingPage from 'assets/images/backgrounds/LandingPage.jpg';

const useStyles = makeStyles((theme: ITheme) => ({
  background: {
    backgroundImage: `url(${LandingPage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

export default useStyles;
