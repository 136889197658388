import { action, flow, observable } from 'mobx';
import ApiLayer from 'services/APIServices/ApiLayer';
import Truck from 'models/dataStructures/Trucks';
import { RAL_DELETE_ERROR } from 'constants/Messages';
import { CollectionsStore } from './CollectionsStore';
import { DriverAppStore } from './DriverAppStore';
import { GetPostedTrucks } from 'services/APIServices/GetPostedTrucks';
import EnhancedStoreBase from './EnhancedStoreBase';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS } from 'constants/Mixpanel';
import { mixpanelTrack } from 'services/FOMixpanel';
import { toJS } from 'mobx';
import { formatYearMonthDay } from 'utils/utility';

export class TruckStore extends EnhancedStoreBase {
  rootStore: DriverAppStore;
  @observable postedTrucks = new CollectionsStore(this.rootStore, true, GetPostedTrucks);
  @observable showPostDonePopover = false;
  @action.bound
  postMyTruck = flow(function* (this: TruckStore, query, showError = true) {
    this.setLoading(true);
    this.setError(null);
    try {
      this.showPostDonePopover = true;
      yield ApiLayer.postMyTruck(query).then((data) => {
        const responseData = data;
        if (query.ralId) {
          this.postedTrucks.deleteItemFromResults(query.ralId);
          this.postedTrucks.updateItemInResults(new Truck(responseData.data.data));
        } else {
          const mixpanelProps = {
            [MIXPANEL_KEYS.PICKUP_LOCATION]: query.pickup && query.pickup.address,
            [MIXPANEL_KEYS.PICKUP_DATE_START]: query.pickupRange
              ? formatYearMonthDay(query.pickupRange.range_start, true)
              : null,
            [MIXPANEL_KEYS.PICKUP_DATE_END]: query.pickupRange?.range_end
              ? formatYearMonthDay(query.pickupRange.range_end, true)
              : null,
            [MIXPANEL_KEYS.DROPOFF_LOCATION]: query.dropoff && query.dropoff.address,
            [MIXPANEL_KEYS.DROPOFF_DATE_START]: query.dropoffRange
              ? formatYearMonthDay(query.dropoffRange.range_start, true)
              : null,
            [MIXPANEL_KEYS.DROPOFF_DATE_END]: query.dropoffRange?.range_end
              ? formatYearMonthDay(query.dropoffRange.range_end, true)
              : null,
            [MIXPANEL_KEYS.EQUIPMENT_LIST]:
              toJS(query.equipmentTypeList) && toJS(query.equipmentTypeList).length > 0
                ? toJS(query.equipmentTypeList)
                : 'All',
            [MIXPANEL_KEYS.DEADHEAD]:
              query.radius && query.radius.amount ? `${query.radius.amount} miles` : null,
          };
          mixpanelTrack(MIXPANEL_EVENTS.RAL_SUBMITTED, {
            ...mixpanelProps,
          });
          this.postedTrucks.updateItemInResults(new Truck(responseData.data.data));
        }
      });
    } catch (error) {
      if (showError) {
        this.rootStore.snackbarStore.enqueueSnackbarStore(
          'Sorry, there was an error posting your request',
          { variant: 'error' },
        );
      }
      throw error;
    } finally {
      this.setLoading(false);
    }
  });
  @action.bound
  deletePostedTruck = flow(function* (this: TruckStore, postedTruckId) {
    const trucksBackup = this.postedTrucks.results;
    try {
      yield ApiLayer.deletePostedTruck(postedTruckId).then((data) => {
        const responseData = data;
        if (responseData.data.data.succ) {
          this.postedTrucks.deleteItemFromResults(postedTruckId);
        } else {
          this.rootStore.snackbarStore.enqueueSnackbarStore(RAL_DELETE_ERROR, { variant: 'error' });
        }
      });
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(RAL_DELETE_ERROR, { variant: 'error' });
      this.postedTrucks.updateResults(trucksBackup);
    }
  });

  constructor(rootStore: DriverAppStore) {
    super();
    this.rootStore = rootStore;
  }

  @action.bound
  hidePostDonePopover() {
    this.showPostDonePopover = false;
  }
}

export default TruckStore;
