import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import config from 'config';
import { ApiFail } from 'utils/ErrorService';
import { getTokenHeader } from '../GetTokenHeader';

export const UpdateLoadOffer = async (offerId: string, amount: number) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/offers/${offerId}`;
  return axios
    .put(uri, { amount }, { headers: tokenHeader })
    .then((response) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw error;
    });
};
