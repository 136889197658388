import palette from './palette';
import typographyOverrides from './typography';

const tableOverrides = {
  MuiTableCell: {
    root: {
      ...typographyOverrides.MuiTypography.body1,
    },
    head: {
      background: palette.background.greyLight,
      color: palette.text.secondary,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...typographyOverrides.MuiTypography.body2,
    },
    stickyHeader: {
      backgroundColor: palette.background.greyLight,
    },
  },
  MuiTableBody: {
    root: {
      background: palette.background.paper,
    },
  },
};
export default tableOverrides;
