const palette = {
  text: {
    primary: '#1B253A',
    secondary: '#777E8A',
    disabled: '#C2C2C2'
  },
  borderColor: '#BFC8D6',
  divider: '#ECECEC',
  darkDivider: '#C4C4C4',
  default: {
    main: '#CCCCCC',
    dark: '#1F1F1F',
    disabled: '#C3C3C3',
    contastText: '#FFFFFF',
  },
  success: {
    lighter: '#D7F5DF',
    main: '#31B958',
  },
  error: {
    lighter: '#FDE8E8',
    light: '#D35252',
    main: '#B93131',
    dark: '#8A2424',
  },
  warning: {
    main: '#E5A21E',
  },
  info: {
    main: '#EDEFFF',
  },
  background: {
    greyLight: '#F1F1F1',
    purpleLight: '#FAFAFF',
    purple: '#EEEEFE',
    default: '#F5f5f5',
    paper: '#FFFFFF',
    greyDark: '#6D7C89',
    navy: '#171A39',
    navyDark: '#111328',
    walkthroughPurple: '#4B56BA',
    darkBlue: '#002665',
    grey: '#ECEDF5',
  },
  navy: '#2D3266',
  blue: "#478AF7",
  lightBlue: {
    light: '#F7F7FE', // Table hover states
    dark: '#E6E9ED', // Used in slider input (component not used)
  },
  loadStatus: {
    booked: '#2BBFB6',
    shared: '#2B78BF',
    pending: '#E4BB28',
    completed: '#2BBF8A',
    cancelled: '#B93131',
    bookedLight: '#D7F5E0',
  },
  chr: {
    main: '#27ADEA',
    contrastText: '#FFFFFF',
    light: '#D4F0FB',
    dark: '#0076B3',
  },
  orange: {
    lighter: '#FCE0D3',
    light: '#F07038',
    main: '#F26423',
    dark: '#90401B',
  },
};

export default palette;
