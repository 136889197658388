import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme/ITheme';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    height: '100%',
    padding: '0 45px',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
  caption: {
    maxWidth: 300,
  },
}));

export default useStyles;
