const config = {
  ssoUrl: 'https://quality.bigroad.com/partner-sign-in/freight', // BigRoad Staging
  clarityId: '86ryxvfrcn',
  hotjarId: '2804969',
  datadog: {
    applicationId: '5aa76349-4049-4005-84d7-032f09fe5044',
    clientToken: 'pubcb94708d8b4523aa861826e83a30483d',
    service: 'bigroad-staging',
  },
};

export default config;
