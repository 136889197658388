import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import config from 'config';
import { IDriverAppStore } from 'models/dataStructures/IDriverAppStore';
import { DriverAppStore } from 'store/DriverAppStore';
import LandingPageContainer from 'components/LandingPageContainer';
import GridContainer from 'components/GridContainer';
import CustomTypography from 'components/CustomTypography';
import useStyles from './styles';
import classnames from 'classnames';
import LoadingText from './LoadingText';

/**
 * Non sso will see the loading screen before auth calls
 * ie. only while LP is lazy loaded which won't take too long
 */

interface InitialSplashScreenOwnProps {
  updateInitialSplashCallback?: () => void;
}
type InitialSplashScreenProps = IDriverAppStore & InitialSplashScreenOwnProps;

const InitialSplashScreen = inject('driverAppStore')(
  observer(({ updateInitialSplashCallback, driverAppStore }: InitialSplashScreenProps) => {
    const {
      configStore: { initialLoadProgress, setInitialLoadProgress, isSSO },
    } = driverAppStore as DriverAppStore;

    const theme = useTheme();
    const progressIncrease = isSSO ? 10 : 50;

    const classes = useStyles();

    let timer;

    useEffect(() => {
      return () => {
        if (updateInitialSplashCallback) {
          /**
           * Adding a delay before setting flag to false as callback
           * is trigger faster within mobile app
           */
          setTimeout(() => {
            updateInitialSplashCallback();
          }, 1000);
        }
        clearInterval(timer);
      };
    }, []);

    useEffect(() => {
      timer = setInterval(() => {
        if (initialLoadProgress < 100) {
          setInitialLoadProgress(initialLoadProgress + progressIncrease);
        } else {
          setInitialLoadProgress(100);
          clearInterval(timer);
        }
      }, 400);
      return () => {
        clearInterval(timer);
      };
    }, [initialLoadProgress]);

    return (
      <LandingPageContainer isLoadingScreen>
        <Box className={classes.container}>
          <GridContainer
            direction='column'
            spacing={1}
            fullHeight
            wrap='nowrap'
            alignItems='center'
            justify='center'
          >
            <Grid item className={classnames(classes.fullWidth, classes.content)}>
              <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item xs={6} sm={3} lg={2} xl={1}>
                  {config.partner.logo ? (
                    <img src={config.partner.logo} className={classes.logo} alt='Partner logo' />
                  ) : (
                    <CustomTypography fontSemibold variant='h1' gutterBottom>
                      {config.partner.name}
                    </CustomTypography>
                  )}
                </Grid>
              </Grid>
              <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item xs={10} sm={6} lg={4} xl={3}>
                  <LinearProgress
                    classes={{ root: classes.root, bar: classes.bar }}
                    variant='determinate'
                    value={initialLoadProgress}
                    color='secondary'
                  />
                </Grid>
              </Grid>
              <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item xs={12} sm={8} lg={6} xl={5}>
                  <LoadingText />
                </Grid>
              </Grid>
            </Grid>
          </GridContainer>
        </Box>
      </LandingPageContainer>
    );
  }),
);

export default InitialSplashScreen;
