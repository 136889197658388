const config = {
  ssoUrl: 'https://app.ezlogz.com/ezloadz',
  datadog: {
    applicationId: '4dbea5a9-d26f-4e7d-9a03-dfbf1a63b6bc',
    clientToken: 'pub3662382a4abb9779146042a7b366ed94',
    service: 'ezloadz-production',
  },
};

export default config;
