import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import config from '../../../../config';

const BASE_URL = 'https://api.netlify.com/api/v1';

interface SplitTest {
  active: boolean;
  branches: Array<{ branch: string; f: number; p: number }>;
  created_at: string;
  id: string;
  name: string;
  site_id: string;
  updated_at: string;
}

export const getSplitTests = async () => {
  const url = `${BASE_URL}/sites/${config.netlifyApiId}/traffic_splits`;
  return axios
    .get(url, { headers: { Authorization: `Bearer ${config.netlifyToken}` } })
    .then((response) => response.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
