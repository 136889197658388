export const DOCUMENT_TYPES = [
  { value: 'w9', label: 'W9' },
  { value: 'coi', label: 'Certificate of Insurance' },
  { value: 'cau', label: 'Carrier Authority' },
  { value: 'ca', label: 'Carrier Agreement' },
  { value: 'workersComp', label: "Worker's Comp" },
  { value: 'cvor', label: 'CVOR' },
  { value: 'weightPermits', label: 'Weight Permits' },
  { value: 'commodityPermits', label: 'Commodity Permits' },
  { value: 'specializedInsurance', label: 'Specialized Insurance' },
  { value: 'corporateDocuments', label: 'Corporate Documents' },
  { value: 'bondInformation', label: 'Bond Information' },
  { value: 'scacCode', label: 'Scac Code' },
  { value: 'ctpat', label: 'CTPAT' },
];

export enum DocumentStatus {
  PENDING = 'pending', // This means the document was uploaded but has not yet been Accepted/Rejected.
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum DocumentKeys {
  TENDER = 'tender',
  INVOICE = 'invoice',
  POD = 'pod',
}
