const config = {
  ssoUrl: 'https://web1.test.ezlogz.com/ezloadz',
  clarityId: '9n4wnbj248',
  datadog: {
    applicationId: '6881d0a3-e273-4390-8778-04075a53773c',
    clientToken: 'pub5ea43e44e595ef06841fce2624a5636d',
    service: 'ezloadz-staging',
  },
};

export default config;
