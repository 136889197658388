import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import config from 'config';
import { getTokenHeader } from 'services/APIServices/GetTokenHeader';
import { ApiFail } from 'utils/ErrorService';
import { IGetDispatcherTrucksHelper } from 'models/apiResponse/getDispatcherTrucks';
import { DriverTruck } from 'models/dataStructures/DriverTruck';

export const GetDriverMetrics = async () => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const uri = `${config.apiUrlV2}/matches/metrics`;
  return axios
    .get(uri, { headers: tokenHeader })
    .then((response: IGetDispatcherTrucksHelper) => response.data.data)
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
