import { action, IObservableArray, observable } from 'mobx';
import { DriverAppStore } from '../DriverAppStore';
import { getNotifications } from 'services/APIServices/GetNotifications';
import { updateNotifications } from 'services/APIServices/UpdateNotifications';
import ApiLayer from 'services/APIServices/ApiLayer';
import { IGetNotifications } from 'models/apiResponse/getNotifications';
import { INotificationData } from 'models/interfaces/shared/INotificationData';
import EnhancedStoreBase from '../EnhancedStoreBase';
import { DEFAULT_NOTIFICATION_COUNT } from 'constants/General';

export class NotificationsStore extends EnhancedStoreBase {
  rootStore: DriverAppStore;
  @observable results: IObservableArray<INotificationData> = observable([]);

  constructor(rootStore: DriverAppStore) {
    super();
    this.rootStore = rootStore;
  }

  notificationsCacheKey = (page: number) => `notifications-${page}`;

  @action.bound
  downloadResults = async (pageNumber = 1, pageLimit = DEFAULT_NOTIFICATION_COUNT) => {
    this.setError(null);
    if (this.results.length > 0 && pageNumber <= this.pagination.page) {
      return;
    }
    try {
      this.setLoading(true);
      const result: IGetNotifications = await getNotifications(pageNumber, pageLimit);
      if (result) {
        this.setResults([...this.results, ...result.data]);
        this.setPagination(result.pagination);
        this.rootStore.matchStore.recommendedMatches.cache[
          this.notificationsCacheKey(result.pagination.page)
        ] = result.data
          .filter((item) => !!item.data.match)
          .map((notification) => notification.data.match);
      }
    } catch (error) {
      console.log(error);
      this.setError(error);
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Technical error downloading notifications. Please refresh and try again.',
        { variant: 'error' },
      );
    } finally {
      this.setLoading(false);
    }
  };

  @action.bound
  updateNotification = async (notification?: INotificationData) => {
    if (!notification && this.rootStore.userStore.FOUser?.inAppNotifications === 0) {
      return;
    }

    if (notification) {
      notification.read = true;
    }
    this.rootStore.userStore.setFOUser({
      ...this.rootStore.userStore.FOUser,
      inAppNotifications: notification ? this.rootStore.userStore.FOUser.inAppNotifications - 1 : 0,
    });
    try {
      await updateNotifications(notification?.id || 'readAll');
      if (!notification) {
        this.refreshNotifications();
      }
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Technical error downloading notifications. Please refresh and try again.',
        { variant: 'error' },
      );
    }
  };

  @action.bound
  clearAllNotificationsCache = () => {
    [...Array(this.pagination.totalPages)].forEach((_, pageNumber) => {
      delete this.rootStore.matchStore.recommendedMatches.cache[
        this.notificationsCacheKey(pageNumber)
      ];
    });
  };

  @action.bound
  refreshNotifications = () => {
    this.setResults([]);
    this.clearAllNotificationsCache();
    this.downloadResults();
  };

  @action
  setResults(notificationData: INotificationData[]) {
    this.results.replace(notificationData);
  }

  @action.bound
  deleteNotification = async (notificationId: string) => {
    try {
      await ApiLayer.deleteNotification(notificationId);
      this.refreshNotifications();
    } catch (error) {
      this.rootStore.snackbarStore.enqueueSnackbarStore(
        'Technical error deleting notification. Please refresh and try again.',
        { variant: 'error' },
      );
    }
  };
}
