import palette from './palette';

const inputOverrides = {
  MuiFormHelperText: {
    root: {
      minHeight: 'auto',
    },
  },
  MuiOutlinedInput: {
    root: {
      background: '#fff',
      '&.Mui-disabled': {
        color: palette.text.disabled,
        WebkitTextFillColor: palette.text.disabled, // Fix opacity Safari bug
        background: '#F5F5F5',
      },
    },
  },
};
export default inputOverrides;
