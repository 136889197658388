import React, { memo } from 'react';
import classnames from 'classnames';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import useStyles from './styles';

interface ICustomTypographyOwnProps {
  customClass?: string;
  fontRegular?: boolean;
  fontMedium?: boolean;
  fontSemibold?: boolean;
  fontBold?: boolean;
  textCapitalize?: boolean;
  textUppercase?: boolean;
  textAlign?: string;
  component?: string;
}

type ICustomTypographyProps = TypographyProps & ICustomTypographyOwnProps;
const CustomTypography = memo(
  ({
    fontRegular,
    fontMedium,
    fontSemibold,
    fontBold,
    textCapitalize,
    textUppercase,
    customClass,
    children,
    component = 'div',
    textAlign = 'left',
    ...other
  }: ICustomTypographyProps) => {
    const classes = useStyles({ textAlign });
    return (
      <Typography
        component={component}
        className={classnames(customClass, {
          [classes.fontRegular]: fontRegular,
          [classes.fontMedium]: fontMedium,
          [classes.fontSemibold]: fontSemibold,
          [classes.fontBold]: fontBold,
          [classes.textCapitalize]: textCapitalize,
          [classes.textUppercase]: textUppercase,
          [classes.textAlign]: textAlign,
        })}
        {...other}
      >
        {children}
      </Typography>
    );
  },
);

export default CustomTypography;
