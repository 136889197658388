import production from './production';
import staging from './staging';
import development from './development';
import test from './test';
import appConfig from './appConfig';

const configVariants = {
  production,
  staging,
  development,
  test,
};

const config = {
  ...configVariants[process.env.NODE_ENV || 'development'],
  ...appConfig,
};

export default config;
