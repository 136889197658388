import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { getTokenHeader } from '../GetTokenHeader';
import config from 'config';
import { ApiFail } from 'utils/ErrorService';

export const CancelLoadOffer = async (offerId: string) => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();
  const url = `${config.apiUrlV2}/offers/${offerId}`;

  return axios({
    method: 'DELETE',
    url,
    headers: tokenHeader,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
};
