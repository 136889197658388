const config = {
  ssoUrl: 'https://testing.pt1view.com/lineage/api/redirectWithToken?url=',
  clarityId: '86s0a8h8hz',
  datadog: {
    applicationId: 'da22de46-b52c-4acd-a8c8-a85cff2fea7b',
    clientToken: 'pub0c21daf2de9a9d614bad3f37931bd998',
    service: 'freight-mate-staging',
  },
};

export default config;
