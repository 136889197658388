import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme/ITheme';

const useStyles = makeStyles((theme: ITheme) => ({
  fullHeight: {
    height: '100%',
  },
}));

export default useStyles;
