import palette from './palette';

const alertOverrides = {
  MuiAlert: {
    filledInfo: {
      background: palette.info.main,
      color: palette.text.primary,
      '& .MuiAlert-icon': {
        alignItems: 'center',
      },
    },
  },
};
export default alertOverrides;
