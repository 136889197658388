import { computed, observable } from 'mobx';
import moment from 'moment';
import { ITruckData } from '../../interfaces/shared/ITruckData';
import { IPreferredPerMileRate } from '../../interfaces/shared/IPreferredPerMileRate';
import { IDropoffTruck } from '../../interfaces/shared/IDropoffTruck';
import { IPickupTruck } from '../../interfaces/shared/IPickupTruck';
import { IRadius } from '../../interfaces/shared/IRadius';
import { IFilterRange } from '../../interfaces/shared/IFilterRange';
import { IDateRange } from 'models/interfaces/shared/IDateRange';
import { sentenceCase, formatDateRangeDisplay } from 'utils/utility';

class Truck implements ITruckData {
  @observable id: string;
  @observable firebaseUID: string;
  @observable pickupRange: IDateRange | null;
  @observable expiresOn: string | null;
  @observable perMileRate: IPreferredPerMileRate;
  @observable personId: string;
  @observable dropoff: IDropoffTruck;
  @observable pickup: IPickupTruck;
  @observable radius: IRadius;
  @observable timestamp: number;
  @observable equipment: string;
  @observable equipmentTypeList:
    | 'Van'
    | 'Reefer'
    | 'Flatbed'
    | 'Stepdeck'
    | 'Power only'
    | 'Hopper bottom'
    | 'Double drop'
    | 'Dump trailer'
    | 'Low boy'
    | 'Auto carrier'
    | 'Tanker'
    | 'Containers'
    | 'Conestoga'[];
  @observable weight: IFilterRange;
  @observable length: IFilterRange;
  @observable laneSize: string;
  @observable loadSize: string;
  @observable dropoffRange: IDateRange | null;
  @observable dropoffRadius: IRadius;
  @observable companyName: [string];
  @observable match_count: number;

  constructor(truck: ITruckData) {
    if (!truck) {
      throw new Error('Truck is undefined.');
    }
    const {
      id,
      equipmentTypeList,
      perMileRate,
      pickup,
      dropoff,
      pickupRange,
      expiresOn,
      radius,
      timestamp,
      equipment,
      firebaseUID,
      personId,
      weight,
      length,
      laneSize,
      loadSize,
      dropoffRange,
      dropoffRadius,
      companyName,
      match_count = 0,
    } = truck;
    this.id = id;
    this.firebaseUID = firebaseUID;
    this.pickupRange = pickupRange;
    this.expiresOn = expiresOn;
    this.perMileRate = perMileRate;
    this.personId = personId;
    this.dropoff = { ...dropoff, location: dropoff?.address };
    this.pickup = { ...pickup, location: pickup.address };
    this.radius = radius;
    this.timestamp = timestamp;
    this.equipment = equipment;
    this.equipmentTypeList = equipmentTypeList;
    this.weight = weight;
    this.length = length;
    this.laneSize = laneSize;
    this.loadSize = loadSize;
    this.dropoffRange = dropoffRange;
    this.dropoffRadius = dropoffRadius;
    this.companyName = companyName;
    this.match_count = match_count;
  }

  /**
   * Format all basic request data that is displayed
   * Mainly creating a flat object for table column sorting
   */
  @computed get displayInfo() {
    return {
      matchCount: this.match_count,
      pickupLocation: this.pickup && this.pickup?.location,
      requestedOn: moment.unix(this.timestamp),
      pickupRange: formatDateRangeDisplay(this.pickupRange),
      dropoffLocation: this.dropoff && this.dropoff?.location,
      equipmentTypes: this.equipmentTypeList ? sentenceCase(this.equipmentTypeList.join(', ')) : '',
    };
  }
}

export default Truck;
