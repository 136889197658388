import EldLoadsLogo from 'assets/images/logos/EldLoads-logo.svg';
import production from './production';
import staging from './staging';
import development from './development';
import test from './test';

const configVariants = {
  production,
  staging,
  development,
  test,
};
const config = {
  freshworksId: 43000002244,
  authorityExpressFormName: 'Authority Express DART Signup - ELD Loads',
  partner: {
    url: 'https://app.eldloads.com',
    name: 'ELD Loads',
    partnerName: 'Gorilla Safety',
    logo: EldLoadsLogo,
  },
  ...configVariants[process.env.NODE_ENV || 'development'],
};

export default config;
