const secondary = {
  light: '#E5F5FF',
  main: '#00A0FF',
  dark: '#008CDF',
  disabled: '#80CFFF',
  medium: '#E5F8FB',
  contrastText: '#FFFFFF',
};
const primary = {
  light: '#EAF9F3',
  main: '#42525C',
  dark: '#1A1A1A',
  disabled: '#A0A8AD',
  contrastText: '#FFFFFF',
  medium: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: secondary,
  partnerCustomSecondary: primary,
  background: {
    walkthroughPurple: '#364554', // Walkthrough bubble bg, etc
    purpleLight: '#E5F5FF', // Light background for selected grid checkbox/radio block input etc
    lightBlue: {
      light: '#E5F5FF', // Table hover states
    },
  },
  navy: '#42525C', // Search LP form background, etc
  loadStatus: {
    booked: '#00A0FF',
    shared: '#FF5600',
    completed: '#2BBF8A',
    cancelled: '#B93131',
  },
};
export default palette;
