import { action, computed, observable, toJS } from 'mobx';
import ApiLayer from 'services/APIServices/ApiLayer';
import Load from 'models/dataStructures/Load';
import { Pagination } from 'models/interfaces/shared/IPagination';
import { SearchLoads } from 'services/APIServices/PostSearchLoads';
import { GetSavedSearches } from 'services/APIServices/GetSavedSearches';
import {
  deadheadBatchCalculation,
  formatDeadhead,
  getFormattedBrokerName,
  sentenceCase,
  sentenceToCamelCase,
  sortBrokerList,
} from 'utils/utility';
import LoadSources from 'constants/LoadSources';
import LoadCompanyNames from 'constants/LoadCompanyNames';
import { SortValues } from 'constants/SortValues';
import { DriverAppStore } from './DriverAppStore';
import { CollectionsStore } from './CollectionsStore';

const batchCount = 10;

export class SearchStore {
  rootStore: DriverAppStore;
  @observable searchResults = new CollectionsStore(this.rootStore, true, SearchLoads, '', this);
  @observable previousQuery = {};
  @observable selectedLoad: Load | null = null;
  @observable sortFilter = [SortValues.RATE];
  @observable sortDirection = -1;
  @observable recentSearches = [];
  @observable loadSourceOptions = [];
  @observable certifiedButtonPopupOpened = false;

  constructor(rootStore: DriverAppStore) {
    this.rootStore = rootStore;
  }

  @computed get recentPickupLocations() {
    return this.recentSearches.length > 0
      ? this.recentSearches.reduce(
          (acc, curr) => (curr.pickupLocation ? [...acc, curr.pickupLocation] : acc),
          [],
        )
      : [];
  }

  @computed get recentDropoffLocations() {
    return this.recentSearches.length > 0
      ? this.recentSearches.reduce(
          (acc, curr) => (curr.dropoffLocation ? [...acc, curr.dropoffLocation] : acc),
          [],
        )
      : [];
  }

  @computed get loadEquipmentOptions() {
    if (this.searchResults?.results?.length > 0) {
      const loadEquipments = [];
      this.searchResults?.results.forEach((result) => {
        result.equipmentTypes.forEach((equipment) => {
          if (!loadEquipments.includes(equipment)) {
            loadEquipments.push(equipment);
          }
        });
      });
      const loadEquipmentsFmt = loadEquipments.map((equip) => ({
        label: equip,
        value: sentenceToCamelCase(equip),
      }));
      return loadEquipmentsFmt;
    }
    return [];
  }

  @computed get customProcessResults() {
    return true;
  }

  @action.bound
  async setRecentSearches(searchResults) {
    this.recentSearches = searchResults;
  }

  @action.bound
  async fetchHistory() {
    try {
      const searchResults = await GetSavedSearches();
      this.setRecentSearches(searchResults.data);
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  async setSortBy(filter: SortValues<string>) {
    this.sortFilter = [filter];

    this.searchResults.clearCache();
    this.searchResults.setResults([]);
    this.searchResults.setPagination(new Pagination());
    await this.downloadSearchResults(1);
  }

  @action.bound
  async resetSortBy(filter: SortValues<string>) {
    this.sortFilter = [filter];
  }

  // Remove if deadhead sort moved to BE
  @action.bound
  setSortByDeadhead(filter: SortValues<string>) {
    this.sortFilter = [filter];
    const args = { ...this.searchResults.args };
    args.sortFilter = [filter];
    this.searchResults.setArgs(args);
  }

  @action.bound
  setSortDirection(direction: number) {
    this.sortDirection = direction;
  }

  @action.bound
  setSelectedLoad(load: Load | null) {
    this.selectedLoad = load;
  }

  @action.bound
  downloadSearchResults = async (pageNumber, query = undefined) => {
    this.searchResults.setError(null);
    const queryParams = query || this.searchResults.previousQuery;
    queryParams.sortDirection = this.sortDirection;
    queryParams.tCode = this.rootStore.userStore.CHRTCode || null;

    let currentCoordinates;
    if (this.rootStore.userStore.currentCoordinates) {
      currentCoordinates = this.rootStore.userStore.currentCoordinates;
    } else {
      currentCoordinates = await this.rootStore.userStore.downloadCurrentCoordinatesAsync();
    }

    if (query) {
      this.searchResults.clearCache();
      this.searchResults.previousQuery = query;
      await this.searchResults.downloadResults(pageNumber, {
        searchQuery: queryParams,
        sortFilter: this.sortFilter,
        currentCoordinates,
      });
    } else {
      await this.searchResults.downloadResults(pageNumber, {
        searchQuery: queryParams,
        sortFilter: this.sortFilter,
        currentCoordinates,
      });
    }
  };

  @action.bound
  downloadLoad = async (loadId, source, tCode = '') => {
    this.setSelectedLoad(null);
    const loadFromResults = this.searchResults.getItemFromResults(loadId);
    if (loadFromResults) {
      this.setSelectedLoad(loadFromResults);
    } else {
      try {
        this.searchResults.setLoading(true);
        this.searchResults.setError(null);
        let result;
        if (source && source === LoadSources.CONVOY) {
          result = await ApiLayer.getConvoyLoad(loadId);
        } else {
          result = await ApiLayer.getLoad(loadId, tCode, source);
        }
        if (result) {
          // Save this match in 'other' key.
          this.setSelectedLoad(result);
          this.searchResults.cache.other = [...this.searchResults.cache.other, result];
        }
      } catch (error) {
        this.searchResults.setError(error);
      } finally {
        this.searchResults.setLoading(false);
      }
    }
  };

  @action.bound
  downloadLoadDetails = async (loadId: string) => {
    try {
      this.searchResults.setLoading(true);
      this.searchResults.setError(null);
      const result = await ApiLayer.getLoadDetails(loadId);
      if (result) {
        this.searchResults.updateItemInResults(result);
      }
    } catch (error) {
      this.searchResults.setError(error);
    } finally {
      this.searchResults.setLoading(false);
    }
  };

  @action.bound
  downloadNextResults() {
    const args = { ...this.searchResults.args };
    args.searchQuery.pagination = this.searchResults.pagination;
    this.searchResults.setArgs(args);
    this.searchResults.downloadNextResults();
  }

  @action.bound setLoadSourceOptions(apiOptions) {
    const appliedBrokerFilter = toJS(this.searchResults.previousQuery?.companyName) || [];
    const isBrokerFilterApplied = appliedBrokerFilter.length > 0;
    if (apiOptions) {
      let loadSources = apiOptions.map((obj) => ({
        label: getFormattedBrokerName(obj.broker) || sentenceCase(obj.broker),
        value: obj.broker === LoadCompanyNames.CONVOY ? obj.broker.toLowerCase() : obj.broker,
      }));
      /**
       * Sort the broker list alphabetically
       */
      loadSources.length > 0 ? (loadSources = sortBrokerList(loadSources)) : {};
      /**
       * If the broker filter is applied, check the API broker list:
       * if the params changes the list of brokers but a broker filter was previously applied that
       * doesn't exist in the API list, add it at the top of the list
       * with an extension (No Loads) to the label
       */
      if (isBrokerFilterApplied) {
        let selectedItems = [];
        appliedBrokerFilter.forEach((appliedFilter) => {
          const existsIndex = loadSources.findIndex((item) => item.value === appliedFilter);
          if (existsIndex === -1) {
            loadSources.unshift({
              label: `${sentenceCase(appliedFilter)} (No Loads)`,
              value: appliedFilter,
            });
          }
          // The selected filters should be moved to the top of the broker list.
          loadSources = loadSources.filter((item) => {
            if (item.value == appliedFilter) {
              selectedItems.push(item);
            }
            return item.value != appliedFilter;
          });
        });
        // Sort the selected filters
        selectedItems = sortBrokerList(selectedItems);
        // Appending the selected filters on top of the list.
        loadSources = [...selectedItems, ...loadSources];
      }
      this.loadSourceOptions = loadSources;
    } else {
      this.loadSourceOptions = [];
    }
  }

  @action.bound
  async loadBrokerList(formData) {
    const result = await ApiLayer.loadListBrokers(formData);
    this.setLoadSourceOptions(result);
  }

  @action.bound
  setCertifiedButtonPopupState(opened: boolean) {
    this.certifiedButtonPopupOpened = opened;
  }

  @action.bound
  updateLoadDeadheadFromBatch(load, value) {
    if (load.deadhead || load.deadhead === 0) {
      load.deadhead += ' mi';
    } else {
      load.deadhead = formatDeadhead(value);
    }
  }

  @action.bound
  updateLoadDropoffDeadheadFromBatch(load, value) {
    load.dropoffDeadhead = formatDeadhead(value);
  }

  @action.bound
  async batchCalculateDeadhead() {
    // Do not calculate deadhead for state level search
    if (this.searchResults.previousQuery?.pickupLocation.city) {
      try {
        const deadheadResults = await deadheadBatchCalculation(
          this.searchResults.results,
          this.searchResults.previousQuery?.pickupLocation,
        );
        this.searchResults.results.map((load, i) => {
          this.updateLoadDeadheadFromBatch(load, deadheadResults[i]);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  @action.bound
  async batchCalculateDropoffDeadhead() {
    // Do not calculate dropoff deadhead for state level search
    if (this.searchResults.previousQuery?.dropoffLocation?.city) {
      try {
        const deadheadResults = await deadheadBatchCalculation(
          this.searchResults.results,
          this.searchResults.previousQuery?.dropoffLocation,
          'DO',
        );
        this.searchResults.results.map((load, i) => {
          this.updateLoadDropoffDeadheadFromBatch(load, deadheadResults[i]);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  @action.bound batchProcessingCallback() {
    if (this.searchResults.pagination.page === 1) {
      this.batchCalculateDeadhead();
      this.batchCalculateDropoffDeadhead();
    }
  }

  @action.bound processResults(dataset, lastProcessed) {
    const itemsToProcess = dataset.slice(
      lastProcessed, // Starting index
      lastProcessed + batchCount, // Ending index to process in this iteration
    );
    const processed = itemsToProcess.map((o) => new Load(o));
    // Using this.results to update and set state
    this.searchResults.results = this.searchResults.results.concat(processed);
    this.searchResults.setResults(this.searchResults.results);
    if (lastProcessed + batchCount < dataset.length) {
      setTimeout(() => {
        this.processResults(dataset, lastProcessed + batchCount);
      }, 100);
    } else {
      this.searchResults.setLoading(false);
      this.batchProcessingCallback();
    }
  }
}

export default SearchStore;
