const secondary = {
  light: '#E5F8FB',
  main: '#00BCD4',
  dark: '#009EB3',
  disabled: '#80DDE9',
  medium: '#E5F8FB',
  contrastText: '#FFFFFF',
};
const primary = {
  light: '#BFC8D6',
  main: '#364554',
  dark: '#1A1A1A',
  disabled: '#1A1A1A',
  contrastText: '#FFFFFF',
  medium: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: secondary,
  partnerCustomSecondary: primary,
  background: {
    walkthroughPurple: '#42525C', // Walkthrough bubble bg, etc
    purpleLight: '#E5F8FB', // Light background for selected grid checkbox/radio block input etc
    lightBlue: {
      light: '#E5F8FB', // Table hover states
    },
  },
  navy: '#364554', // Search LP form background, etc
  loadStatus: {
    booked: '#00BCD4',
    shared: '#DE784D',
    completed: '#2BBF8A',
    cancelled: '#B93131',
  },
};
export default palette;
