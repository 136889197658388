import { IObservableArray, observable, computed, action, keys } from 'mobx';
import { capitalize } from 'utils/StringUtils';
import { stableSort } from 'utils/utility';
import { IAdditionalInfo } from 'models/interfaces/shared/IAdditionalInfo';
import { IUploadedFiles } from 'models/interfaces/shared/IUploadedFiles';
import { IPreferredPerMileRate } from 'models/interfaces/shared/IPreferredPerMileRate';
import { IAddress } from 'models/interfaces/shared/IAddress';
import { IPreferredLanes } from 'models/interfaces/shared/IPreferredLanes';
import { IAgreedToTermsAndConditions } from 'models/interfaces/shared/IAgreedToTermsAndConditions';
import { IFOUser } from 'models/interfaces/shared/IFOUser';
import { IOperatingLane } from 'models/interfaces/shared/IOperatingLanes';
import { ICommunicationsPreferences } from 'models/interfaces/shared/ICommunicationsPreferences';
import { ICompletedOnboardingSteps } from 'models/interfaces/shared/ICompletedOnboardingSteps';
import { IUserPermissions } from 'models/interfaces/shared/IUserPermissions';
import ApiLayer from 'services/APIServices/ApiLayer';
import { UserType } from 'constants/UserTypes';
import { DispatchableType } from '../DispatchableType';
import { DriverTruck } from '../DriverTruck';
import Match from '../Match';

export class FOUser implements IFOUser {
  @observable email: string;
  @observable id: string;
  @observable phone: string;
  @observable emailVerified: boolean;
  @observable userType: UserType;
  @observable dotNumber: string;
  @observable displayName: string;
  @observable creationTimestamp: number;
  @observable disabled: boolean;
  @observable firebaseUID: string;
  @observable additionalInfo: IAdditionalInfo;
  @observable uploadedFiles: IUploadedFiles;
  @observable equipmentTypeList: string[];
  @observable truckType: string;
  @observable preferredPerMileRate: IPreferredPerMileRate;
  @observable truckCount: number;
  @observable officePhone: string;
  @observable companyName: string;
  @observable companyType: string;
  @observable address: IAddress;
  @observable mcNumber: string;
  @observable preferredLanes: IPreferredLanes;
  @observable agreedToTermsAndConditions: IAgreedToTermsAndConditions;
  @observable channel: string;
  @observable firstLogin: boolean;
  @observable dispatchable: DispatchableType;
  @observable drivers: DriverTruck[];
  @observable fleetId: string;
  @observable truck?: DriverTruck;
  @observable inAppNotifications: number;
  @observable operatingLanes: IObservableArray<IOperatingLane>;
  @observable documents?: DOCUMENT_TYPES;
  @observable tac?: string;
  @observable modal: Object;
  @observable communicationsPreferences: ICommunicationsPreferences;
  @observable permissions: IDriverPermissions;
  @observable pickupRadius?: string;
  @observable dropoffRadius?: string;
  @observable completedOnboardingSteps?: ICompletedOnboardingSteps;
  @observable profilePicture?: string;
  @observable userPermissions?: IUserPermissions;

  @observable driverActiveLoads?: Match[]; // Max 2, 1 current and 1 upcoming

  constructor(user: IFOUser) {
    this.id = user.id;
    this.email = user.email;
    this.phone = user.phone;
    this.emailVerified = user.emailVerified;
    this.userType = user.userType;
    this.dotNumber = user.dotNumber;
    this.displayName = user.displayName;
    this.creationTimestamp = user.creationTimestamp;
    this.disabled = user.disabled;
    this.firebaseUID = user.firebaseUID;
    this.additionalInfo = user.additionalInfo;
    this.uploadedFiles = user.uploadedFiles;
    this.equipmentTypeList = user.equipmentTypeList || observable([]);
    this.truckType = user.truckType;
    this.preferredPerMileRate = user.preferredPerMileRate;
    this.truckCount = user.truckCount;
    this.officePhone = user.officePhone;
    this.companyName = user.companyName;
    this.companyType = user.companyType;
    this.address = user.address;
    this.mcNumber = user.mcNumber;
    this.preferredLanes = user.preferredLanes;
    this.agreedToTermsAndConditions = user.agreedToTermsAndConditions;
    this.channel = user.channel;
    this.firstLogin = user.firstLogin;
    this.dispatchable = user.dispatchable;
    this.fleetId = user.fleetId;
    this.inAppNotifications = user.inAppNotifications;
    this.operatingLanes = user.operatingLanes;
    this.documents = user.documents;
    this.tac = user.tac;
    this.communicationsPreferences = user.communicationsPreferences || {};
    this.permissions = user.permissions || user.userPermissions || {};
    this.modal = user.modal;
    this.drivers = user.drivers?.find((driver) => !(driver instanceof DriverTruck))
      ? user.drivers.map((driver) => new DriverTruck(driver))
      : observable([]);
    this.drivers = user.drivers?.map((driver) => new DriverTruck(driver)) || observable([]);
    this.truck = user.truck ? new DriverTruck(user.truck) : undefined;
    this.pickupRadius = user.pickupRadius;
    this.dropoffRadius = user.dropoffRadius;
    this.completedOnboardingSteps = user.completedOnboardingSteps;
    this.profilePicture = user?.profilePicture;
    this.userPermissions = user?.userPermissions || {};
  }

  @computed get truckIntegrationsList() {
    let integrations = this.truck?.integrations;
    return keys(integrations);
  }

  @computed get equipmentTypeFormatted() {
    return this.equipmentTypeList.map((s) => capitalize(s))?.join(', ') || 'None';
  }

  // Max 2, 1 current and 1 upcoming - sort by pickup date asc
  @action.bound setDriverActiveLoads(matches: Match[]) {
    this.driverActiveLoads = stableSort(matches, 'parentLoad.loadDisplayInfo.pickupDate', 'asc');
  }

  @action.bound
  setProfilePicture(profilePicture: string) {
    this.profilePicture = profilePicture;
  }

  @action.bound
  setModal(modal: Object) {
    this.modal = modal;
  }

  @action.bound
  getProfilePicture = async () => {
    const pictureId = this.documents?.profilePicture?.split('/')[0];
    if (pictureId) {
      try {
        const response = await ApiLayer.getProfilePicture(pictureId);
        this.setProfilePicture(response.data);
      } catch (error) {
        throw new Error('Technical error pulling the profile picture');
      }
    }
  };

  @action.bound
  addTruckIntegration({ carrierId, loadSource }) {
    if (carrierId && loadSource && this.truck?.integrations) {
      if (this.truck.integrations[loadSource]) {
        this.truck.integrations[loadSource]['carrier_id'] = carrierId;
      } else {
        this.truck.integrations = {
          ...this.truck.integrations,
          [loadSource]: {
            carrier_id: carrierId,
          },
        };
      }
    }
  }
}
