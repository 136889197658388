export enum LoadStatusCode {
  FINDING_COVERAGE = 'PC',
  BOOKED = 'BO',
  CANCELLED = 'CAN',
}

export enum DisplayLoadStatus {
  BOOKED = 'booked',
  CANCELLED = 'cancelled',
  SHARED = 'shared',
  COMPLETED = 'completed',
}
