export default [
  {
    value: 'van',
    label: 'Van',
    icon: 'icon-van',
  },
  {
    value: 'reefer',
    label: 'Reefer',
    icon: 'icon-reefer',
  },
  {
    value: 'flatbed',
    label: 'Flatbed',
    icon: 'icon-flatbed',
  },
  {
    value: 'stepDeck',
    label: 'Stepdeck',
    icon: 'icon-stepdeck',
  },
  {
    value: 'conestoga',
    label: 'Conestoga',
    icon: 'icon-conestoga',
  },
  {
    value: 'doubleDrop',
    label: 'Double Drop',
    icon: 'icon-double-drop',
  },
  {
    value: 'lowboy',
    label: 'Lowboy',
    icon: 'icon-lowboy',
  },
  {
    value: 'powerOnly',
    label: 'Power Only',
    icon: 'icon-power-only',
  },
];

export const DONE = 'Done';
