const config = {
  ssoUrl: 'https://client.gorillasafety.com/auth/login', // Gorilla Safety Staging
  clarityId: '8y21yya42u',
  datadog: {
    applicationId: 'da7c26f5-c27d-4914-ac35-cf94edcef3f7',
    clientToken: 'pub85ddd5b96b7bdea88902aa2bda15e172',
    service: 'eld-loads-production',
  },
};

export default config;
