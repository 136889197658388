export const UserTypeFormatted = {
  OWNER_OPERATOR: 'Owner-Operator',
  DISPATCHER: 'Dispatcher',
  DISPATCHER_DRIVER: 'Dispatcher-Driver',
  DISPATCHABLE_DRIVER: 'Driver-View',
  DISPATCHABLE_DRIVER_WITH_SEARCH: 'Driver-Search',
};

export enum UserType {
  OWNER_OPERATOR = 'driver',
  DISPATCHER = 'dispatcher',
  DISPATCHABLE_DRIVER = 'dispatchableDriver',
}
