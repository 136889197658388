import mixpanel, { Dict } from 'mixpanel-browser';
import { isEmpty } from 'lodash';
import queryStringParser from 'query-string';
import { MIXPANEL_KEYS, MIXPANEL_USER_PROPERTIES, MIXPANEL_EVENT_VALUE } from 'constants/Mixpanel';
import { isSplitTestActive, getExperimentProperties } from 'utils/experimentUtils';
import config from '../../../config';
import { ICoordinate } from '../../models/interfaces/shared/ICoordinate';
import {
  getAddressComponent,
  sentenceCase,
  formatYearMonthDay,
  formatTime,
} from '../../utils/utility';
import Load from '../../models/dataStructures/Load';
import { FOUser } from '../../models/dataStructures/FOUser';

export const mixpanelInit = () => mixpanel.init(config.mixpanelConfig.token);

export const mixpanelTrack = (event_name: string, properties?: Dict, callback?: () => void) => {
  mixpanel.track(
    event_name,
    {
      [MIXPANEL_KEYS.DATE_TIME]: new Date().toISOString(),
      ...properties,
      ...getExperimentProperties(),
    },
    callback,
  );
};

export const mixpanelSetUser = async (
  {
    firebaseUID,
    displayName,
    phone,
    email,
    mcNumber,
    equipmentTypeList,
    preferredLanes,
    companyType,
    drivers,
    documents,
    equipmentTypeFormatted,
    channel,
  }: FOUser,
  userType: string,
) => {
  mixpanel.identify(firebaseUID);

  mixpanel.people.set_once(MIXPANEL_KEYS.FIRST_LOGIN_DATE, new Date().toISOString());

  const peopleProps = {
    [MIXPANEL_USER_PROPERTIES.USER_TYPE]: userType,
    [MIXPANEL_USER_PROPERTIES.DISPLAY_NAME]: displayName,
    [MIXPANEL_USER_PROPERTIES.MCNUMBER]: mcNumber,
    [MIXPANEL_USER_PROPERTIES.PHONE]: phone,
    [MIXPANEL_USER_PROPERTIES.EMAIL]: email,
    [MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST]: equipmentTypeList,
    [MIXPANEL_USER_PROPERTIES.PREFERRED_LANES]: preferredLanes?.states,
    [MIXPANEL_USER_PROPERTIES.COMPANY_TYPE]: companyType,
    [MIXPANEL_USER_PROPERTIES.ELD_CHANNEL]: config.appMode,
    [MIXPANEL_USER_PROPERTIES.APP_VERSION]: 'DART',
  };

  if (drivers && drivers.length > 0) {
    drivers.forEach((driver) => {
      peopleProps[MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST] = [
        ...peopleProps[MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST],
        ...driver.equipmentTypeList,
      ];
    });
  }
  peopleProps[MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST] = Array.from(
    new Set(peopleProps[MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST]),
  );
  if (documents) {
    peopleProps[MIXPANEL_KEYS.DOCUMENT_TYPES] = Object.keys(documents);
  }

  let superProps = {
    [MIXPANEL_USER_PROPERTIES.DISPLAY_NAME]: displayName,
    [MIXPANEL_USER_PROPERTIES.PHONE]: phone,
    [MIXPANEL_USER_PROPERTIES.EMAIL]: email,
    [MIXPANEL_USER_PROPERTIES.USER_TYPE]: userType,
    [MIXPANEL_USER_PROPERTIES.EQUIPMENT_LIST]: equipmentTypeFormatted,
  };

  const isSplitActive = await isSplitTestActive();
  if (isSplitActive) {
    superProps = { ...superProps, ...getExperimentProperties() };
  }

  mixpanel.people.set(peopleProps);
  mixpanel.register(superProps);
};

export const mixpanelUpdateUser = (userProperty) => {
  mixpanel.people.set(userProperty);
};

export const mixpanelRegister = (userProperty) => {
  mixpanel.register(userProperty);
};

export const mixpanelSetSearchRALCitiesStates = (value) => {
  const locality = getAddressComponent(value, 'locality');
  const city = locality ? locality.long_name : null;
  const administrative_area_level_1 = getAddressComponent(value, 'administrative_area_level_1');
  const state = administrative_area_level_1 ? administrative_area_level_1.short_name : null;
  if (value.city || city) {
    mixpanel.people.append([MIXPANEL_KEYS.SEARCHED_CITY], value.city || city);
  }
  if (value.state || state) {
    mixpanel.people.append([MIXPANEL_KEYS.SEARCHED_STATE], value.state || state);
  }
};

export const mixpanelLoadProperties = (load: Load) => {
  if (load) {
    const loadProperties = {
      matchId: load.isMatch ? load.matchId : null,
      loadId: load.loadId,
      [MIXPANEL_KEYS.LOAD_SOURCE]: load.source,
      [MIXPANEL_KEYS.PICKUP_LOCATION]: load.pickups && load?.pickups[0]?.location,
      [MIXPANEL_KEYS.DROPOFF_LOCATION]: load.dropoffs && load?.dropoffs[0]?.location,
      [MIXPANEL_KEYS.PER_MILE_RATE]:
        load?.payload?.loadPay?.perMileRate || load?.perMileRate?.price,
      [MIXPANEL_KEYS.POSTED_RATE]: load?.payload?.loadPay.amount || load.flatRate?.amount,
      [MIXPANEL_KEYS.CURRENCY]: load?.payload?.loadPay?.currency,
      [MIXPANEL_KEYS.EQUIPMENT_LIST]: load.equipmentTypes,
      [MIXPANEL_KEYS.COMPANY_NAME]: load?.contact?.companyName
        ? sentenceCase(load?.contact?.companyName)
        : '',
      [MIXPANEL_KEYS.PICKUP_DATE]:
        load?.pickups &&
        load.pickups[0] &&
        load.pickups[0]?.startDateTime &&
        formatYearMonthDay(new Date(load.pickups[0]?.startDateTime)),
      [MIXPANEL_KEYS.DROPOFF_DATE]:
        load?.dropoffs &&
        load.dropoffs[0] &&
        load.dropoffs[0]?.startDateTime &&
        formatYearMonthDay(new Date(load.dropoffs[0]?.startDateTime)),
      [MIXPANEL_KEYS.PICKUP_TIME_START]: formatTime(new Date(load.pickups[0]?.startDateTime), true),
      [MIXPANEL_KEYS.PICKUP_TIME_END]: load?.pickups[0]?.endDateTime
        ? formatTime(new Date(load?.pickups[0]?.endDateTime), true)
        : '-',
      [MIXPANEL_KEYS.DROPOFF_TIME_START]: load?.dropoffs[0]?.startDateTime
        ? formatTime(new Date(load?.dropoffs[0]?.startDateTime), true)
        : '-',
      [MIXPANEL_KEYS.DROPOFF_TIME_END]: load?.dropoffs[0]?.endDateTime
        ? formatTime(new Date(load?.dropoffs[0]?.endDateTime), true)
        : '-',
      [MIXPANEL_KEYS.COMMODITY]: load?.commodity,
      [MIXPANEL_KEYS.WEIGHT]: load?.weight,
      [MIXPANEL_KEYS.LENGTH]: load?.lengthFormatted,
      [MIXPANEL_KEYS.NOTES]: load?.shippingNotes,
      [MIXPANEL_KEYS.LOAD_REQUIREMENTS]: load?.equipmentSpecifications,
      [MIXPANEL_KEYS.FREIGHT_TYPE]: load?.freightType,
      [MIXPANEL_KEYS.BID_STATUS]:
        load?.hasOffer && load.offer?.status ? load.offer?.status : undefined,
    };
    return loadProperties;
  }
};

export const mixpanelUserProperties = (FOUser: FOUser) => {
  const userProperties = {
    [MIXPANEL_USER_PROPERTIES.DISPLAY_NAME]: FOUser.displayName,
    [MIXPANEL_USER_PROPERTIES.EMAIL]: FOUser.email,
    [MIXPANEL_USER_PROPERTIES.PHONE]: FOUser.phone,
  };
  return userProperties;
};

/**
 * This function is needed to indicate the trigger of load interaction
 * @param action Action value taken from the url params
 */
export const mixpanelLoadInteractionProperties = (action: string) => {
  const loadInteractionProperties =
    action && window.location.pathname.includes('driver/load')
      ? {
          [MIXPANEL_KEYS.LOAD_INTERACTION_TRIGGER]: 'CL Email', // Harcoded for now because it won't be used in other cases
        }
      : {};
  return loadInteractionProperties;
};

/**
 * This function composes the properties payload for DEM-434
 * @link https://fleetopsai.atlassian.net/browse/DEM-434
 */
export function mixpanelFromEmailSourceEventProperties():
  | { [MIXPANEL_KEYS.IS_COMING_FROM_EMAIL]: 'No' }
  | {
      [MIXPANEL_KEYS.IS_COMING_FROM_EMAIL]: 'Yes';
      [MIXPANEL_KEYS.REQUEST_ID]: string;
    } {
  const params = queryStringParser.parse(window.location.search);

  if (isEmpty(params?.requestId)) {
    return {
      [MIXPANEL_KEYS.IS_COMING_FROM_EMAIL]: MIXPANEL_EVENT_VALUE.IS_COMING_FROM_EMAIL.NO,
    };
  }

  return {
    [MIXPANEL_KEYS.IS_COMING_FROM_EMAIL]: MIXPANEL_EVENT_VALUE.IS_COMING_FROM_EMAIL.YES,
    [MIXPANEL_KEYS.REQUEST_ID]: params.requestId as string,
  };
}
